import { Card } from "primereact/card"
import { useEffect, useState } from "react";
import { FBiBCasePersonForm, emptyFBiBCasePerson, validateFBiBCasePerson } from "./FBiBCasePersonForm";
import { patchData } from "../../feathers";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { ENUM_ICONS } from "../../Components/Icons";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { useFormik } from "formik";
import { copyObject } from "../../snippets";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.CASEPERSONS
export const FBiBCasePersonSheet = ({ fbibCase, onCasePersonChanged, readonly }) => {
	const isReader = useCurrentUserIsReaderFBiB();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [casePerson, setCasePerson] = useState();

	useEffect(() => {
		if (fbibCase) {
			setCasePerson(fbibCase.casePerson)
		}
	}, [fbibCase])

	const patchCasePerson = async (fbibCasePerson) => {
		await patchData(ENUM_SERVICES.FBIBCASEPERSONS, fbibCasePerson).then(async (fbibCasePerson) => {
			await onCasePersonChanged(fbibCasePerson)
		})
	}

	const editCasePerson = () => {
		formikCasePerson.setValues(casePerson ? copyObject(casePerson) : copyObject(emptyFBiBCasePerson))
		setDisplayEditDialog(true)
	}

	/** formik CasePerson */
	const formikCasePerson = useFormik({
		initialValues: copyObject(emptyFBiBCasePerson),
		validate: validateFBiBCasePerson,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});


	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Fallperson</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Person</div>
				<div className={isReader || readonly ? 'hidden' : 'flex gap-2'}>
					<div className='flex w-1rem' onClick={editCasePerson}><i className={fbibCase.casePerson && fbibCase.casePerson.id ? ENUM_ICONS.PENCIL : ENUM_ICONS.PLUS_CIRCLE} /></div>
					<div className={!casePerson ? 'hidden' : 'flex w-1rem'} onClick={() => setDisplayConfirmDialog(true)}><i className={ENUM_ICONS.TRASH} /></div>
				</div>

			</div>
		</div>
	}

	return (
		<>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-remove'
				labelObject={{ ...casePerson }}
				handleOnClick={async () => { await onCasePersonChanged(null) }}
				displayConfirmDialog={displayConfirmDialog}
				setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<EditDialog
				displayDialog={displayEditDialog}
				form={<FBiBCasePersonForm formik={formikCasePerson} />}
				formik={formikCasePerson}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				header='Details zur Fallperson ändern'
				labelButtonOk={fbibCase.casePerson && fbibCase.casePerson.id ? 'Ändern' : 'Neu hinzufügen'}
				message={casePerson && casePerson.personId ? 'Hier können Sie die Details der Person ' + casePerson.personId + ' ändern.' : 'Hier können Sie eine neue Person zum Fall auswählen oder erstellen'}
				patchForm={() => { patchCasePerson(formikCasePerson.values) }}
				setDisplayDialog={setDisplayEditDialog}
			/>
			<Card className="casepersonsheet card-sheet" header={renderHeader}>
				{!casePerson ? 'keine Person zugeordnet' :
					<>
						<div className="grid row">
							<div className="col-fixed">Person ID</div>
							<div className="col">{casePerson.personId}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Merkzeichen</div>
							<div className="col">{casePerson.GdBMark}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Grad der Behinderung</div>
							<div className="col">{casePerson.GdB}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Art der Behinderung</div>
							<div className="col">{casePerson.disablityKind}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Geschlecht</div>
							<div className="col">{casePerson.gender ? casePerson.gender.display : ''}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Einv. Erklärung liegt vor</div>
							<div className="col">{casePerson.parentsConsent ? 'ja' : 'nein'}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Sb-Ausweis liegt vor</div>
							<div className="col">{casePerson.disabledPersonsPass ? 'ja' : 'nein'}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Reha-Status liegt vor</div>
							<div className="col">{casePerson.rehaStatus ? 'ja' : 'nein'}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Schüler*in</div>
							<div className="col">{casePerson.isPupil ? 'ja' : 'nein'}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Berufsvorbereitende Bildung</div>
							<div className="col">{casePerson.bvb ? 'ja' : 'nein'}</div>
						</div>
						<div className="grid row">
							<div className="col-fixed">Berufswünsche</div>
							<div className="col">{casePerson.careerAspiration}</div>
						</div>
						<div className="flex flex-column mt-4">
							<div className="flex">{casePerson.description}</div>
						</div>
					</>
				}
			</Card>
		</>
	)

}