import { MultiSelect } from "primereact/multiselect";
import { useConfigurationsList, useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { useEffect, useState } from 'react';
import { Tag } from "primereact/tag";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../../snippets";
import { logDefault } from "../../Utils/logger";

const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
const CLASSNAME = 'MultiTagFilterElement'
export const MultiTagFilterElement = ({ options, translationKey }) => {
	const configurationsList = useConfigurationsList();
	const fbibMode = useFBiBMode();
	const [listOptions, setListOptions] = useState([]);
	const [filterElementClassName, setFilterElementClassName] = useState();
	const [maxSelectedLabels, setMaxSelectedLabels] = useState();
	const [filterActiveClassName, setFilterActiveClassName] = useState('');
	const [labels, setLabels] = useState(getTranslation(NAMESPACE, translationKey, { returnObjects: true }));

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'labels', labels)
	}, [])

	useEffect(() => {
		if (options.filterModel) {
			setListOptions(configurationsList[options.filterModel.filterListName]);
			setFilterElementClassName(options.filterModel.filterElementClassName);
			setMaxSelectedLabels(options.filterModel.maxSelectedLabels);
		}

	}, [configurationsList, options.filterModel])

	useEffect(() => {
		setFilterActiveClassName(options.value && options.value.length > 0 ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '');
	}, [options.value])

	const selectedTemplate = (option) => {
		const item = listOptions.find(entry => entry.alias === option)
		return !item ? '' :
			<div className={"flex text-xm" + (' color-text-' + item.color)}>
				{item.datatable}
			</div>
	}

	const renderTagFilter = (option) => { return <Tag className={"mr-2 text-white px-3" + (' color-tag-' + option.color)} rounded value={option.datatable} /> }
	return (<div className={filterElementClassName}>
		<MultiSelect
			id='statusRowFilterTemplate'
			panelClassName={fbibMode ? 'fbib' : ''}
			className={filterActiveClassName + ' flex w-full'}
			value={options.value}
			optionValue='alias'
			optionLabel="datatable"
			options={listOptions}
			onChange={(e) => options.filterApplyCallback(e.value)}
			itemTemplate={renderTagFilter}
			selectedItemTemplate={selectedTemplate}
			placeholder={labels?.placeholder ? labels.placeholder['label'] : ''}
			aria-placeholder={labels?.placeholder ? labels.placeholder.ariaLabel : ''}
			selectedItemsLabel={`${options.value ? options.value.length : 0}  ${labels?.filter?.textSelected ? labels.filter.textSelected['label'] : ''}`}
			scrollHeight='250px'
			maxSelectedLabels={maxSelectedLabels} />
	</div>
	)

}
export function getMultiTagFilterElement(dataviewConfigKey) {
	return (options) => <MultiTagFilterElement options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />

}