import { format, parse, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../Components/ConfirmDialog';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { FieldInfo } from "../../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_ICONS, Icon } from '../../Components/Icons';
import { BasicSheetTitleLayout, getScreenreaderMessage } from '../../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../../Components/OverlayPanelMenu';
import { deleteData } from '../../feathers';
import { useBreadcrumbsMinus } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { ToastError } from '../../Utils/ToastError';
import { BasicSheetLayout } from '../../Components/Layouts/BasicSheetLayout';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { CommentsModule } from '../../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { ReminderDialog } from '../../Reminders/ReminderDialog';
import { copyObject, getTranslation } from '../../snippets';
import { TrainingCenterForm, emptyTrainingCenter, validateTrainingCenter } from './TrainingCenterForm';
import { getTrainingCenter, getTypeTrainingCenterObject, patchTrainingCenter, toggleTrainingCenterStatus } from './TrainingCenterManager';
import { TrainingCenterSheet } from './TrainingCenterSheet';
import { TRAININGCOURSE_SERVICECONFIG, TrainingCourseSheet } from '../TrainingCourses/TrainingCourseSheet';
import { listStatus } from '../../Companies/CompaniesData';
import { Tag } from 'primereact/tag';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';


const SERVICENAME = ENUM_SERVICES.TRAININGCENTERS;
const NAMESPACE = ENUM_NAMESPACES.TRAININGCENTERS;
const CLASSNAME = 'TrainingCenterData'
export const TrainingCenterData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const configurationsList = useConfigurationsList();
	const { trainingCenterId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [trainingCenter, setTrainingCenter] = useState();
	const [displayTrainingCenterDialog, setDisplayTrainingCenterDialog] = useState(false);
	const [displayToggleStatusConfirmDialog, setDisplayToggleStatusConfirmDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	const trainingCenterUpdate = async () => {
		if (trainingCenterId) {
			return await getTrainingCenter(trainingCenterId, isReader, setTrainingCenter, setReadonly, setToastParams)
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await trainingCenterUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [errorUpdate, navigate])

	const onPatchTrainingCenter = async () => {
		await patchTrainingCenter(formik.values, currentUser, isReader, setTrainingCenter, setReadonly, setToastParams)
	}

	const onEditTrainingCenter = () => {
		formik.setValues(trainingCenter)
		setDisplayTrainingCenterDialog(true)
	}

	const deleteTrainingCenter = async () => {
		setDisplayDeleteConfirmDialog(false);

		await deleteData(SERVICENAME, trainingCenter.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Ausbildungsstätte konnte nicht gelöscht werden', message: error.message })
		})
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const formik = useFormik({
		initialValues: copyObject(emptyTrainingCenter),
		validate: validateTrainingCenter,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const listButtons = [
		{ key: 'edit', label: getTranslation(NAMESPACE, 'button-edit-trainingcenter.label'), icon: ENUM_ICONS.PENCIL, command: onEditTrainingCenter, disabled: readonly },
		{ key: 'reminder', label: getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.label`), icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderItems = () => {
		const items = [
			{ label: getTranslation(NAMESPACE, 'button-edit-trainingcenter.label'), icon: ENUM_ICONS.PENCIL, command: onEditTrainingCenter, disabled: readonly },
		]
		if (!isReader && trainingCenter) {
			items.push({ separator: true })
			if (trainingCenter.active) {
				items.push({ label: 'Inaktiv setzen', icon: ENUM_ICONS.DEACTIVATE, command: () => setDisplayToggleStatusConfirmDialog(true) })
			} else {
				items.push({ label: 'Aktiv setzen', icon: ENUM_ICONS.REACTIVATE, command: () => setDisplayToggleStatusConfirmDialog(true) })
			}
		}
		if (currentUser.permissions === 'ADMIN' && trainingCenter) {
			items.push({ label: 'Ausbildungsstätte unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}

	const renderContent = () => {
		const status = listStatus.find(entry => entry.alias === trainingCenter.active);
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						screenReaderMessage={getScreenreaderMessage('Ausbildungsstätte', trainingCenter)}
						listButtons={listButtons}
						title={trainingCenter.name}
						subtitle={<div className='flex'>
							<div>{getTranslation(ENUM_NAMESPACES.FORMELEMENTS, 'subtitle.label', { displayId: trainingCenter?.displayId, dateFormatted: formatDate(parseISO(trainingCenter.createdAt)), creatorname: trainingCenter.creator?.displayname })}</div>
							<Tag className={'ml-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />
						</div>
						}
					/>

					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.type' content={getTypeTrainingCenterObject(configurationsList, trainingCenter.type).display} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.phone' content={trainingCenter.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-2x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.mobile' content={trainingCenter.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-3x'} />} />
					</div>
					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.mail' content={<a href={`mailto:${trainingCenter.mail}`}>{trainingCenter.mail}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-2x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.website' content={<a href={`${trainingCenter.website.toLowerCase().startsWith('http') ? '' : 'https://'}${trainingCenter.website}`}>{trainingCenter.website}</a>} icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.WWW} />} />
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.description.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: trainingCenter.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={trainingCenterUpdate}
							key='trainingCenterCommentsModule'
							servicename={ENUM_SERVICES.TRAININGCENTERCOMMENTS}
							readonly={readonly}
							parentId={trainingCenter.id}
							idFieldname='trainingCenterId'
							reminderParentService={SERVICENAME}
							comments={trainingCenter.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey={'confirmdialog-delete'}
				labelObject={trainingCenter}
				handleOnClick={() => deleteTrainingCenter(trainingCenter, minusBreadcrumbs, setToastParams)}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Schulische Ausbildungsstätte'
				name={trainingCenter.name}
				serviceId={trainingCenter.id}
				serviceName={SERVICENAME}
				userId={currentUser.id}
			/>
			<EditDialog
				form={<TrainingCenterForm
					formik={formik}
					validateForm={async () => await formik.validateForm()}
					key='trainingcenter-form' />
				}
				formik={formik}
				patchForm={onPatchTrainingCenter}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayTrainingCenterDialog}
				displayDialog={displayTrainingCenterDialog}
			/>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey={'confirmdialog-deactivate'}
				labelObject={{ action: trainingCenter.active ? 'deaktivieren' : 'reaktivieren' }}
				handleOnClick={async () => await toggleTrainingCenterStatus(trainingCenter, currentUser, isReader, setTrainingCenter, setReadonly, setToastParams)}
				displayConfirmDialog={displayToggleStatusConfirmDialog}
				setDisplayConfirmDialog={setDisplayToggleStatusConfirmDialog}
			/>

		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, awesomeIcon: ENUM_AWESOMEICON.TRAININGCENTERS,
				component: <div className="flex flex-column row-gap-4">
					<TrainingCenterSheet
						updateParentSheet={trainingCenterUpdate}
						hideSpecialFields={true}
						readonly={readonly}
						trainingCenter={trainingCenter}
						key='trainingcenter-sheet'
						hideTraningCourses={true}
						onUpdateTrainingCenter={() => { }} />
				</div>
			},
			{
				index: 1, visible: true, awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES,
				component: <div className="flex flex-column row-gap-4">
					<TrainingCourseSheet
						serviceConfig={TRAININGCOURSE_SERVICECONFIG.TRAINIGCENTERS}
						readonly={readonly}
						parentId={trainingCenterId}
						trainingCoursesList={trainingCenter.courses}
						updateParent={trainingCenterUpdate}
					/>
				</div>
			},
		]
		return sideSheets;
	}

	return (!trainingCenter ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!trainingCenter ? '' : renderContent()}{!trainingCenter ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={trainingCenter.id}
		/>
	)
}