import { useEffect, useState } from "react";
import { useFormatDate } from "../../Persistence/ConfigurationsContext";
import { Tag } from "primereact/tag";
import { getTranslation } from "../../snippets";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { parseISO } from "date-fns";

const CLASSNAME = 'BasicSheetSubtitle';

export const listStatus = [{ icon: 'fa fa-check-circle-o ', display: 'Aktiv', alias: 1, bgColor: 'color-tag-green' }, { icon: 'fa fa-times-circle-o', display: 'Inaktiv', alias: 0, bgColor: 'color-tag-grey' }];
export const BasicSheetSubtitle = ({ object, NAMESPACE, tag }) => {
	const formatDate = useFormatDate();
	const [status, setStatus] = useState(listStatus.find(entry => entry.alias === object.active))

	useEffect(() => {
		setStatus(listStatus.find(entry => entry.alias === object.active))
	}, [object])

	return <div className='flex text-center justify-content-center text-lg mb-1 gap-2'>
		{getTranslation(NAMESPACE || ENUM_NAMESPACES.FORMELEMENTS, 'subtitle.label', { displayId: object.displayId, dateFormatted: formatDate(object.createdAtMillis || parseISO(object.createdAt)), creatorname: object.creator?.displayname })}
		{tag || <Tag className={'mr-2 text-white px-3 ' + (status.bgColor)} rounded value={status.display} />}
	</div>
}