import { useState } from 'react'
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { AssociatedContactChooseDialog } from '../AssociatedContacts/AssociatedContactChooseDialog';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';
import { getTranslation } from '../snippets';
import { CalendarChangesChecked } from '../Components/FormElements/CalendarChangesChecked';
import { DropdownChangesChecked } from '../Components/FormElements/DropdownChangesChecked';

const NAMESPACE = ENUM_NAMESPACES.EAACASES
export const emptyEAACase = { id: null, version: 1, active: 1, caseDocId: '', name: '', description: '', date: '', initiatedBy: '', status: 'OPEN', documentLocation: '', supportCaseId: '', associatedContactId: null }

export const EAACaseForm = ({ formik }) => {
	const configurationsList = useConfigurationsList();
	const [initiatedBy, setInitiatedBy] = useState('')

	return (
		<div>{!formik ? '' :
			< div className='col p-0 m-0' >
				<div className="formgrid grid">
					<InputChangesChecked
						NAMESPACE={NAMESPACE}
						id='name'
						translationKey='fields.name'
						type="text"
						formik={formik}

					/>
				</div>
				<div className="formgrid grid">
					<CalendarChangesChecked
						id='date'
						tabIndex={0}
						NAMESPACE={NAMESPACE}
						translationKey='fields.date'
						formik={formik}
						maxDate={new Date()}
					/>
					<DropdownChangesChecked
						tabIndex={0}
						className='mr-3'
						NAMESPACE={NAMESPACE}
						translationKey='fields.status'
						id='status'
						optionValue='alias'
						optionLabel='display'
						formik={formik}
						options={configurationsList.CaseStatusList} />
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						tabIndex={0}
						NAMESPACE={NAMESPACE}
						translationKey='fields.initiatedBy'
						className='mr-5'
						id='initiatedBy'
						optionLabel='display'
						optionValue='alias'
						formik={formik}
						options={configurationsList.InitiatedByTypesList}
						onChange={setInitiatedBy} />


					{(formik.values.id || initiatedBy !== 'THIRDPARTY' || configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') ? <div className="field col" /> :
						<>
							<AssociatedContactChooseDialog
								formik={formik}
								readonly={false}
								associatedContacts={[]}
								className='pl-0 mr-3'
							/>
						</>

					}
				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						NAMESPACE={NAMESPACE}
						translationKey='fields.documentLocation'
						id='documentLocation'
						type="text"
						formik={formik}
					/>
				</div>
				<div className={"formgrid grid"}>
					<EditorChangesChecked
						NAMESPACE={NAMESPACE}
						id='description'
						translationKey='fields.description'
						formik={formik}
						value={formik.values.description}
						setValue={(value) => formik.setFieldValue('description', value)}
					/>
				</div>
			</div>}
		</div>

	)
}
export const validateEAACase = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error', { returnObjects: true });
	}
	if (!data.status) {
		errors.status = getTranslation(NAMESPACE, 'fields.status.error', { returnObjects: true });
	}
	if (!data.initiatedBy) {
		errors.initiatedBy = getTranslation(NAMESPACE, 'fields.initiatedBy.error', { returnObjects: true });
	}
	if (!data.date) {
		errors.date = getTranslation(NAMESPACE, 'fields.date.error', { returnObjects: true });
	}
	if (!data.description) {
		errors.description = getTranslation(NAMESPACE, 'fields.description.error', { returnObjects: true });
	}
	return errors;
}