
import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { useConfigurationsList, useFBiBMode } from '../../Persistence/ConfigurationsContext';
import { logDefault } from '../../Utils/logger';

const CLASSNAME = 'MultiSelectFilterElement';
export const MultiSelectFilterElement = ({ options, translationKey }) => {
	const configurationsList = useConfigurationsList();
	const fbibMode = useFBiBMode();
	const [listOptions, setListOptions] = useState();
	const [listGrouped, setListGrouped] = useState();
	const [placeholder, setPlaceholder] = useState();
	const [filterSelectedLabel, setFilterSelectedLabel] = useState();
	const [maxSelectedLabels, setMaxSelectedLabels] = useState();
	const [filterActiveClassName, setFilterActiveClassName] = useState('');

	useEffect(() => {
		if (options.filterModel) {
			logDefault(CLASSNAME, 'info', 'options.filterModel:', options.filterModel)
			const list = configurationsList[options.filterModel.filterListName]
			setListOptions(list);
			setListGrouped(configurationsList[options.filterModel.groupedListName])
			setPlaceholder(options.filterModel.filterPlaceholder);
			setFilterSelectedLabel(options.filterModel.filterSelectedLabel);
			setMaxSelectedLabels(options.filterModel.maxSelectedLabels);
			setFilterActiveClassName(options.value ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '')
		}
	}, [options])


	const itemTemplate = (option) => {
		const labelname = options.filterModel.filterLabelName || 'name'
		return option ? option[labelname] : ''
	}

	const groupedItemTemplate = (option) => {
		const labelname = options.filterModel.filterLabelName || 'name'
		return option ? option[labelname] : ''
	}

	const selectedTemplate = (option) => {
		const fieldname = options.filterModel.filterFieldName || 'id'
		const labelname = options.filterModel.filterLabelName || 'name'
		const optionEntry = option ? listOptions.find(e => e[fieldname] === option) : null;
		return optionEntry ? <div className="flex text-xm ">{(optionEntry.prefix || '') + optionEntry[labelname]}</div> : '';
	}

	const panelFooterTemplate = () => {
		const selectedItems = options.value;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> {filterSelectedLabel} ausgewählt.
			</div>
		);
	}

	return (<>
		{(!listOptions || listOptions.length === 0) ? 'keine ' + filterSelectedLabel + ' gelistet' :
			<MultiSelect
				className={filterActiveClassName + ' multiselect-custom '}
				panelClassName={fbibMode ? 'fbib' : ''}
				id={'multiSelectFilter_' + options.filterModel.filterListName}
				value={options.value}
				optionValue={options.filterModel.filterFieldName || 'id'}
				optionLabel={options.filterModel.filterLabelName || "name"}
				options={listGrouped || listOptions}
				optionGroupLabel={listGrouped ? "name" : null}
				optionGroupChildren={listGrouped ? "items" : null}
				optionGroupTemplate={listGrouped ? groupedItemTemplate : null}
				onChange={(e) => options.filterApplyCallback(e && e.value && e.value.length === 0 ? null : e.value)}
				itemTemplate={itemTemplate}
				selectedItemTemplate={selectedTemplate}
				panelFooterTemplate={panelFooterTemplate}
				placeholder={placeholder}
				selectedItemsLabel={(options.value ? options.value.length : 0) + ' ' + filterSelectedLabel + ' ausgewählt'}
				maxSelectedLabels={3} />
		}
	</>)
}

export function getMultiSelectFilterElement(dataviewConfigKey) {
	return (options) => <MultiSelectFilterElement options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />


}