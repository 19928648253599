import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils"

export const RadioButtonChecked = ({ id, label, formik, className, disabled, checked }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id].label}</small>;
	};

	const onChange = (e) => {
		formik.handleBlur(e);
		formik.setFieldValue(id, e.value)
	}

	const onBlur = (e) => {
		formik.handleBlur(e);
	}

	return (

		<div className={className}>
			<div className={"field-radiobutton"}>
				<RadioButton
					key={'radiobutton-' + id}
					inputId={'radiobutton-' + id}
					value={checked}
					readOnly={disabled}
					checked={formik.values[id] === checked}
					onBlur={onBlur}
					onChange={onChange}
					className={classNames({ 'p-invalid': isFormFieldValid(id) })} />
				<label htmlFor={id} className='block'>{label}</label>
			</div>
			{getFormErrorMessage(id)}
		</div>
	)

}

