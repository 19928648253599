import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout, getScreenreaderMessage } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { AssociatedContactForm, getEmptyAssociatedContact, validateAssociatedContact } from './AssociatedContactForm';
import { getAssociatedContact } from './AssociatedContactsManager';
import { Card } from "primereact/card";
import { AssociatedOrganizationSheet } from '../AssociatedOrganizations/AssociatedOrganizationSheet';
import { BasicSheetSubtitle } from '../Components/Layouts/BasicSheetSubtitle';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';
import { NavigationEntry } from '../Navigation/NavigationEntry';
import { getTranslation } from '../snippets';


const SERVICENAME = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const NAMESPACE = ENUM_NAMESPACES.ASSOCIATEDCONTACTS;
const CLASSNAME = 'AssociatedContactData'
export const AssociatedContactData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const { associatedContactId } = useParams();
	const [associatedContact, setAssociatedContact] = useState();
	const [displayAssociatedContactDialog, setDisplayAssociatedContactDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayStatusDialog, setDisplayStatusDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [relations, setRelations] = useState(1);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') {
			errorUpdate('Modul Netzwerkkontakte', 'Das Modul Netzwerkkontakte ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [])

	const associatedContactUpdate = async () => {
		if (associatedContactId) {
			return await getAssociatedContact(associatedContactId).then((associatedContact) => {
				setReadonly(isReader || !associatedContact.active)
				const countRelations = associatedContact.companies.length + associatedContact.eaaCases.length + associatedContact.provideractivities.length + associatedContact.fbibCases.length + associatedContact.fbibProvideractivities.length
				setRelations(countRelations)
				setAssociatedContact(associatedContact);
				logDefault(CLASSNAME, 'info', 'associatedContact ', associatedContact);
				return associatedContact !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await associatedContactUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [])


	const toggleStatus = async () => {
		associatedContact.active = associatedContact.active ? 0 : 1
		await patchData(SERVICENAME, associatedContact).then(async (result) => {
			await associatedContactUpdate();
		})
	}

	const patchAssociatedContact = async () => {
		const associatedContact = { ...formik.values, regionId: formik.values.regionId }
		await patchData(SERVICENAME, associatedContact).then(async (result) => {
			await associatedContactUpdate();
		})
	}

	const onEditAssociatedContact = () => {
		formik.setValues({ ...associatedContact })
		setDisplayAssociatedContactDialog(true)
	}

	const deleteAssociatedContact = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, associatedContact.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Netzwerkkontakt konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = [
			{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly },
			{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => setDisplayStatusDialog(true), disabled: isReader },

		]
		if (currentUser.permissions === 'ADMIN' && associatedContact) {
			items.push({ separator: true })
			items.push({ label: 'Netzwerkkontakt unwiderruflich löschen', disabled: relations !== 0, className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: getEmptyAssociatedContact(),
		validate: validateAssociatedContact,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: getTranslation(NAMESPACE, 'button-edit-associatedcontact.label'), icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly },
		{ key: 'reminder', label: getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.label`), icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		logDefault(CLASSNAME, 'info', 'associatedContact', associatedContact)
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						screenReaderMessage={getScreenreaderMessage('Netzwerkkontakt', associatedContact)}
						listButtons={listButtons}
						title={associatedContact.name}
						subtitle={<BasicSheetSubtitle object={associatedContact} />}
					/>
					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.organization' content={associatedContact.organization ? associatedContact.organization.name : ''} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.region' content={associatedContact.region ? associatedContact.region.name : ''} />
					</div>
					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.phone' content={associatedContact.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-1x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.mobile' content={associatedContact.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-1x'} />} />
					</div>

					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.email' content={<a href={`mailto:${associatedContact.email}`}>{associatedContact.email}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-1x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.fax' content={associatedContact.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-1x'} />} />
					</div>
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.description.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: associatedContact.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={associatedContactUpdate}
							key='associatedContactCommentsModule'
							servicename={ENUM_SERVICES.ASSOCIATEDCONTACTCOMMENTS}
							readonly={readonly}
							parentId={associatedContact.id}
							idFieldname='associatedContactId'
							reminderParentService={ENUM_SERVICES.ASSOCIATEDCONTACTS}
							comments={associatedContact.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				labelObject={{ ...associatedContact }}
				handleOnClick={deleteAssociatedContact}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog}
			/>
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Netzwerkkontakt'
				name={associatedContact.name}
				serviceId={associatedContact.id}
				serviceName={ENUM_SERVICES.ASSOCIATEDCONTACTS}
				userId={currentUser.id}
			/>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelObject={{ action: associatedContact.active ? 'deaktivieren' : 'reaktivieren' }}
				labelKey='confirmdialog-deactivate'
				handleOnClick={toggleStatus}
				displayConfirmDialog={displayStatusDialog}
				setDisplayConfirmDialog={setDisplayStatusDialog}
			/>
			<EditDialog
				form={<AssociatedContactForm formik={formik} key='associatedContact-form' />}
				formik={formik}
				patchForm={patchAssociatedContact}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayAssociatedContactDialog}
				displayDialog={displayAssociatedContactDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, awesomeIcon: ENUM_AWESOMEICON.ASSOCIATEDCONTACTS, component:
					<AssociatedOrganizationSheet
						associatedOrganizationId={associatedContact.associatedOrganizationId}
					/>
			},
		]
		return sideSheets;
	}

	return (!associatedContact ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!associatedContact ? '' : renderContent()}{!associatedContact ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={associatedContact.id}
		/>
	)
}