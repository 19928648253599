export enum ENUM_NAMESPACES {
	ADDRESSES = 'addresses',
	ASSOCIATEDCONTACTS = 'associated-contacts',
	ASSOCIATEDORGANIZATIONS = 'associated-organizations',
	CASEEVENTS = 'case-events',
	CASEPERSONS = 'case-persons',
	CASEREQUESTS = 'case-requests',
	CASEREQUESTTYPES = 'caserequest-types',
	COMMENTS = 'comments',
	COMPANIES = 'companies',
	COMPANIESJOBOPENINGS = 'companies-jobopenings',
	COMPANIESFBIBJOBOPENINGS = 'companies-fbib-jobopenings',
	CONTACTPERSONS = 'contact-persons',
	DASHBOARD = 'dashboard',
	DATAVIEWS = 'dataviews',
	EAACASES = 'eaacases',
	FBIBCASES = 'fbibcases',
	FBIBJOBOPENINGS = 'fbib-jobopenings',
	FBIBPROVIDERACTIVITIES = 'fbib-provider-activities',
	FORMELEMENTS = 'form-elements',
	FTE = 'fte',
	JOBOPENINGS = 'jobopenings',
	LOGIN = 'login',
	ORGANIZATIONS = 'organizations',
	PROVIDERACTIVITIES = 'provider-activities',
	REGIONS = 'regions',
	RELATEDUSERS = 'related-users',
	REMINDERS = 'reminders',
	REQUESTDISTRIBUTIONS = 'request-distributions',
	SETTINGS = 'settings',
	SIDEBAR = 'sidebar',
	STATISTIC = 'statistic',
	TASKAREAS = 'taskareas',
	TRAININGCENTERS = 'trainingcenters',
	TRAININGCOOURSES = 'trainingcourses',
	USERPROFILE = 'userprofile',
	USERADMINISTRATION = 'useradministration',
};

export function getEnumArray() {
	return Object.values(ENUM_NAMESPACES);
}