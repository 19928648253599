import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../Components/Icons';
import { BasicSheetTitleLayout, getScreenreaderMessage } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { ToastError } from '../Utils/ToastError';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { getAssociatedOrganization, patchAssociatedOrganization, toggleStatus } from './AsociatedOrganizationsManager';
import { AssociatedOrganizationContactSheet } from './AssociatedOrganizationContactSheet';
import { AssociatedOrganizationForm, getEmptyAssociatedOrganization, validateAssociatedOrganization } from './AssociatedOrganizationForm';
import { BasicSheetSubtitle } from '../Components/Layouts/BasicSheetSubtitle';
import { AssociatedOrganisationsProvider } from '../Persistence/AssociatedOrganisationsContext';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';
import { formatDate, getTranslation } from '../snippets';
import { parseISO } from 'date-fns';


const SERVICENAME = ENUM_SERVICES.ASSOCIATEDORGANIZATIONS;
const CLASSNAME = 'AssociatedOrganizationData'
const NAMESPACE = ENUM_NAMESPACES.ASSOCIATEDORGANIZATIONS
export const AssociatedOrganizationData = () => {

	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const contactId = queryParams.get('contactid');
	const { associatedOrganizationId } = useParams();
	const [associatedOrganization, setAssociatedOrganization] = useState();
	const [associatedContact, setAssociatedContact] = useState();
	const [associatedContactOrganization, setAssociatedContactOrganization] = useState();
	const [displayStatusDialog, setDisplayStatusDialog] = useState(false);
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [relations, setRelations] = useState(0);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1') {
			errorUpdate('Modul Netzwerkkontakte', 'Das Modul Netzwerkkontakte ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
		setReadonly(isReader)
	}, [])

	const associatedOrganizationUpdate = async () => {
		if (associatedOrganizationId) {
			const associatedContactId = associatedContact ? associatedContact.id : contactId
			return await getAssociatedOrganization(associatedOrganizationId, setAssociatedOrganization, isReader, setReadonly, setToastParams).then((associatedOrganization) => {
				return associatedOrganization !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		new Promise(async () => {
			if (!await associatedOrganizationUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		})
	}, [])

	const handlePatchAssociatedOrganization = async () => {
		await patchAssociatedOrganization(formik.values, setAssociatedOrganization, isReader, setReadonly, setToastParams)
	}

	const onEditAssociatedContact = () => {
		formik.setValues({ ...associatedOrganization })
		setDisplayEditDialog(true)
	}

	const deleteAssociatedContact = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, associatedOrganization.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'Netzwerkkontakt konnte nicht gelöscht werden', message: error.message })
		})
	}
	const renderItems = () => {
		const items = [
			{ label: getTranslation(NAMESPACE, 'button-edit-associatedorganization.label'), icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly },
			{ separator: true },
			{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => setDisplayStatusDialog(true), disabled: isReader }
		]
		if (currentUser.permissions === 'ADMIN' && associatedOrganization) {
			//items.push({ label: 'Netzwerkkontakt unwiderruflich löschen', disabled: relations !== 0, className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: getEmptyAssociatedOrganization(),
		validate: validateAssociatedOrganization,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: getTranslation(NAMESPACE, 'button-edit-associatedorganization.label'), icon: ENUM_ICONS.PENCIL, command: onEditAssociatedContact, disabled: readonly },
		{ key: 'reminder', label: getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.label`), icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderAddress = () => {
		const { line1, line2, line3, postcode, city, country } = associatedOrganization
		const address = [line1, line2, line3, ((postcode || '') + ' ' + (city || '')).trim(), country].filter(e => (e !== null && e !== ''))
		return address.join('; ')

	}

	const renderContent = () => {
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						screenReaderMessage={getScreenreaderMessage('Netzwerkorganisation', associatedOrganization)}
						listButtons={listButtons}
						title={associatedOrganization.name}
						subtitle={<BasicSheetSubtitle object={associatedOrganization} />}
					/>

					<div className='flex card-container blue-container overflow-hidden mb-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.phone' content={associatedOrganization.phone} icon={<i className={ENUM_ICONS.PHONE + 'fa-1x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.mobile' content={associatedOrganization.mobile} icon={<i className={ENUM_ICONS.MOBILE + 'fa-1x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.fax' content={associatedOrganization.fax} icon={<i className={ENUM_ICONS.FAX + 'fa-1x'} />} />
					</div>
					<div className='flex card-container blue-container overflow-hidden my-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.webside' content={associatedOrganization.website} icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.WWW} size={ENUM_AWESOMEICON_SIZE.X1} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.email' content={<a href={`mailto:${associatedOrganization.email}`}>{associatedOrganization.email}</a>} icon={<i className={ENUM_ICONS.ENVELOPE + 'fa-1x'} />} />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.region' className={associatedOrganization.region ? '' : 'hidden'} content={associatedOrganization.region ? associatedOrganization.region.name : ''} />
					</div>
					<div className='flex card-container blue-container overflow-hidden mb-3 grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.address' content={renderAddress()} />
					</div>
					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.description.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: associatedOrganization.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={associatedOrganizationUpdate}
							key='associatedContactCommentsModule'
							servicename={ENUM_SERVICES.ASSOCIATEDORGANIZATIONCOMMENTS}
							readonly={readonly}
							parentId={associatedOrganization.id}
							idFieldname='associatedOrganizationId'
							reminderParentService={ENUM_SERVICES.ASSOCIATEDORGANIZATIONS}
							comments={associatedOrganization.comments} />
					</div>
				</div>
			</div></>
		)
	}

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				handleOnClick={deleteAssociatedContact}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-deactivate'
				labelObject={{ action: associatedOrganization.active ? 'deaktivieren' : 'reaktivieren' }}
				handleOnClick={async () => await toggleStatus(associatedOrganization, setAssociatedOrganization, contactId, isReader, setReadonly, setToastParams, setAssociatedContact)}
				displayConfirmDialog={displayStatusDialog}
				setDisplayConfirmDialog={setDisplayStatusDialog} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Netzwerkkontakt'
				name={associatedOrganization.name}
				serviceId={associatedOrganization.id}
				serviceName={ENUM_SERVICES.ASSOCIATEDORGANIZATIONS}
				userId={currentUser.id} />
			<EditDialog
				form={<AssociatedOrganisationsProvider><AssociatedOrganizationForm
					formik={formik}
					validateForm={() => formik.validateForm()}
					key='associatedOrganization-form' /></AssociatedOrganisationsProvider>}
				formik={formik}
				patchForm={handlePatchAssociatedOrganization}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayEditDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<AssociatedOrganizationContactSheet
						associatedContacts={associatedOrganization.associatedContacts}
						parentSetAssociatedContact={setAssociatedContact}
						readonly={readonly}
						onAssociatedContactPatched={associatedOrganizationUpdate}
						parentId={associatedOrganization.id} />
				</div>
			}

		]
		return sideSheets;
	}

	return (!associatedOrganization ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!associatedOrganization ? '' : renderContent()}{!associatedOrganization ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={associatedOrganization.id}
		/>
	)
}