import { useFormik } from "formik";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsSuperAdmin } from "../Persistence/CurrentUserContext";
import { UserProvider } from "../Persistence/UserContext";
import { StatisticAll } from "./StatisticAll";
import { StatisticForm, emptyStatistic, validateStatistik } from "./StatisticForm";
import { StatisticOrg } from "./StatisticOrg";
import { StatisticPerson } from "./StatisticPerson";
import { useState } from "react";
import { ToastError } from '../Utils/ToastError';
import client from "../feathers";
import { endOfDay, format } from "date-fns";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.STATISTIC;
export const StatisticBIH = () => {
	const currentUser = useCurrentUser();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const [toastParams, setToastParams] = useState(null);
	const [displayDialog, setDisplayDialog] = useState(false);

	const showDialog = async (startDate, endDate, userId, organizationId) => {
		const statisticValues = { ...JSON.parse(JSON.stringify(emptyStatistic)), startDate, endDate, userId, organizationId }
		statisticValues.Integrationsamt = configurationsList.statistics.Integrationsamt
		formikStatistic.setValues(statisticValues)
		if (isAdmin) {
			setDisplayDialog(true)
		} else {
			await onClickCreateStatistic(statisticValues)
		}
	}

	const onClickCreateStatistic = async (statisticValues) => {

		const endDate = endOfDay(statisticValues.endDate)
		const data = { ...statisticValues, endDate, currentUser, method: 'statistic', timezoneOffsetStart: statisticValues.startDate.getTimezoneOffset(), timezoneOffsetEnd: statisticValues.endDate.getTimezoneOffset(), printStopwatch: isSuperAdmin, headlineDate: format(new Date(), 'yyyy-MM-dd HH:mm:ss') }

		await client.service(ENUM_SERVICES.UTILS).create(data).then(async (result) => {
			if (!statisticValues.asExcelSheet) {
				const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_BIH_Statistik.pdf';
				const base64 = await fetch(result);
				var blob = await base64.blob();

				var file = new File([blob], fileName, { lastModified: new Date().getTime(), type: "application/pdf" });
				let exportUrl = URL.createObjectURL(file);
				var fileLink = document.createElement('a');
				fileLink.href = exportUrl;
				fileLink.download = fileName;
				fileLink.click();
			} else {
				import('file-saver').then(module => {
					if (module && module.default) {
						const data = new Blob([result], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
						});

						module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_BIH_Statistik.xlsx');
					}
				});
			}

		}).catch((error) => {
			setToastParams({ title: 'Bei der Erstellung der Statistik ist ein Fehler aufgesteten !', message: error.message })
		});
	}

	/** formik Statistik */
	const formikStatistic = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyStatistic)),
		validate: validateStatistik,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return <div>
		<UserProvider> <StatisticPerson onClickCreateStatistic={showDialog} /></UserProvider>
		{!isAdmin && currentUser.visibilityMode !== 'ALL' ? '' : <StatisticAll onClickCreateStatistic={showDialog} />}
		{!isAdmin && !currentUser.roles.find(e => e.alias === 'statistic-org') ? '' : <StatisticOrg onClickCreateStatistic={showDialog} />}
		<ToastError toastParams={toastParams} />
		<EditDialog
			displayDialog={displayDialog}
			form={<StatisticForm formik={formikStatistic} />}
			formik={formikStatistic}
			NAMESPACE={NAMESPACE}
			labelKey={'editdialog'}
			patchForm={() => onClickCreateStatistic(formikStatistic.values)}
			setDisplayDialog={setDisplayDialog}
		/>
	</div>

}