import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FBiBApplication } from './FBiB/Navigation/FBiBApplication';
import { CompaniesData } from './Companies/CompaniesData';
import { CompaniesDataview } from './Companies/CompaniesDataview';
import { Dashboard } from './Dashboard/Dashboard';
import { EAACaseData } from './EAACase/EAACaseData';
import { EAACaseDataview } from './EAACase/EAACaseDataview';
import { JobOpeningData } from './JobOpenings/JobOpeningData';
import { JobOpeningDataview } from './JobOpenings/JobOpeningsDataView';
import { ENUM_ROUTES } from './Navigation/Routes';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import { LoginProvider } from './Persistence/LoginContext';
import { ProviderActivitiesDataview } from './ProviderActivities/ProviderActivitiesDataview';
import { ProviderActivityData } from './ProviderActivities/ProviderActivityData';
import { RequestDistributionData } from './RequestDistributions/RequestDistributionData';
import { RequestDistributionsArchivDataview } from './RequestDistributions/RequestDistributionsArchivDataview';
import { RequestDistributionsDataview } from './RequestDistributions/RequestDistributionsDataview';
import { RequestDistributionsUserRelations } from './RequestDistributions/RequestDistributionsUserRelations';
import { Settings } from './Settings/Settings';
import { Statistic } from './Statistic/Statistic';
import { UserAdministration } from './UserAdministration/UserAdministration';
import { UserProfile } from './UserAdministration/UserProfile';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { ErrorPage } from './Navigation/ErrorPage';
import { AssociatedContactsDataview } from './AssociatedContacts/AssociatedContactsDataview';
import { AssociatedContactData } from './AssociatedContacts/AssociatedContactData';
import { OverviewContainer } from './Overviews/OverviewContainer';
import { Application } from './Application';
import { FBiBDashboard } from './FBiB/Dashboard/FBiBDashboard';
import { TrainingCentersDataview } from './FBiB/TrainingCenters/TrainingCentersDataview';
import { TrainingCenterData } from './FBiB/TrainingCenters/TrainingCenterData';
import { FBiBCasesDataview } from './FBiB/FBiBCases/FBiBCasesDataview';
import { FBiBCaseData } from './FBiB/FBiBCases/FBiBCaseData';
import { FBiBProviderActivitiesDataview } from './FBiB/FBiBProviderActivities/FBiBProviderActivitiesDataview';
import { FBiBProviderActivityData } from './FBiB/FBiBProviderActivities/FBiBProviderActivityData';
import { TrainingCourseDataview } from './FBiB/TrainingCourses/TrainingCourseDataview';
import { TrainingCourseData } from './FBiB/TrainingCourses/TrainingCourseData';
import { FBiBStatistic } from './FBiB/Statistic/FBiBStatistic';
import { FBiBJobOpeningDataview } from './FBiB/FBiBJobOpenings/FBiBJobOpeningsDataView';
import { FBiBJobOpeningData } from './FBiB/FBiBJobOpenings/FBiBJobOpeningData';
import { AssociatedOrganizationData } from './AssociatedOrganizations/AssociatedOrganizationData';

Sentry.init({
	dsn: "https://5b5be9fff7024ca28b6d792760820bbf@sentry.bos-connect.de/4",
	tracesSampleRate: 1.0,
	environment: window.location.hostname,
	replaysSessionSampleRate: 0.0,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new Sentry.Replay({
			// Additional SDK configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
	],
});

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
/* root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
) */
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<CurrentUserProvider><LoginProvider><Application /></LoginProvider></CurrentUserProvider>}>
					<Route index element={<Dashboard />} />
					<Route path={`Fallverwaltung`} element={<EAACaseDataview />} />
					<Route path={`Fallverwaltung/:eaaCaseId`} element={<EAACaseData />} />
					<Route path={`${ENUM_ROUTES.ERROR}`} element={<ErrorPage />} />
					<Route path={`${ENUM_ROUTES.DASHBOARD}`} element={<Dashboard />} />
					<Route path={`${ENUM_ROUTES.COMPANIES}`} element={<CompaniesDataview />} />
					<Route path={`${ENUM_ROUTES.COMPANIES}/:companyId`} element={<CompaniesData />} />
					<Route path={`${ENUM_ROUTES.SETTINGS}`} element={<Settings />} />
					<Route path={`${ENUM_ROUTES.EAACASES}`} element={<EAACaseDataview />} />
					<Route path={`${ENUM_ROUTES.EAACASES}/:eaaCaseId`} element={<EAACaseData />} />
					<Route path={`${ENUM_ROUTES.STATISTICS}`} element={<Statistic />} />
					<Route path={`${ENUM_ROUTES.PROVIDERACTIVITIES}`} element={<ProviderActivitiesDataview />} />
					<Route path={`${ENUM_ROUTES.PROVIDERACTIVITIES}/:provideractivityId`} element={<ProviderActivityData />} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}`} element={<JobOpeningDataview />} />
					<Route path={`${ENUM_ROUTES.JOBPROFILES}/:jobOpeningId`} element={<JobOpeningData />} />
					<Route path={`${ENUM_ROUTES.OVERVIEWS}`} element={<OverviewContainer />} />
					<Route path={`${ENUM_ROUTES.REQUESTDISTRIBUTIONARCHIVE}`} element={<RequestDistributionsArchivDataview />} />
					<Route path={`${ENUM_ROUTES.REQUESTDISTRIBUTION}`} element={<RequestDistributionsDataview />} />
					<Route path={`${ENUM_ROUTES.REQUESTDISTRIBUTIONUSERRELATIONS}`} element={<RequestDistributionsUserRelations />} />
					<Route path={`${ENUM_ROUTES.REQUESTDISTRIBUTION}/:requestDistributionId`} element={<RequestDistributionData />} />
					<Route path={`${ENUM_ROUTES.USERADMINISTRATION}`} element={<UserAdministration />} />
					<Route path={`${ENUM_ROUTES.ASSOCIATEDCONTACTS}`} element={<AssociatedContactsDataview />} />
					<Route path={`${ENUM_ROUTES.ASSOCIATEDCONTACTS}/:associatedContactId`} element={<AssociatedContactData />} />
					<Route path={`${ENUM_ROUTES.ASSOCIATEDORGANIZATIONS}/:associatedOrganizationId`} element={<AssociatedOrganizationData />} />
					<Route path={`${ENUM_ROUTES.USERPROFILE}`} element={<UserProfile />} />
				</Route>
				<Route path="/FBiB" element={<CurrentUserProvider><LoginProvider><FBiBApplication /></LoginProvider></CurrentUserProvider>}>
					<Route index element={<FBiBDashboard />}></Route>
					<Route path={`${ENUM_ROUTES.DASHBOARD.replace('FBiB/', '')}`} element={<FBiBDashboard />} />
					<Route path={`${ENUM_ROUTES.COMPANIES.replace('FBiB/', '')}`} element={<CompaniesDataview />} />
					<Route path={`${ENUM_ROUTES.COMPANIES.replace('FBiB/', '')}/:companyId`} element={<CompaniesData />} />
					<Route path={`${ENUM_ROUTES.FBIB_CASES.replace('FBiB/', '')}`} element={<FBiBCasesDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_CASES.replace('FBiB/', '')}/:fbibCaseId`} element={<FBiBCaseData />} />
					<Route path={`${ENUM_ROUTES.FBIB_TRAININGCENTERS.replace('FBiB/', '')}`} element={<TrainingCentersDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_TRAININGCENTERS.replace('FBiB/', '')}/:trainingCenterId`} element={<TrainingCenterData />} />
					<Route path={`${ENUM_ROUTES.FBIB_TRAININGCOURSES.replace('FBiB/', '')}`} element={<TrainingCourseDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_TRAININGCOURSES.replace('FBiB/', '')}/:trainingCourseId`} element={<TrainingCourseData />} />
					<Route path={`${ENUM_ROUTES.FBIB_PROVIDERACTIVITIES.replace('FBiB/', '')}`} element={<FBiBProviderActivitiesDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_PROVIDERACTIVITIES.replace('FBiB/', '')}/:provideractivityId`} element={<FBiBProviderActivityData />} />
					<Route path={`${ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS.replace('FBiB/', '')}`} element={<AssociatedContactsDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_ASSOCIATEDCONTACTS.replace('FBiB/', '')}/:associatedContactId`} element={<AssociatedContactData />} />
					<Route path={`${ENUM_ROUTES.FBIB_ASSOCIATEDORGANIZATIONS.replace('FBiB/', '')}/:associatedOrganizationId`} element={<AssociatedOrganizationData />} />
					<Route path={`${ENUM_ROUTES.FBIB_JOBPROFILES.replace('FBiB/', '')}`} element={<FBiBJobOpeningDataview />} />
					<Route path={`${ENUM_ROUTES.FBIB_JOBPROFILES.replace('FBiB/', '')}/:jobOpeningId`} element={<FBiBJobOpeningData />} />
					<Route path={`${ENUM_ROUTES.FBIB_STATISTICS.replace('FBiB/', '')}`} element={<FBiBStatistic />} />
					<Route path={`${ENUM_ROUTES.FBIB_USERPROFILE.replace('FBiB/', '')}`} element={<UserProfile />} />
				</Route>

			</Routes>
		</BrowserRouter>
	</React.StrictMode >,
);


reportWebVitals();
