import { useEffect, useState } from "react";
import { getTranslation } from "../../snippets"
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { InputText } from "primereact/inputtext";
import { useFBiBMode } from "../../Persistence/ConfigurationsContext";

const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
const TextFilterElement = ({ options, translationKey }) => {
    const fbibMode = useFBiBMode();
    const [labels, setLabels] = useState(getTranslation(NAMESPACE, translationKey, { returnObjects: true }));

    return <InputText
        aria-label={labels?.headline ? labels.headline.ariaLabel : ''}
        className={options.value ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : ''}
        value={options.value}
        placeholder={labels?.placeholder ? labels.placeholder['label'] : ''}
        aria-placeholder={labels?.placeholder ? labels.placeholder.ariaLabel : ''}
        onChange={(e) => { options.filterApplyCallback(e.target.value) }} />
}

export const getTextFilterElement = (dataviewConfigKey) => {
    return (options) => {
        return <TextFilterElement options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />
    }
}