import { startOfYear } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useState, useEffect } from "react";
import { useOrganizationsList } from "../Persistence/OrganizationsContext";
import { useCurrentUser, useCurrentUserIsAdmin } from "../Persistence/CurrentUserContext";
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.STATISTIC
export const StatisticOrg = ({ onClickCreateStatistic }) => {
	const currentUser = useCurrentUser()
	const isAdmin = useCurrentUserIsAdmin();
	const list = useOrganizationsList();
	const [dateFrom, setDateFrom] = useState(startOfYear(new Date()));
	const [dateTo, setDateTo] = useState(new Date());
	const [organization, setOrganization] = useState();
	const [orgList, setOrgList] = useState([])

	useEffect(() => {
		if (list) {
			if (isAdmin) {
				setOrgList(orgList)
			} else {
				const org = list.find(e => e.id === currentUser.organizationId)
				setOrganization(org)
				setOrgList([org])
			}
		}
	}, [list])

	return (
		<div className="card shadow-2 p-4 my-4 bg-white">
			<div className='font-bold mb-4'>{getTranslation(NAMESPACE, 'BIH.label.organization.headline')}</div>
			<div className="p-fluid grid formgrid gap-4">
				<div className="field col-fixed w-15rem">
					<label htmlFor="person" aria-label={getTranslation(NAMESPACE, 'BIH.ariaLabel.organization.dropdown.label')} >{getTranslation(NAMESPACE, 'BIH.label.organization.dropdown.label')}</label>
					<Dropdown
						id="organization"
						name="organization"
						disabled={!isAdmin && !currentUser.roles.find(e => e.alias === 'statistic-org')}
						optionLabel="name"
						value={organization}
						options={list}
						filter
						filterBy="name"
						showFilterClear
						showClear
						onChange={(e) => setOrganization(e.value)}
						autoFocus
						className={'bg-white w-full'}
					/>
				</div>
				<div className="field col-fixed w-15rem">
					<label htmlFor="dateFrom" aria-label={getTranslation(NAMESPACE, 'BIH.ariaLabel.from')}>{getTranslation(NAMESPACE, 'BIH.label.from')}</label>
					<Calendar maxDate={dateTo} id="dateFrom" value={dateFrom} onChange={(e) => setDateFrom(e.value)} showIcon dateFormat="dd.mm.yy" />
				</div>
				<div className="field col-fixed w-15rem">
					<label htmlFor="dateTo" aria-label={getTranslation(NAMESPACE, 'BIH.ariaLabel.to')}>{getTranslation(NAMESPACE, 'BIH.label.to')}</label>
					<Calendar minDate={dateFrom} maxDate={new Date()} id="dateTo" value={dateTo} onChange={(e) => setDateTo(e.value)} showIcon dateFormat="dd.mm.yy" />
				</div>
			</div>
			<div className='flex '>
				<Button
					disabled={organization === null || dateFrom === null || dateTo === null}
					onClick={() => onClickCreateStatistic(dateFrom, dateTo, null, (organization ? organization.id : null))}
					className='flex  text-white'
					aria-label={getTranslation(NAMESPACE, 'BIH.ariaLabel.button')}
					label={getTranslation(NAMESPACE, 'BIH.label.button')} />
			</div>
		</div>
	)
}