import { useEffect, useState } from 'react';
import { AutoCompleteChangesChecked } from "../../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from '../../Components/DropdownChangesChecked';
import { ENUM_ICONS } from '../../Components/Icons';
import { InputChangesChecked } from "../../Components/InputChangesChecked";

import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { InputNumberChecked } from '../../Components/InputNumberChecked';
import { getData } from '../../feathers';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { SelectButtonChecked } from '../../Components/FormElements/SelectButtonChecked';
import { EditorChangesChecked } from '../../Components/EditorChangesChecked';
import { InputTextareaChangesChecked } from '../../Components/InputTextareaChangesChecked';

export const emptyFBiBCasePerson = { id: null, version: 1, active: 1, personId: '', GdB: '', GdBMark: '', disablityKind: '', gender: '', parentsConsent: 0, disabledPersonsPass: 0, careerAspiration: '', isPupil: 0, rehaStatus: 0, bvb: 0 }

export const FBiBCasePersonForm = ({ formik }) => {

	const configurationsList = useConfigurationsList();

	const [dataviewList, setDataviewList] = useState([])

	useEffect(() => {
		setTimeout(async () => {
			const listPersons = await getData(ENUM_SERVICES.FBIBCASEPERSONS);
			setDataviewList(listPersons)
		}, 1)
	}, [])


	const handleCasePersonChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			e.value.gender = configurationsList.GenderTypeList.find(entry => entry.alias === e.value.gender)
			formik.setValues(e.value)
		} else {
			formik.setFieldValue('personId', e.value)
		}
	}

	return (
		<div>{!formik ? <div></div> :
			<div className='flex flex-column w-full'>
				<div className="formgrid grid mr-1">
					{formik.values.id ? <>
						<div className="field col ">Person ID: {formik.values.personId}</div>
						<div className="field col-fixed w-3rem p-0"> <i className={ENUM_ICONS.TRASH} onClick={() => { formik.setValues({ ...emptyFBiBCasePerson }) }} /></div>
					</>
						:
						<AutoCompleteChangesChecked dropdown={true} label={'Bekannte Personennummer'} id={'personId'} itemLabel={'personId'} formik={formik} forceSelection={false} suggestionsList={dataviewList}
							helptextcolor='#F4A261' helptext='Eine Personen ID mit diesem Namen ist der Datenbank nicht bekannt. Beim speichern wird diese Id mit den eingegebenen Daten in der Datenbank eingetragen.'
							handleChange={handleCasePersonChange} />
					}
				</div>
				<div className="formgrid grid mr-1">
					<InputNumberChecked id='GdB' label='Grad der Behinderung' showButtons={true} min={0} max={100} step={10} formik={formik} col='col-3' className='w-3rem' />
					<DropdownChangesChecked
						label='Geschlecht'
						id='gender'
						itemLabel='display'
						formik={formik}
						options={configurationsList.GenderTypeList}
						className='col-3'
						classNameDropdown='ml-1 w-14rem'
					/>
					<InputChangesChecked id='GdBMark' label='Merkzeichen' type="text" formik={formik} col='col-6' className='m-0' />
				</div>
				<div className="formgrid grid mr-1">
					<InputChangesChecked id='disablityKind' label='Art der Behinderung' type="text" formik={formik} />
					<InputChangesChecked id='careerAspiration' label='Berufswünsche' type="text" formik={formik} />
				</div>
				<div className="formgrid grid mr-1">
					<SelectButtonChecked id='parentsConsent' label='Einwilligung der Eltern liegt vor' formik={formik} />
					<div className="field col mr-3" />
				</div>
				<div className="formgrid grid mr-1">
					<SelectButtonChecked id='rehaStatus' label='Reha-Status liegt vor' formik={formik} />
					<div className="field col mr-3" />
				</div>
				<div className="formgrid grid mr-1">
					<SelectButtonChecked id='isPupil' label='Schüler*in' formik={formik} />
					<div className="field col mr-3" />
				</div>
				<div className="formgrid grid mr-1">
					<SelectButtonChecked id='bvb' label='Berufsvorbereitende Bildung (BvB)' formik={formik} />
					<div className="field col mr-3" />
				</div>
				<div className="formgrid grid mr-1">
					<SelectButtonChecked id='disabledPersonsPass' label='Sb-Ausweis liegt vor' formik={formik} />
					<div className="field col mr-3" />
				</div>
				<div className="formgrid grid mt-2">
					<InputTextareaChangesChecked id='description' label='Erläuterung zur Fallperson' rows={3} formik={formik} autoResize={true} className='mr-3' />
				</div>
			</div>
		}</div>
	)
}


export const validateFBiBCasePerson = (data) => {
	let errors = {};
	if (!data.personId) {
		errors.personId = 'Geben Sie die Personen ID ein. Wenn diese ID bereits in der Datenbank existiert, können Sie sie auswählen. Wenn die Personen ID noch nicht in der Datenbank existiert, können Sie eine neue ID wählen und die Informationen eintragen und speichern.';
	}
	return errors;
}