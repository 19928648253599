import { useEffect, useState } from "react";
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useCompaniesInit, useCompaniesList } from "../Persistence/CompaniesContext";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from "../Components/FormElements/DropdownChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import { getData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const NAMESPACE = ENUM_NAMESPACES.COMPANIES
export const employmentObligationOptions = [{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }, { label: 'Berechnet', value: 2 }, { label: 'Offen', value: 3 }]
export const emptyCompany = { id: null, version: 1, active: 1, origin: 'EAA', name: '', phone: '', mobile: '', fax: '', mail: '', employees: 0, employeesSbM: 0, employmentObligation: 3, chamber: '', chamberNo: '', dataprotectionsheet: 0, branchId: null, regionId: '', region: '', addresses: [], contactPersons: [], updateUser: null, companyUsers: [], companyComments: null, description: '', existingCompany: 0, companyCheck: false, companyChange: false, oldCompanyId: undefined }

export const CompanyForm = ({ formik, handleSelectExistingCompany, readOnly, showTrash, validateForm }) => {

	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();
	const useCompanyList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const configurationList = useConfigurationsList();
	const [mounted, setMounted] = useState(false);

	const [regionsSelection, setRegionsSelection] = useState([]);
	const [companiesList, setCompaniesList] = useState([])

	useEffect(() => {
		if (useCompanyList) {
			setCompaniesList(useCompanyList.filter((entry) => entry.active))
		}
		setMounted(true)
	}, [useCompanyList])

	const createRegionSelection = () => {
		/** prüfe ob die Region die bisher bei dem Unternehmen gesetzt war noch in der Liste ist.
		 * Wenn nicht -> hinzufügen
		 */
		const regionSelection = regionsSelectionList ? JSON.parse(JSON.stringify(regionsSelectionList)) : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		if (!formik.values.id && !formik.values.regionId && regionSelection && regionSelection.length === 1) {
			formik.setFieldValue('regionId', regionSelection[0].id)
		}
		setRegionsSelection(regionSelection)
	}

	useEffect(() => {
		createRegionSelection();
		companiesInit();
	}, [])

	const clearCompany = () => {
		const newCompany = JSON.parse(JSON.stringify(emptyCompany))
		formik.setValues({ ...newCompany, oldCompanyId: formik.values.oldCompanyId, changeCompany: formik.values.changeCompany })
		if (!formik.values.changeCompany) {
			handleSelectExistingCompany(null)
		}
	}

	const handleCompanyNameChange = async (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			/** Eine bestehenes Unternehmen wurde ausgewählt 
			 * Option 1: Auswahl bei Fallanlage, Stellenanlage -> id == null und changeCompany == false
			 * Option 2: Wechsel des Unternehmens im EAA-Fall -> id != null und changeCompany == true
			 * 
			*/
			if (!formik.values.changeCompany) {
				handleSelectExistingCompany(e.value.id)
			} else {
				const company = await getData(ENUM_SERVICES.COMPANIES, e.value.id)
				const values = { ...company, changeCompany: true, regionId: e.value.region.id, oldCompanyId: formik.values.oldCompanyId }
				formik.setValues(values)
				formik.setFieldValue('regionId', values.regionId)
				if (validateForm) {
					console.log('validate company form')
					setTimeout(() => validateForm(), 1)
				}

			}

		} else {
			formik.setFieldValue('name', e.value)
		}
	}

	const handleCompanyNameBlur = (e) => {
		const existingCompany = useCompanyList.find(company => (company.name.toUpperCase().trim() === e.target.value.toUpperCase().trim() && company.id !== formik.values.id))
		formik.setValues({
			...formik.values, name: formik.values.name.trim(), existingCompany: (existingCompany && formik.values.id !== existingCompany.id ? (existingCompany.active ? 1 : -1) : 0),
			companyCheck: false
		})
	}

	return (
		<div>{!formik ? '' :
			< div className='col p-0 m-0' >
				<div className="formgrid grid">

					{formik.values && !formik.values.id ? <>
						<AutoCompleteChangesChecked dropdown={true}
							label={getTranslation(NAMESPACE, 'fields.name.label')}
							id={'name'}
							itemLabel={'name'}
							formik={formik}
							forceSelection={false}
							suggestionsList={companiesList}
							helptextcolor='#11b9a0'
							helptext={formik.values.changeCompany ? undefined : 'Ein Unternehmen mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird das Unternehmen mit den eingegebenen Daten in der Datenbank eingetragen.'}
							onBlur={handleCompanyNameBlur}
							onFocus={() => formik.setFieldValue('companyCheck', true)}
							handleChange={handleCompanyNameChange} />
					</> : <>
						<InputChangesChecked id='name'
							type="text"
							translationKey='fields.name'
							NAMESPACE={NAMESPACE}
							formik={formik}
							handleBlur={handleCompanyNameBlur}
							disabled={(formik.values && formik.values.id) && (showTrash || formik.values.changeCompany)}
							helptextcolor='#24A347'
							onFocus={() =>
								formik.setFieldValue('companyCheck', true)
							}
							helptext_old='Sie haben ein bekanntes Unternehmen aus der Datenbank ausgewählt. Es wird kein neues Unternehmen erstellt.'
						/>
						{(formik.values && formik.values.id) && (showTrash || formik.values.changeCompany) ? <div className="flex h-4rem mr-4">
							<div className="flex align-items-end w-full" onClick={clearCompany}>
								<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.BACKSPACE} size={ENUM_AWESOMEICON_SIZE.XL2} />
							</div>
						</div> : ''}
					</>}

				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						id='phone'
						translationKey='fields.phone'
						type="text"
						formik={formik}
						NAMESPACE={NAMESPACE}
						disabled={readOnly}
					/>
					<InputChangesChecked
						id='fax'
						translationKey='fields.fax'
						type="text"
						formik={formik}
						NAMESPACE={NAMESPACE}
						disabled={readOnly} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						id='mobile'
						translationKey='fields.mobile'
						NAMESPACE={NAMESPACE}
						type="text"
						formik={formik} disabled={readOnly}
					/>
					<InputChangesChecked
						id='mail'
						translationKey='fields.mail'
						NAMESPACE={NAMESPACE}
						type="text"
						formik={formik}
						disabled={readOnly}
					/>
				</div>

				<div className="formgrid grid">
					<InputNumberChecked
						className='w-5rem'
						id='employees'
						translationKey='fields.employees'
						disabled={readOnly}
						NAMESPACE={NAMESPACE}
						type="number"
						formik={formik}
						min={0}
						showButtons={true}
					/>
					<InputNumberChecked
						className='w-5rem '
						id='employeesSbM'
						translationKey='fields.employeesSbM'
						disabled={readOnly}
						NAMESPACE={NAMESPACE}
						type="number"
						formik={formik}
						min={0}
						showButtons={true}
					/>
				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						id='chamber'
						translationKey='fields.chamber'
						NAMESPACE={NAMESPACE}
						type="text"
						formik={formik}
						disabled={readOnly}
					/>
					<InputChangesChecked
						id='chamberNo'
						translationKey='fields.chamberNo'
						NAMESPACE={NAMESPACE}
						type="text"
						formik={formik}
						disabled={readOnly}
					/>
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						NAMESPACE={NAMESPACE}
						translationKey='fields.region'
						id='regionId'
						options={regionsSelection}
						disabled={readOnly}
						formik={formik}
						optionValue='id'
						optionLabel='name'
						onChange={createRegionSelection}
						className='mr-3'
					/>
					<DropdownChangesChecked
						NAMESPACE={NAMESPACE}
						translationKey='fields.employmentObligation.form'
						id='employmentObligation'
						options={employmentObligationOptions}
						disabled={readOnly}
						formik={formik}
						optionValue='value'
						optionLabel='label'
						className='mr-3'
					/>
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						options={configurationList.BranchTypeList || []}
						placeholder={getTranslation(NAMESPACE, 'branch.placeholder.label')}
						disabled={readOnly}
						formik={formik}
						id='branchId'
						optionValue='id'
						optionLabel='display'
						NAMESPACE={NAMESPACE}
						translationKey='fields.branch'
						className='mr-3'
					/>
					{configurationList.eaaModules.DATAPROTECTIONSHEET !== '1' ? '' :
						<DropdownChangesChecked
							options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]}
							formik={formik}
							id='dataprotectionsheet'
							NAMESPACE={NAMESPACE}
							translationKey='fields.dataprotectionsheet.form'
							optionValue='value'
							optionLabel='label'
							classnameDiv='m-0 mr-3'
						/>}
				</div>
				<div className="formgrid grid">
					{!mounted ? '' : <EditorChangesChecked
						id='description'
						value={formik.values.description}
						setValue={(value) => formik.setFieldValue('description', value)}
						NAMESPACE={NAMESPACE}
						translationKey='fields.description'
						disabled={readOnly} />}
				</div>
			</div>
		}</div>
	)
}

export const validateCompany = (data) => {
	let errors = {};
	if (data.changeCompany && data.oldCompanyId === data.id) {
		errors.name = 'Kein Wechsel des Unternehmens erkannt.';
	} else if (!data.changeCompany) {
		if (data.companyCheck) {
			errors.name = getTranslation(NAMESPACE, 'fields.name.error-check', { returnObjects: true });
		} else if (data.existingCompany === 1) {
			errors.name = getTranslation(NAMESPACE, 'fields.name.error-exists', { returnObjects: true });
		} else if (data.existingCompany === -1) {
			errors.name = getTranslation(NAMESPACE, 'fields.name.error-inactive', { returnObjects: true });
		} else if (!data.name) {
			errors.name = getTranslation(NAMESPACE, 'fields.name.error', { returnObjects: true });
		}
	}

	if (!data.regionId) {
		errors.regionId = getTranslation(NAMESPACE, 'fields.region.error', { returnObjects: true });
	}
	if (!data.employees === '0' && !data.employees) {
		errors.employees = getTranslation(NAMESPACE, 'fields.employees.error', { returnObjects: true });
	}
	if (!data.employeesSbM === '0' && !data.employeesSbM) {
		errors.employeesSbM = getTranslation(NAMESPACE, 'fields.employeesSbM.error', { returnObjects: true });
	} else if (data.employeesSbM > data.employees) {
		errors.employeesSbM = 'SbM muss kleiner als Anzahl Mitarbeiter*innen sein!';
	}
	return errors;
}