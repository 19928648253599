import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";
import { useCallbackPrompt } from "../../Utils/useCallbackPrompt";
import UnsafedChangesDialog from "../UnsafedChangesDialog";
import { useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../../snippets";
import './EditDialog.scss'

export const EditDialog = ({ form, formik, labelKey, labelObject, patchForm, displayDialog, setDisplayDialog, className, onCancel, additionalButton, refCancel, NAMESPACE }) => {
	const fbibMode = useFBiBMode();
	const [hasErrors, setHasErrors] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
	const [screenReaderMessage, setScreenReaderMessage] = useState('');
	const [labels, setLabels] = useState(getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `editdialog`, { returnObjects: true }));

	useEffect(() => {
		let dialogLabels = NAMESPACE && labelKey ? getTranslation(NAMESPACE, `${labelKey}`, (labelObject ? { ...labelObject, returnObjects: true } : { returnObjects: true })) : labels
		if (!dialogLabels['button-ok']) {
			dialogLabels['button-ok'] = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `editdialog.button-ok`, { returnObjects: true })
		}
		if (!dialogLabels['button-cancel']) {
			dialogLabels['button-cancel'] = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `editdialog.button-cancel`, { returnObjects: true })
		}
		if (!dialogLabels.message) {
			dialogLabels.message = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `editdialog.message`, { returnObjects: true })
		}
		if (!dialogLabels.headline) {
			dialogLabels.headline = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `editdialog.headline`, { returnObjects: true })
		}
		setLabels(dialogLabels)
	}, [labelObject])

	useEffect(() => {
		setScreenReaderMessage(getHintMessage())
		setHasErrors(Object.keys(formik.errors).length !== 0)
	}, [formik.errors])

	useEffect(() => {
		const touched = Object.keys(formik.touched);
		setShowDialog(touched.length !== 0 && touched[0] !== 'undefined')
	}, [formik.touched])

	const getHintMessage = () => {
		let keys = Object.keys(formik.errors)
		let message = `Bearbeitungshinweise ${keys.length === 0 ? 'keine Probleme' : ''}`
		for (let key of keys) {
			message += `${formik.errors[key].ariaLabel} `
		}
		return message
	}

	const onClickSave = async () => {
		formik.setTouched({})
		setShowDialog(false);
		setDisplayDialog(false);
		await patchForm();
	}
	const onClickCancel = () => {
		formik.setTouched({})
		setShowDialog(false);
		setDisplayDialog(false);
		if (onCancel) {
			onCancel()
		}
	}

	const renderFooter = () => {
		return <div className={'flex flex-row-reverse  mx-4 my-2'}>
			<Button
				disabled={hasErrors}
				onClick={onClickSave}
				className=" ml-3 "
				label={labels['button-ok'][formik.values.id ? 'edit' : 'new'].label}
				aria-label={labels['button-ok'][formik.values.id ? 'edit' : 'new'].ariaLabel}
			/>
			{!additionalButton ? '' : <Button
				type="button"
				className={' ml-3 '}
				label={additionalButton.label}
				aria-label={additionalButton.ariaLabel}
				onClick={() => { setShowDialog(false); additionalButton.onClick() }}
			/>
			}
			<Button
				type="button"
				ref={refCancel}
				className={'button-cancel'}
				label={labels['button-cancel'][formik.values.id ? 'edit' : 'new'].label}
				aria-label={labels['button-cancel'][formik.values.id ? 'edit' : 'new'].ariaLabel}
				onClick={onClickCancel}
			/>
		</div>
	}

	const renderHeader = () => {
		return <div className="flex justify-content-between">
			<div aria-hidden={true}>{labels ? labels.headline[formik.values.id ? 'edit' : 'new'].label : ''}</div>
			<Button
				aria-label='Dialog schliessen'
				icon="pi pi-times"
				className="text-xs p-button-text"
				onClick={onClickCancel}
			/>
		</div>
	}

	return (<>
		<UnsafedChangesDialog
			// @ts-ignore
			showDialog={showPrompt}
			confirmNavigation={confirmNavigation}
			cancelNavigation={cancelNavigation}
		/>
		<Dialog header={renderHeader()}
			footer={renderFooter}
			visible={displayDialog}
			onHide={onClickCancel}
			onShow={() => setScreenReaderMessage(`${labels?.message[formik.values.id ? 'edit' : 'new'].label || ''} ${getHintMessage()}`)}
			id="dialog-editdialog"
			closable={true}
			className={`${className || 'eaa-inputdialog'} ${fbibMode ? ' fbib' : ''}`} >
			<>
				<div aria-live="polite" className="screen-reader-only">{screenReaderMessage}</div>
				<div
					className={'flex font-light mb-3 p-0 '}
					style={{ whiteSpace: "pre-line" }}
					autoFocus={true}
					dangerouslySetInnerHTML={{ __html: labels?.message[formik.values.id ? 'edit' : 'new'].label || '' }}>

				</div>
			</>
			{form}


		</Dialog>
	</>
	)
}