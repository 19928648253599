
import { useEffect, useRef, useState } from 'react';
import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils"
import { logDefault } from "../../Utils/logger";
import { InputMask } from 'primereact/inputmask';
import { getTranslation } from '../../snippets';
import { format, parse } from 'date-fns';
import './FormElements.scss'

export const CalendarChangesChecked = ({ id, formik, className, setShowDialog, disabled, tabIndex, classNameCalendar, minDate, maxDate, onKeyDown, NAMESPACE, translationKey }) => {
	const timeInputRef = useRef(null);
	const calendarRef = useRef(null);
	const [validDate, setValidDate] = useState(true);
	const [dateInput, setDateInput] = useState();
	const [dateCalendar, setDateCalendar] = useState();

	useEffect(() => {
		logDefault('CalendarChangesChecked', 'info', 'id:' + id + ' = ' + formik.values[id])
		formik.setFieldValue('timeInputRef', timeInputRef)
		setDateState()
	}, [])

	useEffect(() => {
		setDateState()
	}, [formik.values[id]])

	const setDateState = () => {
		const date = formik.values[id];
		if (date) {
			setValidDate(true)
			setDateCalendar(date)
			setDateInput(format(formik.values[id], 'dd.MM.yyyy'))
		} else {
			setValidDate(false)
		}
	}

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id].label}</small>;
	};

	const onChangeInput = (e) => {
		const date = parse(e.value, 'dd.MM.yyyy', new Date())
		if (('' + date) !== 'Invalid Date') {
			onChangeCalendar({ target: { id, value: date } })
		} else {
			onChangeCalendar({ target: { id, value: null } })
			setValidDate(false)
		}
	}

	const onChangeCalendar = (e) => {
		if (setShowDialog) {
			setShowDialog(true);
		}
		formik.handleChange(e)
	}

	return (
		<div className={`calendar-changes-checked ${className || 'mr-3'} field col`} onKeyDown={onKeyDown}>
			<label htmlFor={id} className='block'>{getTranslation(NAMESPACE, `${translationKey || id}.label`)}</label>
			<div className='flex'>
				<InputMask
					key={'inputmask-date-' + id}
					mask="99.99.9999"
					slotChar="TT.MM.JJJJ"
					className={`flex flex-grow-1 inputmask ${(validDate ? '' : 'text-red-500')} ${classNames({ 'p-invalid': isFormFieldValid(id) })}`}
					value={dateInput}
					tabIndex={tabIndex}
					onChange={onChangeInput}
				/>
				<Calendar
					key={'calendar-date-' + id}
					id={id}
					className={`flex calendar-element ${classNames({ 'p-invalid': isFormFieldValid(id) })} ${(classNameCalendar)}`}
					ref={calendarRef}
					onBlur={() => { calendarRef.current.hide() }}
					value={formik.values[id]}
					onChange={onChangeCalendar}
					showIcon
					dateFormat="dd.mm.yy"
					disabled={disabled ? true : false}
					minDate={minDate ? minDate : null}
					maxDate={maxDate ? maxDate : null}
				/>
			</div>
			{getFormErrorMessage(id)}
		</div>
	)

}

