import { InputField } from "../Components/InputField"
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import { copyObject, getSortedList, getTranslation } from "../snippets";
import { DropdownChangesChecked } from "../Components/FormElements/DropdownChangesChecked";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useEffect, useState } from "react";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.CONTACTPERSONS
const CLASSNAME = 'ContactPersonsForm'
export const CONTACTPERSON_SERVICECONFIGLIST = {
	COMPANY: { servicename: ENUM_SERVICES.COMPANIES, contactTypeListname: 'ContactTypeList', refFieldname: 'companyId', displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	TRAININGCENTER: { servicename: ENUM_SERVICES.TRAININGCENTERS, contactTypeListname: 'TrainingcenterContactTypeList', refFieldname: 'trainingCenterId', displayname: 'Ausbildungsstätte', deleteMessage: 'von der Ausbildungsstätte', editMessage: 'der Ausbildungsstätte' }
}



export const ContactPersonsForm = ({ formik, CONTACTPERSON_SERVICECONFIG, addressList }) => {
	const configurationsList = useConfigurationsList();

	const getAddressList = () => {
		const addresses = copyObject(addressList)
		for (let address of addresses) {
			const addressType = configurationsList.AddressTypeList.find(e => e.alias === address.addressType)
			address.addressTypeDisplay = addressType ? addressType.display : addressType
			address.listentry = `${addressType ? addressType.display : ''} ${address.line1} ${address.line2} ${address.line3} in ${address.postcode} ${address.city}`
		}
		addresses.unshift({ id: null, addressTypeDisplay: 'keine Adresse zugeordnet', listentry: '', city: '', postcode: '', line1: '' })
		return addresses
	}

	const addressListTemplate = (entry) => {
		return <div aria-label={`${entry.addressTypeDisplay} ${entry.line1} ${entry.line2} ${entry.line3} ${entry.postcode} ${entry.city}`}>
			<div aria-hidden={true}>
				<div>{`${entry.addressTypeDisplay}`}</div>
				<div>{`${entry.line1}`}</div>
				{entry.line2 ? <div>{`${entry.line2}`}</div> : ''}
				{entry.line3 ? <div>{`${entry.line3}`}</div> : ''}
				<div>{`${entry.postcode} ${entry.city}`}</div>
			</div>
		</div>
	}

	return <div className='grid'>{!formik ? <div></div> :
		<div className='col'>
			<div className="formgrid grid mr-3">
				<DropdownChangesChecked
					id='contactType'
					label='fields.contactType'
					formik={formik}
					optionLabel='display'
					optionValue='alias'
					className='bg-white w-full'
					classnameDiv='mr-0'
					NAMESPACE={NAMESPACE}
					options={getSortedList(configurationsList[CONTACTPERSON_SERVICECONFIG.contactTypeListname], 'display', false)}
				/>
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='lastname' label='fields.lastname' NAMESPACE={NAMESPACE} type='text' />
				<InputField formik={formik} id='firstname' label='fields.firstname' NAMESPACE={NAMESPACE} type='text' />
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='phone' label='fields.phone' NAMESPACE={NAMESPACE} type='text' />
				<InputField formik={formik} id='fax' label='fields.fax' NAMESPACE={NAMESPACE} type='text' />
			</div>
			<div className="formgrid grid  mr-3">
				<InputField formik={formik} id='mobile' label='fields.mobile' NAMESPACE={NAMESPACE} type='text' className='w-full' />
				{!addressList ? '' : <DropdownChangesChecked
					options={getAddressList()}
					formik={formik}
					id='addressId'
					optionValue='id'
					optionLabel='listentry'
					itemTemplate={addressListTemplate}
					label='fields.company-address'
					NAMESPACE={NAMESPACE}
					classnameDiv='m-0'
				/>}
			</div>
			<div className="formgrid grid mr-3">
				<InputField formik={formik} id='mail' label='fields.mail' NAMESPACE={NAMESPACE} type='text' className='w-full' />
				<DropdownChangesChecked
					options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]}
					formik={formik}
					id='advertisingBan'
					optionValue='value'
					optionLabel='label'
					label='fields.advertisingBan'
					classnameDiv='m-0'
					NAMESPACE={NAMESPACE}
				/>
			</div>
			<div className="formgrid grid  mr-3">
				<InputField formik={formik} id='jobDescription' label='fields.jobDescription' NAMESPACE={NAMESPACE} type='text' className='w-full' />
			</div>
		</div>
	}</div>

}

export const emptyContactPerson = { id: null, active: 1, versionCompanyContactPerson: 1, version: 1, origin: 'EAA', contactType: '', lastname: '', firstname: '', phone: '', mail: '', advertisingBan: 0, fax: '', mobile: '', jobDescription: '', addressId: '' }
export const validateContactPerson = (data) => {
	let errors = {};

	if (!data.lastname) {
		errors.lastname = getTranslation(NAMESPACE, 'lastname.error.label');
	}
	if (!data.contactType) {
		errors.contactType = getTranslation(NAMESPACE, 'contactType.error.label');
	}
	return errors;
}