import { useEffect, useState } from "react";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked"
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const NAMESPACE = ENUM_NAMESPACES.ASSOCIATEDCONTACTS
export const AssociatedContactForm = ({ formik }) => {
	const [regionsSelection, setRegionsSelection] = useState([]);
	const [editmode, setEditmode] = useState(true);
	const configurationsList = useConfigurationsList();
	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();

	const createRegionSelection = () => {
		const regionSelection = regionsSelectionList ? [...regionsSelectionList] : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		setRegionsSelection(regionSelection)
	}
	useEffect(() => {
		createRegionSelection();
	}, [regionsSelectionList])

	return (<>
		<div className="formgrid grid ">
			<InputChangesChecked autoFocus={true} formik={formik} id='name' NAMESPACE={NAMESPACE} translationKey='fields.name' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={2} formik={formik} id='phone' NAMESPACE={NAMESPACE} translationKey='fields.phone' type='text' />
			<InputChangesChecked tabIndex={3} formik={formik} id='mobile' NAMESPACE={NAMESPACE} translationKey='fields.mobile' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={4} formik={formik} id='fax' NAMESPACE={NAMESPACE} translationKey='fields.fax' type='text' />
			<InputChangesChecked tabIndex={5} formik={formik} id='email' NAMESPACE={NAMESPACE} translationKey='fields.email' type='text' />
		</div>
		<div className="formgrid grid">

			<DropdownChangesChecked
				showClear={true}
				className='mr-3'
				tabIndex={0}
				NAMESPACE={NAMESPACE}
				translationKey='fields.region'
				id='regionId'
				itemLabel={'name'}
				optionValue='id'
				formik={formik}
				options={regionsSelection} />
			<DropdownChangesChecked
				tabIndex={6}
				className='mr-3'
				NAMESPACE={NAMESPACE}
				translationKey='fields.status'
				id='active'
				itemLabel={'display'}
				optionValue='alias'
				formik={formik}
				options={configurationsList.StatusActiveList} />
		</div>
		<div className="formgrid grid ">
			<InputChangesChecked tabIndex={14} formik={formik} id='description' NAMESPACE={NAMESPACE} translationKey='fields.description' type='text' />
		</div>
	</>
	)
}
export const getEmptyAssociatedContact = () => { return { id: null, active: 1, version: 1, name: '', description: '', email: '', mobile: '', phone: '', origin: 'EAA', regionId: null } }
export const validateAssociatedContact = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error.label');
	}
	return errors;
}
