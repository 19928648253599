import { Card } from "primereact/card";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { patchData } from "../feathers";
import { ToastError } from "../Utils/ToastError";
import { ENUM_ICONS } from "../Components/Icons";
import { useState, useEffect } from "react";
import { REMINDERSERVICECONFIG, getEmptyReminder } from "./RemindersManager";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useObjectRemindersList, useRemindersList } from "../Persistence/RemindersContext";
import { ReminderCard } from "./ReminderCard";
import { ReminderDialog } from "./ReminderDialog";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

export const sortReminders = (reminders) => {
	reminders.sort((b, a) => {
		if (a.date < b.date) {
			return 1;
		}
		if (a.date > b.date) {
			return -1;
		}
		return 0;
	})
	return reminders
}

const NAMESPACE = ENUM_NAMESPACES.REMINDERS
export const RemindersSheet = ({ servicename, id }) => {
	const currentUser = useCurrentUser();
	const remindersList = useRemindersList();
	const getRemindersOjcetList = useObjectRemindersList();
	const [reminder, setReminder] = useState();
	const [dataList, setDataList] = useState([]);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialogReminderDone, setDisplayConfirmDialogReminderDone] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		getRemindersOjcetList(servicename, id).then((list) => {
			setDataList(sortReminders(list));
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		});
	}, [remindersList])

	const onReminderEdit = (reminder) => {
		if (reminder) {
			setReminder(reminder)
		} else {
			setReminder(getEmptyReminder(servicename, id, currentUser.id))
		}
		setDisplayReminderDialog(true)
	}

	const handleSetReminderDone = async (reminder) => {
		setReminder(reminder);
		setDisplayConfirmDialogReminderDone(true);
	}
	const onReminderDone = async () => {
		reminder.reminded = '1'
		await patchData('reminders', reminder)
	}

	const renderHeader = () => {
		return <div className={'flex flex-column'}>
			<div className='flex font-bold mb-2'>Wiedervorlagen</div>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Wiedervorlage</div>
				<div className='flex '>
					<div className={'flex w-1rem open-icon'} onClick={() => { onReminderEdit(null) }}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderReminderCard = (reminder) => {
		return !reminder ? '' : <ReminderCard key={'remindercard_' + reminder.id} reminder={reminder} onDoneHandler={handleSetReminderDone} onEditHandler={onReminderEdit} />
	}

	const renderReminders = () => {
		return dataList.length === 0 ? 'keine' : <div className='flex flex-column gap-1 w-full'> {dataList.map((r) => { return renderReminderCard(r) })}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-close'
				labelObject={reminder}
				handleOnClick={onReminderDone}
				displayConfirmDialog={displayConfirmDialogReminderDone}
				setDisplayConfirmDialog={setDisplayConfirmDialogReminderDone} />

			{!reminder ? '' :
				<ReminderDialog
					displayDialog={displayReminderDialog}
					setDisplayDialog={setDisplayReminderDialog}
					header={REMINDERSERVICECONFIG[servicename].header}
					name={reminder.name}
					serviceId={id}
					serviceName={servicename}
					userId={currentUser.id}
					reminder={reminder} />
			}
			<Card className="relatedusersheet card-sheet" header={renderHeader}>
				{renderReminders()}
			</Card>
		</>
	)

}