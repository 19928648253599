import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../Components/ConfirmDialog';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { FieldInfo } from "../../Components/FieldInfo";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../../Components/Icons';
import { BasicSheetTitleLayout, getScreenreaderMessage } from '../../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../../feathers';
import { useBreadcrumbsMinus, useBreadcrumbsUpdate } from '../../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { ToastError } from '../../Utils/ToastError';
import { BasicSheetLayout } from '../../Components/Layouts/BasicSheetLayout';
import { RelatedUsersSheet, sortRelatedUser } from '../../RelatedUsers/RelatedUsersSheet';
import { ENUM_SERVICES } from '../../Enums/ENUM_SERVICES';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../../Persistence/ErrorContext';
import { ENUM_ROUTES, ROUTES } from '../../Navigation/Routes';
import { ReminderDialog } from '../../Reminders/ReminderDialog';
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from '../../AssociatedContacts/AssociatedContactSheet';
import { copyObject, getTranslation } from '../../snippets';
import { getFBiBCase, patchFBiBCase } from './FBiBCasesManager';
import { FBiBCaseForm, emptyFBiBCase, validateFBiBCase } from './FBiBCaseForm';
import { CompanySheet } from '../../Companies/CompanySheet';
import { Divider } from 'primereact/divider';
import { FBiBCasePersonSheet } from '../FBiBCasePersons/FBiBCasePersonSheet';
import { Badge } from 'primereact/badge';
import { FBiBCaseEventsSheet } from '../FBiBCaseEvents/FBiBCaseEventsSheet';
import { Tag } from 'primereact/tag';
import { SplitButton } from 'primereact/splitbutton';
import { TrainingCenterSheet } from '../TrainingCenters/TrainingCenterSheet';
import { patchCaseEvent } from '../FBiBCaseEvents/FBiBCaseEventsManager';
import { emptyFBiBCaseEvent } from '../FBiBCaseEvents/FBiBCaseEventsForm';
import { FBiBCaseRequestSheet } from '../FBiBCaseRequests/FBiBCaseRequestSheet';
import { RELATEDUSERSERVICECONFIG } from '../../RelatedUsers/RelatedUserManager';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';


const SERVICENAME = ENUM_SERVICES.FBIBCASES;
const CLASSNAME = 'FBiBCaseData'
const NAMESPACE = ENUM_NAMESPACES.FBIBCASES
export const FBiBCaseData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const configurationsList = useConfigurationsList();
	const { fbibCaseId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [fbibCase, setFbibCase] = useState();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();

	const fbibCaseUpdate = async () => {
		if (fbibCaseId) {
			return await getFBiBCase(fbibCaseId, isReader, setFbibCase, setReadonly, setToastParams, configurationsList).then((fbibCase) => {
				formik.setValues(fbibCase);
				return fbibCase
			})
		}
	}

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => {
				if (!await fbibCaseUpdate()) {
					errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
					navigate('/' + ENUM_ROUTES.ERROR)
				}
			}, 100);
		}
		setMounted(true)
	}, [errorUpdate, navigate, mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const onPatchFBiBCase = async () => {
		return await patchFBiBCase(formik.values, currentUser, isReader, setFbibCase, setReadonly, setToastParams, configurationsList)
	}

	const handlePatchFBiBCase = async (fbibCase) => {
		return await patchFBiBCase(fbibCase, currentUser, isReader, setFbibCase, setReadonly, setToastParams, configurationsList)
	}

	const createCaseEventEntry = async (eventType, description) => {
		const caseEvent = copyObject(emptyFBiBCaseEvent)
		caseEvent.eventType = eventType;
		caseEvent.description = description;
		caseEvent.eventDate = new Date();
		caseEvent.eventTime = new Date();
		caseEvent.fbibCaseId = fbibCase.id
		await patchCaseEvent(caseEvent, fbibCase, null, fbibCaseUpdate)

	}

	const onUpdateCasePerson = async (casePerson) => {
		const casePersonId = casePerson ? casePerson.id : null
		if (casePersonId !== fbibCase.fbibCasePersonId) {
			const oldCPersonName = fbibCase.fbibCasePersonId ? fbibCase.casePerson.personId : ''
			const eventDescription = casePerson ? `Neue Fallperson gesetzt ${casePerson.personId}` : `Fallperson ${oldCPersonName} aus FBiB Fall entfernt.`;
			fbibCase.fbibCasePersonId = casePersonId;
			delete fbibCase.casePerson
			await handlePatchFBiBCase(fbibCase).then(async () => {
				await createCaseEventEntry('PERSONCHANGED', eventDescription)
			})

		} else {
			await fbibCaseUpdate()
		}
	}

	const onSelectCompany = async (company) => {
		const companyId = company ? company.id || company : null
		if (companyId !== fbibCase.companyId) {
			const oldCompanyName = fbibCase.companyId ? 'UN' + fbibCase.companyId.toString().padStart(5, "0") + ' ' + fbibCase.company.name : ''
			const eventDescription = company ? `Neues Unternehmen gesetzt ${company.displayId} ${company.name}` : `Unternehmen ${oldCompanyName} aus FBiB Fall entfernt.`;
			fbibCase.companyId = companyId;
			delete fbibCase.company
			await handlePatchFBiBCase(fbibCase).then(async () => {
				await createCaseEventEntry('COMPANYCHANGED', eventDescription).then(() => {
					updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: ROUTES.FBIB_CASES.label, route: ENUM_ROUTES.FBIB_CASES + '/' + fbibCase.id }] })
				})
			})
		} else {
			await fbibCaseUpdate()
		}
	}

	const onEditFBiBCase = () => {
		formik.setValues(fbibCase)
		setDisplayEditDialog(true)
	}

	const onSelectTrainingCenter = async (trainingCenterId) => {
		const oldTrainingCenter = fbibCase.trainingCenterId ? 'AS' + fbibCase.trainingCenterId.toString().padStart(5, "0") + ' ' + fbibCase.trainingCenter.name : ''
		await handlePatchFBiBCase({ ...formik.values, trainingCenterId }).then(async (fbibCase) => {
			const eventDescription = fbibCase.trainingCenter ? `Neue Ausbildungsstätte gesetzt ${'AS' + fbibCase.trainingCenterId.toString().padStart(5, "0")} ${fbibCase.trainingCenter.name}` : `Ausbildungsstätte ${oldTrainingCenter} aus FBiB Fall entfernt.`;
			await createCaseEventEntry('TRAININGCENTERCHANGED', eventDescription)
		});
	}

	const deleteFBiBCase = async () => {
		setDisplayDeleteConfirmDialog(false);

		await deleteData(SERVICENAME, fbibCase.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: 'FBiB Fall konnte nicht gelöscht werden', message: error.message })
		})
	}

	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditFBiBCase, disabled: readonly }]
		if (currentUser.permissions === 'ADMIN' && fbibCase) {
			items.push({ label: 'FBiB Fall unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}

	const formik = useFormik({
		initialValues: copyObject(emptyFBiBCase),
		validate: validateFBiBCase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: onEditFBiBCase, disabled: readonly },
		{ key: 'reminder', label: 'Wiedervorlage', icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		const maxChar = 200;
		const chamber = fbibCase.trainingCourse ? configurationsList.ChamberList.find((e) => e.id === fbibCase.trainingCourse.chamberId) : '';
		const title = fbibCase ? (fbibCase.company ? fbibCase.company.name : '') + fbibCase.name : '';
		const status = configurationsList.FBiBCaseStatusList.find((e) => e.alias === fbibCase.status);
		const items = configurationsList.FBiBCaseStatusList.map((caseStatus) => {
			return { label: caseStatus.display, alias: caseStatus.alias, command: async (e) => await handlePatchFBiBCase({ ...fbibCase, status: caseStatus.alias }) }
		}).filter((e) => fbibCase.status.alias !== e.alias)
		return <div className="eaa-maincontent flex flex-column " >
			<BasicSheetTitleLayout
				screenReaderMessage={getScreenreaderMessage('FBiB Fall', fbibCase, NAMESPACE)}
				listButtons={listButtons}
				title={fbibCase ? `${(title.length > maxChar ? fbibCase.name.substring(0, maxChar - (fbibCase.company ? fbibCase.company.name.length : 0)) + '...' : fbibCase.name)}` : ''}
				subtitle={<div id='spitbutton-eaacase' className="flex align-items-center gap-2"><div className='flex'>{fbibCase ? `${fbibCase.displayId} erstellt am ${formatDate(parseISO(fbibCase.createdAt))} ${(fbibCase.creator ? (' von ' + fbibCase.creator.displayname) : '')}` : ''}</div>
					{isReader ? <Tag className={'mr-2 text-white px-3 color-tag-' + status.color} rounded value={status.display} /> :
						<SplitButton disabled={isReader} buttonClassName="text-sm text-white py-0 px-2" className={'mr-2  text-white p-button-text p-button-rounded p-0 color-tag-' + status.color} label={status.display} model={items}></SplitButton>}
				</div>} />

			<div className='flex flex-column my-4' >
				<div className='flex flex-wrap justify-content-between grid'>
					<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.date' content={formatDate(fbibCase.date)} className='col-3' />
					<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.responsible' content={fbibCase.responsible ? fbibCase.responsible.displayname : 'n.n.'} className='col-3' />
				</div>
				<div className='flex flex-wrap justify-content-between  grid'>
					<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.companyname' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.COMPANY} size={ENUM_AWESOMEICON_SIZE.X1} />} content={!fbibCase.company ? '' : fbibCase.company.name} className={!fbibCase.company ? 'hidden' : 'col-4'} />
					<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.fbibcaseperson' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.USER} size={ENUM_AWESOMEICON_SIZE.X1} />} content={!fbibCase.casePerson ? '' : fbibCase.casePerson.personId} className={!fbibCase.casePerson ? 'hidden' : 'col-4'} />
				</div>
				<div className='flex flex-wrap justify-content-between  grid'>
					<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.trainingcenter' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCENTERS} size={ENUM_AWESOMEICON_SIZE.X1} />} content={!fbibCase.trainingCenter ? '' : fbibCase.trainingCenter.name} className={!fbibCase.trainingCenter ? 'hidden' : 'col-4'} />
					{!fbibCase.trainingCourse ? '' : <FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.trainingcourse' content={fbibCase.trainingCourse.name + ' | ' + chamber.display} icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCOURSES} size={ENUM_AWESOMEICON_SIZE.X1} />} className='col-4' />}
				</div>
				<Divider />

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-1'>
						{getTranslation(NAMESPACE, 'fields.description.label')}
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: fbibCase.description }} />
				</div>

				<div className='flex text-1xl m-2'>
					{fbibCase ? <FBiBCaseEventsSheet fbibCase={fbibCase} caseEvents={fbibCase.caseEvents} fbibCaseUpdate={fbibCaseUpdate} /> : ''}
				</div>
			</div>
		</div>
	}

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				labelObject={{ name: fbibCase.name }}
				handleOnClick={() => deleteFBiBCase(fbibCase, minusBreadcrumbs, setToastParams)}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog}
			/>
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='FBiB Fall'
				name={fbibCase.name}
				serviceId={fbibCase.id}
				serviceName={SERVICENAME}
				userId={currentUser.id}
			/>
			<EditDialog
				form={<FBiBCaseForm formik={formik} key='fbibcase-form' />}
				formik={formik}
				patchForm={onPatchFBiBCase}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayEditDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const amountCaseRequests = !fbibCase ? 0 : fbibCase.caseRequests.length
		const colorBadgeCaseRequests = amountCaseRequests === 0 ? 'danger' : (fbibCase.caseRequests.find(e => (e.status.alias || e.status) === 'INPROGRESS') ? 'warning' : 'success')
		const sideSheets = !fbibCase ? [] : [
			{ index: 0, visible: true, icon: ENUM_ICONS.USER + "fa-lg", component: <FBiBCasePersonSheet readonly={readonly} fbibCase={fbibCase} onCasePersonChanged={onUpdateCasePerson} /> },
			{
				index: 1, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly}
					company={fbibCase.company}
					onSelectCompany={onSelectCompany}
					onUpdateCompany={fbibCaseUpdate}
					showChangeCompany={true}
					fbibCaseId={fbibCase.id}
					fbibCaseMode={true} />
			},
			{
				index: 2, visible: true, awesomeIcon: ENUM_AWESOMEICON.TRAININGCENTERS,
				component: <div className="flex flex-column row-gap-1">
					<TrainingCenterSheet
						onSelectTrainingCenter={onSelectTrainingCenter}
						trainingCenter={fbibCase.trainingCenter}
						readonly={readonly}
						key='fbibtrainingcenter-sheet'
						trainingCourseSelected={fbibCase.trainingCourse}
						updateParentSheet={fbibCaseUpdate} />
				</div>
			},
			{
				index: 3, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet readonly={readonly} key='relatedUserSheet' parentId={fbibCase.id} relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.FBIBCASE} hideDivider={true} />
					<AssociatedContactSheet readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.FBIBCASE} parent={fbibCase} />
				</div>
			},
			{
				index: 4,
				visible: true,
				awesomeIcon: ENUM_AWESOMEICON.CASEREQUESTS,
				component: <FBiBCaseRequestSheet fbibCase={fbibCase} readonly={readonly} updateFBiBCase={fbibCaseUpdate} />,
				badge: <Badge value={amountCaseRequests} severity={colorBadgeCaseRequests} style={{ right: '15px', }} className="z-2 relative flex" />
			},
		]
		return sideSheets;
	}

	return (!fbibCase ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!fbibCase ? '' : renderContent()}{!fbibCase ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={fbibCase.id}
		/>
	)
}