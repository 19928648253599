import { useEffect, useState } from "react";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { InputText } from 'primereact/inputtext';
import { useCompaniesInit, useCompaniesList } from "../Persistence/CompaniesContext";
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { ENUM_ICONS } from '../Components/Icons';
import { logDefault } from '../Utils/logger';
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import client, { findData } from "../feathers";

export const emptyJobOpening = {
	id: null, version: 1, active: 1, description: '', explanation: '', jobType: null, openingDate: null, mobilityRequired: 0, businessTravelRequired: 0, status: null, location: '', excludedDisabilities: '', mobilityType: '', salary: '', workingHours: '', companyId: null, eaaCaseId: null, showCompanyTrash: false
}

const CLASSNAME = 'JobOpeningForm'
export const JobOpeningForm = ({ formik }) => {

	const configurationsList = useConfigurationsList();
	const useCompanyList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const [companiesList, setCompanyList] = useState([])
	const [eaaCaseList, setEaaCaseList] = useState();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		companiesInit();
		setMounted(true)
	}, [])

	useEffect(() => {
		if (useCompanyList) {
			setCompanyList(useCompanyList.filter((entry) => entry.active))
		}
		if (formik.values.companyId) {
			const query = { where: { companyId: formik.values.companyId } }
			findData(ENUM_SERVICES.EAACASES, query).then((list) => {
				const eaaCaseId = formik.values.eaaCaseId;
				setEaaCaseList(list.filter(entry => (entry.jobOpening === undefined || entry.jobOpening.eaaCaseId === eaaCaseId)));
			})
		}
	}, [useCompanyList, formik.values])

	const handleCompanySelect = async (e) => {
		const value = e ? e.value : null;
		if (value === null) {
			formik.setFieldValue('eaaCase', null)
		} else {
			const query = { where: { companyId: value.id } }
			await findData(ENUM_SERVICES.EAACASES, query).then((list) => {
				const eaaCaseId = formik.values.eaaCaseId;
				setEaaCaseList(list.filter(entry => (entry.jobOpening === undefined || entry.jobOpening.eaaCaseId === eaaCaseId)));
			})
		}
		formik.setFieldValue('company', value);
	}

	return (
		<div>{!formik || !formik.values ? <div></div> :
			< div className='col' >

				<div className="formgrid grid">
					<InputChangesChecked id='description' type="text" formik={formik} label='Stellenbeschreibung' disabled={false} />
				</div>
				<div className="formgrid grid">
					{!mounted ? '' : <EditorChangesChecked id='explanation' value={formik.values.explanation} setValue={(value) => formik.setFieldValue('explanation', value)} label='Erläuterungen' disabled={false} />}
				</div>
				<div className="formgrid grid">
					{formik.values.companyId ? <div className="field col mr-3">
						<label htmlFor='input-companyName' className='block'>Unternehmen</label>
						<div className='flex'>
							<InputText key='input-companyName' id='input-companyName' disabled={true} value={formik.values.company.name} className={'w-full'} />
							<i className={!formik.values.showCompanyTrash ? 'hidden' : ENUM_ICONS.TRASH + 'w-1rem ml-1 pt-1 text-sm'} onClick={() => handleCompanySelect(null)} />
						</div>
					</div> :
						<AutoCompleteChangesChecked
							dropdown={true}
							label={'Unternehmen'}
							id={'company'}
							itemLabel={'name'}
							formik={formik}
							forceSelection={true}
							suggestionsList={companiesList}
							handleChange={handleCompanySelect} />}
					{!formik.values.eaaCase ?
						<DropdownChangesChecked
							label='Fall'
							id='eaaCase'
							filter
							itemLabel='name'
							formik={formik}
							options={eaaCaseList}
							className='mr-3 '
						/>
						:
						<div className="field col mr-3">
							<label htmlFor='input-eaacase' className='block'>Fall</label>
							<div className='flex'>
								<InputText key='input-eaacase' id='input-eaacase' disabled={true} value={formik.values.eaaCase.name} className='flex-1' />
								<i className={ENUM_ICONS.TRASH + 'w-1rem ml-1 pt-1 text-sm'} onClick={() => formik.setFieldValue('eaaCase', null)} />
							</div>
						</div>
					}
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						label='Stellenart'
						id='jobType'
						itemLabel='display'
						optionValue='alias'
						className='mr-3 '
						formik={formik}
						options={configurationsList.JobOpeningTypeList}
					/>
					<CalendarChangesChecked className='mr-3' id='openingDate' formik={formik} label='Startdatum' />
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						label='Status'
						id='status'
						itemLabel='display'
						optionValue='alias'
						className='mr-3 '
						formik={formik}
						options={configurationsList.JobOpeningStatusList} />
					<div className='field col mr-3' />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						id='location'
						type='text'
						formik={formik}
						label='Adresse'
					/>
				</div>
				<div className="formgrid grid">
					<InputChangesChecked
						id='workingHours'
						label='Arbeitsstunden'
						type="text"
						formik={formik}
					/>
					<InputChangesChecked
						id='salary'
						label='Lohn / Gehalt'
						type="text"
						formik={formik}
					/>
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						className={'mr-3'}
						label='Mobilität erforderlich' id={'mobilityRequired'}
						itemLabel='label'
						formik={formik}
						options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]}
					/>
					<InputChangesChecked
						className={'mr-3'}
						id='mobilityType'
						label='Mobilitätstyp'
						type="text"
						formik={formik}
						disabled={false} />
				</div>
				<div className="formgrid grid">
					<DropdownChangesChecked
						className={'mr-3'}
						label='Geschäftsreise erforderlich'
						id={'businessTravelRequired'}
						itemLabel='label'
						formik={formik}
						options={[{ label: 'Ja', value: 1 }, { label: 'Nein', value: 0 }]}
					/>
					<InputChangesChecked
						className={'mr-3'}
						id='excludedDisabilities'
						label='Ausgeschlossene Behinderung'
						type="text"
						formik={formik}
						disabled={false} />
				</div>
			</div >
		}</div >
	)
}

export const validateJobOpening = (data) => {
	let errors = {};

	if (!data.description) {
		errors.description = 'Beschreibung ist ein Pflichtfeld';
	}
	if (!data.jobType) {
		errors.jobType = 'Typ ist ein Pflichtfeld';
	}
	if (!data.openingDate) {
		errors.openingDate = 'Datum ist ein Pflichtfeld';
	}
	if (!data.status) {
		errors.status = 'Status ist ein Pflichtfeld';
	}
	if (!data.location) {
		errors.location = 'Ort ist ein Pflichtfeld';
	}
	if (!data.company) {
		errors.company = 'Unternehmen ist ein Pflichtfeld';
	}
	return errors;
}