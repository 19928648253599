import { useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const NAMESPACE = ENUM_NAMESPACES.CASEEVENTS
export const CaseEventDivider = ({ caseEvents, onClickHandler, readonly }) => {
	const isReader = useCurrentUserIsReaderEAA();

	const [disableItems, setDisableItems] = useState();

	useEffect(() => {
		const caseEvent = caseEvents && caseEvents.length > 0 ? caseEvents.find(e => e.eventType.alias === 'CONTACT') : null;
		setDisableItems(!(caseEvent && caseEvent.id))

	}, [caseEvents])

	return (<div className='flex flex-column'>
		<div className={isReader ? 'hidden' : 'flex flew-row w-full justify-content-between my-4'} id='createbuttons-caseeventsheet'>
			<div className='flex flex-auto '>
				<Button disabled={isReader || readonly}
					className='accordion-tab-event'
					label={getTranslation(NAMESPACE, 'button-new-contact.label')}
					aria-label={getTranslation(NAMESPACE, 'button-new-contact.ariaLabel')}
					onClick={() => onClickHandler('CONTACT')}
				/>
				<Button disabled={isReader || readonly || disableItems}
					className='accordion-tab-event'
					label={getTranslation(NAMESPACE, 'button-new-result.label')}
					aria-label={getTranslation(NAMESPACE, 'button-new-result.ariaLabel')}
					onClick={() => onClickHandler('CONTACTRESULT')} />
				<Button disabled={isReader || readonly || disableItems}
					className='accordion-tab-event'
					label={getTranslation(NAMESPACE, 'button-new-sustainability.label')}
					aria-label={getTranslation(NAMESPACE, 'button-new-sustainability.ariaLabel')}
					onClick={() => onClickHandler('SUSTAINABILITY')} />
			</div>
			<div className='justify-content-end'>
				<Button disabled={isReader || readonly}
					className='w-12rem accordion-tab-intern'
					label={getTranslation(NAMESPACE, 'button-new-intern.label')}
					aria-label={getTranslation(NAMESPACE, 'button-new-intern.ariaLabel')}
					onClick={() => onClickHandler('INTERN')} />
				<Button disabled={isReader || readonly}
					className='w-12rem accordion-tab-transfer'
					label={getTranslation(NAMESPACE, 'button-new-transfer.label')}
					aria-label={getTranslation(NAMESPACE, 'button-new-transfer.ariaLabel')}
					onClick={() => onClickHandler('CASETRANSFER')} />
			</div>
		</div>
	</div>
	)
}