import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils"
import { useEffect, useState } from "react";
import { useFBiBMode } from "../Persistence/ConfigurationsContext";
import { getTranslation } from "../snippets";

export const DropdownChangesChecked = ({ id, disabled, formik, className, optionValue, itemLabel, itemTemplate, valueTemplate, options, placeholder, filter, filterBy, onChange, handleChange, showClear, tabIndex, idError, emptyFilterMessage, filterTemplate, classNameDropdown, virtualScrollerOptions, editable, rowStyle, colLabels, multiple, NAMESPACE, translationKey }) => {
	const fbibMode = useFBiBMode();
	const [screenReaderMessage, setScreenReaderMessage] = useState('')
	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block mt-1">{formik.errors[id].label}</small>;
	};

	const handleFilter = (event) => {
		const filteredOptions = options.filter(option => {
			const optionFilter = (filterBy || '').split(',').map(f => option[f.trim()]).join(' ')
			return optionFilter.toLowerCase().includes(event.filter.toLowerCase())
		}
		);
		setScreenReaderMessage(`Filter findet ${filteredOptions.length} Einträge`);
	};

	const handleOnChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		if (handleChange) {
			handleChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	const defaultTemplate = (option) => {
		return (
			<div role="option" aria-label={option.ariaLabel || option.label}>
				{itemLabel ? option[itemLabel] : option.label}
			</div>
		);
	};

	return (
		<div className={rowStyle ? 'flex w-full' : 'field col ' + className}>
			<div aria-live="polite" className="screen-reader-only">{screenReaderMessage}</div>
			<label htmlFor={id} className={rowStyle ? (colLabels || 'col-3') : 'block'}>{getTranslation(NAMESPACE, `${translationKey || id}.label`)}</label>
			<div className={rowStyle ? 'col' : ''}>
				<Dropdown id={id}
					key={'dropdown-' + id}
					editable={editable}
					tabIndex={tabIndex}
					disabled={disabled}
					type='text'
					filter={filter}
					filterBy={filterBy}
					onFilter={handleFilter}
					emptyMessage={getTranslation(NAMESPACE, `${translationKey || id}.emptyMessage.label`)}
					emptyFilterMessage={getTranslation(NAMESPACE, `${translationKey || id}.emptyFilterMessage.label`)}
					filterTemplate={filterTemplate}
					itemTemplate={itemTemplate || defaultTemplate}
					valueTemplate={valueTemplate}
					multiple={multiple}
					optionLabel={itemLabel}
					optionValue={optionValue}
					value={formik.values[id]}
					options={options}
					onChange={handleOnChange}
					placeholder={getTranslation(NAMESPACE, `${translationKey || id}.placeholder.label`)}
					aria-placeholder={getTranslation(NAMESPACE, `${translationKey || id}.placeholder.ariaLabel`)}
					autoComplete="off"
					virtualScrollerOptions={virtualScrollerOptions}
					onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }}
					className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + classNameDropdown}
					panelClassName={fbibMode ? 'fbib ' : ''}
					showClear={showClear} />
				{getFormErrorMessage(idError ? idError : id)}
			</div>
		</div>
	)

}

