import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { Address } from '../Components/FormElements/Adddress';
import { useTranslation } from 'react-i18next';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const NAMESPACE = ENUM_NAMESPACES.ADDRESSES
const CLASSNAME = 'AddressesForm'
export const AddressesForm = ({ formik, readonly, validateForm }) => {
	const configurationsList = useConfigurationsList();
	return (
		!formik ? '' :
			<div className=''>
				<div className="formgrid mr-3 mb-1">
					<DropdownChangesChecked
						id={'addressType'}
						translationKey='fields.addressType'
						formik={formik}
						optionValue='alias'
						itemLabel={'display'}
						handleChange={() => { }}
						className={'m-0'}
						NAMESPACE={NAMESPACE}
						options={configurationsList.AddressTypeList} />
				</div>

				<Address readonly={readonly} formik={formik} validateForm={validateForm} className='field col mr-3' />
			</div>
	)

}

export const emptyAddress = { id: null, version: 1, line1: '', line2: '', line3: '', city: '', postcode: '', country: 'Deutschland', addressType: 'HEADQUARTER', postcodeAndCity: '' }
export const validateAddress = (data) => {
	let errors = {};
	if (!data.line1) {
		errors.line1 = getTranslation(NAMESPACE, 'fields.line1.error.label');
	}
	if (!data.postcode) {
		errors.postcode = getTranslation(NAMESPACE, 'fields.postcode.error.label');
	}
	if (data.country === 'Deutschland' && ('' + data.postcodeAndCity) === '') {
		errors.postcodeAndCity = getTranslation(NAMESPACE, 'fields.postcodeAndCity.error.label');
	}
	if (!data.city) {
		errors.city = getTranslation(NAMESPACE, 'fields.city.error.label');
	}
	if (!data.country) {
		errors.country = 'Land ist ein Pflichtfeld';
	}
	if (!data.addressType) {
		errors.addressType = 'Adresstyp  ist ein Pflichtfeld';
	}
	return errors;
}
