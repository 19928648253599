import { useEffect, useState } from 'react';
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { Multiselect } from '../Components/FormElements/Multiselect';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useOrganizationsSelectionList } from "../Persistence/OrganizationsContext";
import { useRegionsList, useRegionsSelectionList } from '../Persistence/RegionsContext';
import { useTaskareasList, useTaskareasSelectionList } from '../Persistence/TaskareasContext';
import { logDefault } from '../Utils/logger';
import { InputText } from 'primereact/inputtext';
import { startOfToday } from 'date-fns';
import { Button } from 'primereact/button';
import { ENUM_ICONS } from '../Components/Icons';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { getUserFTECurrentValue, UserFormFTE } from './UserFormFTE';
import { InputNumber } from 'primereact/inputnumber';
import { copyObject, getSortedList } from '../snippets';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'UserForm'
const NAMESPACE = ENUM_NAMESPACES.FTE
export const emptyUser = {
	id: null,
	email: '', username: '', password: '', displayname: '', permissions: '', roles: '', isAdmin: 0, loginAllowed: 1, fte: 1, regions: [], taskareas: [], addToRecipientList: 0, userFTEs: [{ date: startOfToday(), fte: 1.00 }], dateLastFTE: startOfToday(), lastFTE: 1.00, editmode: false, ftemode: false,
	active: 1, version: 1, forceChangePassword: 1, lastname: '', firstname: '', mobilenumber: '', phonenumber: '', phonenumberbusiness: '', organization: '', organizationId: undefined, additionalOrgIds: []
}

export const UserForm = ({ formik, profileMode }) => {
	const formatDate = useFormatDate();
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const regionsListSelection = useRegionsSelectionList();
	const regionsListAll = useRegionsList();
	const taskareaListAll = useTaskareasList();
	const taskareaListSelection = useTaskareasSelectionList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const [eaamoduleFBiB, setEaamoduleFBiB] = useState(false);
	const [regionOptions, setRegionOptions] = useState(regionsListSelection)
	const [taskareaOptions, setTaskareaOptions] = useState(taskareaListAll)
	const [displayFTEDialog, setDisplayFTEDialog] = useState(false)
	const [userFTEs, setUserFTEs] = useState([])

	useEffect(() => {
		setEaamoduleFBiB(configurationsList.eaaModules.FBIB === '1')
	}, [configurationsList])

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values', formik.values)
	})

	useEffect(() => {
		if (regionsListAll && regionsListSelection) {
			const regionOptionsList = JSON.parse(JSON.stringify(regionsListSelection));
			logDefault(CLASSNAME, 'info', 'regionOptionsList 1:' + regionOptionsList.length);
			formik.values.regions.forEach(entry => {
				const listEntry = regionsListAll.find(e => e.id === entry.id);
				if (!regionOptionsList.find(e => e.id === entry.id)) {
					logDefault(CLASSNAME, 'info', 'add entry to regionOptionsList:' + entry.id);
					regionOptionsList.push(listEntry);
				}
			})
			setRegionOptions(regionOptionsList)
			logDefault(CLASSNAME, 'info', 'regionOptionsList 2:' + regionOptionsList.length);
		}
	}, [regionsListSelection, regionsListAll])

	const onChangeOrganization = (e) => {
		if (e.value && e.value.id) {
			formik.setFieldValue('organizationId', e.value)
		}
	}

	const onCancelFTEDialog = () => {
		formik.setFieldValue('ftemode', false);
		formik.setFieldValue('editmode', false)
		getUserFTECurrentValue(formik, userFTEs)
	}

	useEffect(() => {
		if (taskareaListAll && taskareaListSelection) {
			const optionsList = JSON.parse(JSON.stringify(taskareaListSelection));
			logDefault(CLASSNAME, 'info', 'optionsList 1:' + optionsList.length);
			formik.values.taskareas.forEach(entry => {
				const listEntry = taskareaListAll.find(e => e.id === entry.id);
				if (!optionsList.find(e => e.id === entry.id)) {
					logDefault(CLASSNAME, 'info', 'add entry to optionsList:' + entry.id);
					optionsList.push(listEntry);
				}
			})
			setTaskareaOptions(optionsList)
			logDefault(CLASSNAME, 'info', 'optionsList 2:' + optionsList.length);
		}
	}, [taskareaListSelection, taskareaListAll])

	return (
		!formik ? '' : <>
			<EditDialog
				form={<UserFormFTE formik={formik} key='user-form' />}
				className='w-30rem'
				formik={formik}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				labelObject={{ ...formik?.values }}
				setDisplayDialog={setDisplayFTEDialog}
				displayDialog={displayFTEDialog}
				patchForm={() => {
					formik.setFieldValue('ftemode', false);
				}}
				onCancel={onCancelFTEDialog}

			/>
			<div className='col'>
				<div className="formgrid grid">
					<InputChangesChecked id='firstname' autoFocus={true} type="text" formik={formik} label='Vorname' setShowDialog={() => { }} />
					<InputChangesChecked id='lastname' tabIndex={1} type="text" formik={formik} label='Nachname' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='phonenumber' tabIndex={0} type="text" formik={formik} label='Telefon' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='mobilenumber' tabIndex={0} type="text" formik={formik} label='Mobil' setShowDialog={() => { }} />
					<div className="field col mr-3" />
				</div>
				{currentUser.permissions !== 'ADMIN' || profileMode ? '' :
					<>
						<div className="formgrid grid">
							<DropdownChangesChecked
								tabIndex={0}
								className='mr-3'
								label={'Träger'}
								id={'organizationId'}
								itemLabel={'name'}
								formik={formik}
								optionValue={'id'}
								options={organizationsSelectionList}
								setShowDialog={() => { }}
								filter={true}
							/>
						</div>
						<div className="formgrid grid">
							<InputChangesChecked
								id='username'
								className={'mr-0'}
								tabIndex={0}
								type="text"
								formik={formik}
								label='Anmeldename'
								setShowDialog={() => { }} />
							<div className="field col mr-3 flex">
								<DropdownChangesChecked
									tabIndex={0}
									className='pr-0'
									label={'Rolle'}
									id={'permissions'}
									itemLabel={'display'}
									formik={formik}
									options={configurationsList.RolesList}
									setShowDialog={() => { }}
									filter={false}
								/>
								{!eaamoduleFBiB ? '' : <DropdownChangesChecked
									tabIndex={0}
									className='ml-3 pr-0'
									label={'Rolle FBiB'}
									id={'permissionsFBiB'}
									itemLabel={'display'}
									formik={formik}
									options={configurationsList.RolesList.filter((e) => e.alias !== 'ADMIN')}
									setShowDialog={() => { }}
									filter={false}
								/>}
							</div>
						</div>
						<div className="formgrid grid">
							<InputChangesChecked tabIndex={7} id='email' label='E-Mail' type="text" formik={formik} setShowDialog={() => { }} />
						</div>
						<div className="formgrid grid">
							<DropdownChangesChecked
								tabIndex={8}
								className={'pr-3'}
								label='Nutzer*in kann sich anmelden'
								id={'loginAllowed'}
								itemLabel='display'
								formik={formik}
								options={[{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }]}
								setShowDialog={() => { }}
								filter={false}
								onChange={(value) => { if (!value) { formik.setFieldValue('fte', 0) } }}
							/>
							<div className="col mr-3">
								<div className="flex w-full" >
									<div className='flex formgrid grid'>
										<DropdownChangesChecked
											tabIndex={9}
											className=''
											label='In Verteilerliste'
											id={'addToRecipientList'}
											itemLabel='display'
											formik={formik}
											options={[{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }]}
											setShowDialog={() => { }} filter={false}
										/>
									</div>
									<div className='flex formgrid grid'>
										<div className={' field col ml-3'}>
											<label htmlFor='fte' className='block'>FTE</label>
											<div className="flex col-fixed w-12rem ml-0 p-0">
												<InputText readOnly={true}
													tabIndex={100}
													className='flex col-fixed w-8rem mr-2' style={{ padding: '7px 10px' }}
													value={'seit ' + formatDate(formik.values.dateLastFTE)} />
												<InputNumber
													className='flex col-fixed w-3rem'
													mode="decimal"
													minFractionDigits={2}
													maxFractionDigits={2}
													max={1}
													inputClassName='w-3rem'
													value={formik.values.lastFTE} />
												<Button className='flex col-fixed ml-1 w-2rem'
													icon={ENUM_ICONS.PENCIL}
													onClick={() => {
														formik.setFieldValue('ftemode', true);
														setUserFTEs(formik.values.userFTEs.map(e => { const { date } = e; return { ...copyObject(e), date } }));
														setDisplayFTEDialog(true)
													}} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{currentUser.visibilityMode !== 'ORGANIZATION' ? '' :
							<div className="formgrid grid">
								<Multiselect
									id='additionalOrgIds'
									options={organizationsSelectionList}
									filter={true}
									editmode={true}
									placeholder="Träger wählen"
									itemLabel="name"
									itemValue='id'
									showClear={false}
									label='Weitere sichtbare Träger'
									formik={formik}
									filterSelectedLabel='Träger'
									maxSelectedLabels={1}
								/>
								<DropdownChangesChecked
									tabIndex={9}
									classNameDropdown='py-1'
									className='mr-3'
									label='Trägerstatistik sichtbar'
									id={'roles'}
									itemLabel='display'
									optionValue='alias'
									formik={formik}
									options={[{ display: 'Ja', alias: 'statistic-org' }, { display: 'Nein', alias: '' }]}
									setShowDialog={() => { }}
									filter={false}
								/>

							</div>

						}
						{!configurationsList.eaaModules.REQUESTDISTRIBUTION ? '' : <div className="formgrid grid">
							<Multiselect id='regions' options={regionOptions} filter={true} editmode={true} placeholder="Regionen wählen" itemLabel="name" showClear={false} label='Regionen' formik={formik} display='chip' />
							<Multiselect id='taskareas' options={taskareaOptions} filter={true} editmode={true} placeholder="Aufgabenbereiche wählen" itemLabel="name" showClear={false} label='Aufgabenbereiche' formik={formik} display='chip' />
						</div>}

					</>}

				{formik.values.id ? '' :
					<div className="formgrid grid">
						<InputChangesChecked id='password' tabIndex={9} label='initiales Passwort' type="text" formik={formik} setShowDialog={() => { }} />
					</div>}
			</div >
		</>
	)
}

export const validateUser = (data) => {
	let errors = {};

	if (data.ftemode) {
		if (data.editmode) {
			errors.editmode = 'Bearbeitenmodus ist aktiviert';
		}
	} else {
		if (!data.email) {
			errors.email = 'E-Mail muss gesetzt und eindeutig sein';
		}
		if (!data.username) {
			errors.username = 'Anmeldenamen muss gesetzt und eindeutig sein';
		}
		if (!data.id && !data.password) {
			errors.password = 'Ein Passwort muss beim Anlegen gesetzt werden';
		}
		if (!data.isAdmin && !data.organizationId) {
			errors.organizationId = 'Träger muss ausgefüllt sein';
		}
		if (!data.permissions) {
			errors.permissions = 'Rolle muss ausgefüllt sein';
		}
	}
	return errors;
}
