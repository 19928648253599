import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CaseEventsSheet } from "../CaseEvents/CaseEventsSheet";
import { CasePersonSheet } from "../CasePersons/CasePersonSheet";
import { CompanyChangeDialog } from "../Companies/CompanyChangeDialog";
import { CompanySheet } from "../Companies/CompanySheet";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { deleteData } from "../feathers";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { EAACaseForm, emptyEAACase, validateEAACase } from "./EAACaseForm";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../Components/Icons";
import exportToPDF from "../Utils/exportToPDF";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useBreadcrumbsUpdate } from "../Persistence/Breadcrumbs";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { BasicSheetTitleLayout, getScreenreaderMessage } from "../Components/Layouts/BasicSheetTitleLayout";
import { logDefault } from "../Utils/logger";
import { ENUM_ROUTES, ROUTES } from "../Navigation/Routes";
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from "../Persistence/ErrorContext";
import { RelatedUsersSheet } from "../RelatedUsers/RelatedUsersSheet";
import { getEAACase, patchEAACase } from "./EAACaseManager";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { CaseRequestSheet } from "../CaseRequests/CaseRequestSheet";
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from "../AssociatedContacts/AssociatedContactSheet";
import { SplitButton } from 'primereact/splitbutton';
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { useFormik } from "formik";
import { copyObject, getTranslation } from "../snippets";
import { Badge } from "primereact/badge";
import { RELATEDUSERSERVICECONFIG } from '../RelatedUsers/RelatedUserManager';
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { parseISO } from "date-fns";

const NAMESPACE = ENUM_NAMESPACES.EAACASES
const CLASSNAME = 'EAACaseData';
const SERVICENAME = ENUM_SERVICES.EAACASES;
export const EAACaseData = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const { eaaCaseId } = useParams();
	const [eaaCase, setEaaCase] = useState();
	const [readonly, setReadonly] = useState(true);
	const [mounted, setMounted] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayEaaCaseDialog, setDisplayEAACaseDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const [changeCompany, setChangeCompany] = useState(false);

	const eaaCaseUpdate = async () => {
		if (eaaCaseId) {
			return await getEAACase(eaaCaseId, configurationsList, isReader, setReadonly, setEaaCase, setToastParams)
		}
	}

	useEffect(() => {
		if (mounted) {
			setTimeout(async () => {
				logDefault(CLASSNAME, 'info', 'eaaCaseUpdate in useEffect.setTimeout')
				if (!await eaaCaseUpdate()) {
					errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
					navigate('/' + ENUM_ROUTES.ERROR)
				}
			}, 10);
		}
	}, [mounted])

	useEffect(() => {
		setMounted(true)
	}, [])

	const handlePatchEaaCase = async (eaaCase) => {
		await patchEAACase(eaaCase, configurationsList, isReader, setReadonly, setEaaCase, setToastParams)
	}
	const handleExportEAACase = async () => {
		const eaaCaseCopy = copyObject(eaaCase)
		const status = configurationsList.CaseStatusList.find((e) => e.alias === eaaCaseCopy.status);
		const initiatedBy = configurationsList.InitiatedByTypesList.find((e) => e.alias === eaaCaseCopy.initiatedBy)
		await exportToPDF('EAACASE', { ...eaaCaseCopy, initiatedBy, status }, copyObject(configurationsList.CaseEventClassifierStructure), configurationsList.eaaModules);
	}
	const handleEditEaaCase = async () => {
		formikEAACase.setValues(await getEAACase(eaaCaseId, configurationsList));
		setDisplayEAACaseDialog(true)
	}
	const handleDeleteEaaCase = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData('eaa-cases', eaaCase.id).then(async () => {
			updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: ROUTES.EAACASES.label, route: `${ENUM_ROUTES.EAACASES}/` }] })
		}).catch((error) => {
			setToastParams({ title: 'Fall konnte nicht gelöscht werden', message: error.message })
		})
	}
	const handlerChangeCompany = () => {
		setChangeCompany(true);
		setDisplayCompanyDialog(true)
	}

	const changeCompanyId = async (companyId) => {
		eaaCase.companyId = companyId;
		await handlePatchEaaCase(eaaCase).then(() => {
			navigate(location.pathname)
		});
	}

	const renderSummaryContacts = () => {
		const labels = getTranslation(NAMESPACE, 'summary', { returnObjects: true })
		const summary = [
			{ label: labels['first-contacts'].label, value: (eaaCase.firstContact ? 'Ja' : 'nein') },
			{ label: labels['quantity-contacts.label'], value: (eaaCase.typeCONTACT) },
			{ label: labels['company-visit.label'], value: (eaaCase.G1) },
			{ label: labels.result.label, value: (eaaCase.typeCONTACTRESULT) },
			{ label: labels.sustainable.label, value: (eaaCase.typeSUSTAINABILITY) }
		]

		return <div className='flex gap-5  mx-4'>
			{summary.map((e) => <div>{`${e.label}: `}<b>{e.value}</b> </div>)}
		</div>
	}

	const renderDocLink = () => {
		let docLink = '';
		if (eaaCase && eaaCase.documentLocation) {

			try {
				const url = new URL(eaaCase.documentLocation);
				if (url.protocol === "http:" || url.protocol === "https:") {
					docLink = eaaCase.documentLocation
				} else {
					docLink = 'file:///' + eaaCase.documentLocation;
				}
			} catch (error) { }
		}
		return <a href={docLink} target="_blank" rel="noreferrer" >{docLink}</a>

	}

	/** formik EaaCase */
	const formikEAACase = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyEAACase)),
		validate: validateEAACase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderDialogs = () => {
		return <>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-deactivate'
				labelObject={{ action: readonly ? ' aktivieren?' : ' inaktivieren?' }}
				handleOnClick={async () => await handlePatchEaaCase({ ...eaaCase, status: (eaaCase.status.alias || eaaCase.status) === 'INACTIVE' ? 'OPEN' : 'INACTIVE' })}
				displayConfirmDialog={displayConfirmDialog}
				setDisplayConfirmDialog={setDisplayConfirmDialog} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				handleOnClick={handleDeleteEaaCase}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ToastError toastParams={toastParams} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Fall'
				name={eaaCase.name + ' - ' + eaaCase.company.name}
				serviceId={eaaCase.id}
				serviceName={ENUM_SERVICES.EAACASES}
				userId={currentUser.id} />
			<EditDialog
				displayDialog={displayEaaCaseDialog}
				form={<EAACaseForm formik={formikEAACase} />}
				formik={formikEAACase}
				labelKey={'editdialog'}
				NAMESPACE={NAMESPACE}
				patchForm={() => { handlePatchEaaCase(formikEAACase.values) }}
				setDisplayDialog={setDisplayEAACaseDialog}
			/>
			<CompanyChangeDialog
				companyId={eaaCase.companyId}
				displayCompanyDialog={displayCompanyDialog}
				setDisplayCompanyDialog={setDisplayCompanyDialog}
				handleOnCompanyPatched={eaaCaseUpdate}
				changeCompanyId={changeCompanyId} />
		</>
	}

	const listButtons = [
		{
			key: 'edit',
			NAMESPACE,
			translationKey: 'button-edit-eaacase',
			icon: ENUM_ICONS.PENCIL,
			command: handleEditEaaCase,
			disabled: isReader || readonly
		},
		{
			key: 'reminder',
			NAMESPACE: ENUM_NAMESPACES.REMINDERS,
			translationKey: 'button-edit-reminder',
			icon: ENUM_ICONS.CLOCK,
			command: () => setDisplayReminderDialog(true),
			disabled: isReader
		}
	]

	const renderContent = () => {
		const maxChar = 200;
		const title = eaaCase ? eaaCase.company.name + eaaCase.name : '';
		eaaCase.companyName = eaaCase.company.name
		const status = configurationsList.CaseStatusList.find((e) => e.alias === eaaCase.status);
		const initiatedBy = configurationsList.InitiatedByTypesList.find((e) => e.alias === eaaCase.initiatedBy)
		const items = configurationsList.CaseStatusList.map((caseStatus) => {
			return { label: caseStatus.display, alias: caseStatus.alias, command: async (e) => await handlePatchEaaCase({ ...eaaCase, status: caseStatus.alias }) }
		}).filter((e) => eaaCase.status.alias !== e.alias)
		return <div className="eaa-maincontent flex flex-column " >
			<BasicSheetTitleLayout
				screenReaderMessage={getScreenreaderMessage('EAA Fall', eaaCase, NAMESPACE)}
				NAMESPACE={NAMESPACE}
				listButtons={listButtons}
				title={eaaCase ? `${(title.length > maxChar ? eaaCase.name.substring(0, maxChar - eaaCase.company.name.length) + '...' : eaaCase.name)}` : ''}
				subtitle={
					<div id='spitbutton-eaacase' className="flex align-items-center gap-2">
						<div className='flex'>{getTranslation(NAMESPACE, 'subtitle.label', { displayId: eaaCase?.displayId, dateFormatted: formatDate(eaaCase.date), creatorname: eaaCase.creator?.displayname })}</div>
						{isReader ? <Tag className={'mr-2 text-white px-3 color-tag-' + status.color} rounded value={status.display} /> :
							<div  >
								<SplitButton aria-hidden={true} tabIndex={-1} disabled={isReader || readonly} menuButtonClassName={`color-tag-${status.color}`} buttonClassName={`text-sm text-white py-0 px-2 color-tag-${status.color}`} className={'mr-2  text-white p-button-text p-button-rounded p-0 color-tag-' + status.color} label={status.display} model={items}></SplitButton>
							</div>
						}
					</div>} />

			<div className='flex flex-column my-4' >
				<div className='flex flex-wrap justify-content-between grid'>
					<FieldInfo translationKey='fields.companyname' NAMESPACE={NAMESPACE} content={eaaCase.company.name} className='col-6' />
					<FieldInfo translationKey='fields.responsible' NAMESPACE={NAMESPACE} content={eaaCase.responsible ? eaaCase.responsible.displayname : 'n.n.'} className='col-3' />
					<FieldInfo translationKey='fields.creator' NAMESPACE={NAMESPACE} content={eaaCase.creator.displayname} className='hidden col-3' />
				</div>
				<div className='flex flex-wrap justify-content-between  grid'>
					<FieldInfo translationKey='fields.initiatedBy' NAMESPACE={NAMESPACE} content={initiatedBy.display} className='col-3' />
					<FieldInfo translationKey='fields.documentLocation' NAMESPACE={NAMESPACE} content={renderDocLink()} className='col-9' />
				</div>
				<Divider />

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-1'>
						{getTranslation(NAMESPACE, 'fields.description.label')}
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: eaaCase.description }} />
				</div>

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-2'>
						{getTranslation(NAMESPACE, 'summary.label')}
					</div>
					{renderSummaryContacts()}
				</div>
				<div className='flex text-1xl mx-2'>
					{eaaCase ? <CaseEventsSheet eaaCase={eaaCase} caseEvents={eaaCase.caseEvents} eaaCaseUpdate={eaaCaseUpdate} readonly={readonly} /> : ''}
				</div>
			</div>
		</div>
	}
	//<div className={showChangeCompany && isAdmin ? 'flex w-1rem ' : 'hidden'} onClick={handlerChangeCompany} ><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.CHANGECOMPANY} size={ENUM_AWESOMEICON_SIZE.X1} /></div>
	const renderItems = () => {
		const items = [{ label: 'Bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditEaaCase, disabled: isReader || readonly },
		{ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportEAACase },
		{ label: 'Unternehmen wechseln', disabled: !isAdmin || readonly, icon: <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.CHANGECOMPANY} size={ENUM_AWESOMEICON_SIZE.X1} className='mr-1' />, command: handlerChangeCompany },
		{ separator: true },
		{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => setDisplayConfirmDialog(true), disabled: isReader }]
		if (currentUser.permissions === 'ADMIN' && eaaCase) {
			items.push({ separator: true })
			items.push({ label: 'Fall unwiderruflich löschen', className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return <OverlayPanelMenu items={items} />;
	}

	const getSideSheets = () => {
		const amountCaseRequests = eaaCase.caseRequests.length
		const colorBadgeCaseRequests = amountCaseRequests === 0 ? 'danger' : (eaaCase.caseRequests.find(e => e.status.alias === 'INPROGRESS') ? 'warning' : 'success')
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly} c
					company={eaaCase.company}
					onUpdateCompany={eaaCaseUpdate}
					showChangeCompany={true} />
			},
			{ index: 1, visible: true, icon: ENUM_ICONS.USER + "fa-lg", component: <CasePersonSheet readonly={readonly} eaaCase={eaaCase} patchEaaCase={handlePatchEaaCase} /> },
			{
				index: 2, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet readonly={readonly} key='relatedUserSheet' parentId={eaaCase.id} relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.CASE} hideDivider={true} />
					<AssociatedContactSheet readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.CASE} parent={eaaCase} />
				</div>
			},
			{
				index: 3,
				visible: true,
				awesomeIcon: ENUM_AWESOMEICON.CASEREQUESTS,
				component: <CaseRequestSheet eaaCase={eaaCase} readonly={readonly} updateEaaCase={eaaCaseUpdate} />,
				badge: <Badge value={amountCaseRequests} severity={colorBadgeCaseRequests} style={{ right: '15px', }} className="z-2 relative flex" />
			},

		]
		return sideSheets;
	}

	return (!eaaCase ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!eaaCase ? '' : renderContent()}{!eaaCase ? '' : renderDialogs()}</div>}
			overlayMenu={renderItems()}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={eaaCase.id}
		/>
	)
}
