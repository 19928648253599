import { useEffect, useState } from 'react';
import { DropdownChangesChecked } from '../../Components/DropdownChangesChecked';
import { useConfigurationsList } from '../../Persistence/ConfigurationsContext';
import { Button } from 'primereact/button';

const CLASSNAME = 'FBiBJobOpeningStatusForm'
export const FBiBJobOpeningStatusForm = ({ formik, validateForm }) => {

	const configurationsList = useConfigurationsList();
	const [statusOptions, setStatusOptions] = useState();

	useEffect(() => {
		const statusList = []

		if (formik.values.currentStatus === 'OPEN') {
			statusList.push({ alias: 'RESERVED', display: 'auf reserviert setzen' })
			statusList.push({ alias: 'ARRANGED', display: 'auf vermittelt setzen' })
		} else if (formik.values.currentStatus === 'RESERVED') {
			statusList.push({ alias: 'OPEN', display: 'auf offen setzen' })
			statusList.push({ alias: 'ARRANGED', display: 'auf vermittelt setzen' })
		}
		else if (formik.values.currentStatus === 'ARRANGED') {
			statusList.push({ alias: 'OPEN', display: 'auf offen setzen' })
			statusList.push({ alias: 'RESERVED', display: 'auf reserviert setzen' })
		}
		setStatusOptions(statusList)
	}, [formik.values.currentStatus])

	return !formik ? <div></div> : <div className=''>
		<div className="formgrid grid">
			<div className="field col mr-3" style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: `Diese Stelle hat den Status <b>${configurationsList.FBiBJobOpeningStatusList.find((e) => e.alias === formik.values.currentStatus).display} </b><br/>Sie können den Status dieses Stellenprofil für den FBiB Fall<br/><b>"${formik.values.fbibCaseName}"</b><br/>ändern` }} />
		</div>
		<div className="formgrid grid">
			<DropdownChangesChecked
				classNameDropdown='fbib'
				label='Status'
				id='status'
				itemLabel='display'
				optionValue='alias'
				className='fbib mr-3 '
				formik={formik}
				options={statusOptions} />
		</div>
	</div>
}

