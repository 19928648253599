import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { TemplateDataViewPagination } from '../../Components/TemplateDataViewPagination';
import { useDataviewsConfig } from '../../Persistence/ConfigurationsContext';
import { useFormik } from 'formik';
import { copyObject } from '../../snippets';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { useBreadcrumbsAdd } from '../../Persistence/Breadcrumbs';
import { FBiBCaseForm, emptyFBiBCase, validateFBiBCase } from './FBiBCaseForm';
import { patchFBiBCase } from './FBiBCasesManager';
import { FBiBCaseCreateWizard } from './FBiBCaseCreateWizard';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'FBiBCasesDataview'
const NAMESPACE = ENUM_NAMESPACES.FBIBCASES
export const FBiBCasesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const addBreadcrumb = useBreadcrumbsAdd();
	const isReader = useCurrentUserIsReaderFBiB();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.FBIBCASES));

	/** formik FBiB Case */
	const formik = useFormik({
		initialValues: copyObject(emptyFBiBCase),
		validate: validateFBiBCase,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ filterAlias: 'col_01', field: "displayId", style: { width: '150px' }, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_02', field: "name", style: { width: '250px' }, body: null, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_03', field: "responsible.lastname", style: { width: '250px' }, className: '', body: renderResponsible, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_04', field: "status", style: { width: '200px' }, className: '', alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_05', field: "company.name", style: { width: '250px' }, className: '', body: (rowData) => { return rowData.company ? rowData.company.name : '' } },
		{ filterAlias: 'col_06', field: "casePerson.personId", style: { width: '150px' }, className: '', body: (rowData) => { return rowData.casePerson ? rowData.casePerson.personId : '' } },
		{ filterAlias: 'col_07', field: "trainingCenter.name", style: { width: '250px' }, className: '', body: (rowData) => { return rowData.trainingCenter ? rowData.trainingCenter.name : '' } },
		{ filterAlias: 'col_08', field: "millisecondsDate", style: { width: '180px' }, className: '' },
	]

	return <div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
		<div className='' >
			<EditDialog
				displayDialog={displayEditDialog}
				formik={formik}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				labelButtonOk='Anlegen'
				header='FBiB Fall anlegen'
				message='Lege einen neuen FBiB Fall an'
				patchForm={async () => {
					await patchFBiBCase(formik.values, currentUser).then((fbibCase) => {
						addBreadcrumb(`${fbibCase.name}`, `${ENUM_ROUTES.FBIB_CASES}/` + fbibCase.id);
					})
				}}
				setDisplayDialog={setDisplayEditDialog}
				form={<FBiBCaseForm
					formik={formik}
					readOnly={false}
				/>}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-fbibcases'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.FBIB_CASES}
				buttons={[<FBiBCaseCreateWizard />]}
				columns={columns}
				dataTableWidth='1400px'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	</div>
}