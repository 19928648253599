import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES'
import { formatDate, formatDateTime, getTranslation } from '../../snippets'
import { getDateFilterElement } from "../FilterTemplates/DateRangeFilterElement";
import { getListFilterElement } from "../FilterTemplates/ListFilterElement";
import { getTextFilterElement } from "../FilterTemplates/TextFilterElement";
import { getSingleTagFilterElement } from "../FilterTemplates/SingleTagFilterElement";
import { getMultiTagFilterElement } from "../FilterTemplates/MultiTagFilterElement";
import { getMultiSelectFilterElement } from "../FilterTemplates/MultiSelectFilterElement";
import { getResponsibleMultiSelect } from "../FilterTemplates/ResponsibleMultiSelect";
import { Tag } from 'primereact/tag';
import { Badge } from 'primereact/badge';
import { ENUM_AWESOMEICON_SIZE, Icon } from '../Icons';
import { Column } from 'primereact/column';
import '../../css/dataviews.scss'

const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS


const renderHeader = (translationKey) => {
    return <div className='flex w-full' aria-hidden={true}>{getTranslation(NAMESPACE, `${translationKey}.headline.label`)}</div>
}
const renderDefault = (value) => {
    return <div>{value}</div>
}

const renderDate = (field, filter, configurationsList) => {
    return (rowData) => renderDefault(rowData[field] ? formatDate(rowData[field]) : '---')
}
const renderDateTime = (field, filter, configurationsList) => {
    return (rowData) => renderDefault(rowData[field] ? formatDateTime(rowData[field]) : '---')
}
const renderResponsible = (field, filter, configurationsList) => {
    return (rowData) => renderDefault(rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
}
const renderTag = (field, filter, configurationsList) => {
    const list = configurationsList[filter?.filterListName] || [];
    const fieldName = field
    return (rowData) => {
        const values = Array.isArray(rowData[fieldName]) ? rowData[fieldName] : [rowData[fieldName]]
        const returnValue = values.map(value => {
            const alias = value.constructor === Object ? value.alias : value;
            const option = list.find(entry => entry.alias === alias);
            const style = option && option.backcolor ? { color: '#' + option.color, backgroundColor: '#' + option.backcolor, borderColor: '#' + option.color, borderStyle: 'solid', borderWidth: option.backcolor.toLowerCase() === 'ffffff' ? '1px' : '0px' } : {};
            const className = 'px-3 mx-1' + (option && option.backcolor ? '' : (option ? 'text-white color-tag-' + option.color : ''));
            return <Tag
                key={'tag_' + rowData.id + '_' + alias}
                style={style}
                className={className}
                rounded
                value={option ? (option.datatable || option.alias) : '---'}
            />
        })
        return renderDefault(returnValue)
    }
}

const renderListIcon = (field, filter, configurationsList) => {
    const list = configurationsList[filter?.filterListName] || [];

    return (rowData) => {
        const entry = list.find(e => rowData[field] === e.alias);
        const badge = entry.functionBadge && entry.functionBadge(rowData) ? <Badge severity='danger' className='fa-badge' /> : '';
        const returnValue = entry ? (entry.icon ?
            <i className={'flex w-full justify-content-center ' + entry.icon} /> :
            <Icon ENUM_AWESOMEICON={entry.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className={'flex w-full justify-content-center '} badge={badge} />
        ) : rowData[field];
        return renderDefault(returnValue)
    }
}

const renderList = (field, filter, configurationsList) => {
    const list = configurationsList[filter?.filterListName] || [];
    return (rowData) => {
        const entry = list.find(e => rowData[field] === e.alias)
        return renderDefault(entry?.datatable || rowData[field]);
    }

}

const renderFunctions = { 'renderDate': renderDate, 'renderDateTime': renderDateTime, 'renderListIcon': renderListIcon, 'renderList': renderList, 'renderTag': renderTag, 'renderResponsible': renderResponsible }
const listFilterElements = { 'textFilterElement': getTextFilterElement, 'listFilterElement': getListFilterElement, 'dateFilterElement': getDateFilterElement, 'responsibleFilterElement': getResponsibleMultiSelect, 'multiSelectFilterElement': getMultiSelectFilterElement, 'singleTagFilterElement': getSingleTagFilterElement, 'multiTagFilterElement': getMultiTagFilterElement };

const getBodyFunction = (columnConfig, screenreaderColumn, filter, getDoubleClickCommand, configurationsList) => {
    const columnAlias = columnConfig.filterAlias
    const field = filter?.field || columnConfig.field
    const srFields = columnAlias !== screenreaderColumn ? null : columnConfig?.screenreaderFields || [{ label: '', field: field }]
    let renderFunction = (rowData) => renderDefault(rowData[field])
    if (columnConfig.body) {
        renderFunction = columnConfig.body
    } else if (srFields) {
        renderFunction = (rowData) => <a
            tabIndex={0}
            aria-label={'Listeneintrag ' + srFields.map((e) => { return `${e.label} ${rowData[e.field]} ` }).join('')}
            onClick={(e) => { getDoubleClickCommand({ data: rowData }) }}
        >
            {renderDefault(rowData[field])}
        </a>
    } else if (renderFunctions[filter?.renderFunction]) {
        renderFunction = renderFunctions[filter?.renderFunction](field, filter, configurationsList)
    }
    return renderFunction

}

const getFilterElementFunction = (dataviewConfigKey, filter) => {
    const filterElementFunction = listFilterElements[filter?.filterElement] || listFilterElements['textFilterElement']
    return filterElementFunction(dataviewConfigKey)
}


export const DataviewColumn = ({ key, header, sortable, body, field, style, className, frozen, alignFrozen, align, onFilterClear, filter, filterField, filterElement, filterPlaceholder, sortField }) => {

    return <Column
        style={style}
        key={'dataviewcolumn_' + key}
        className={className}
        frozen={frozen}
        alignFrozen={alignFrozen}
        rowHover
        sortable={sortable}
        sortField={sortField}
        filter={filter}
        filterField={filterField}
        field={field}
        filterPlaceholder={filterPlaceholder}
        filterElement={filterElement}
        onFilterClear={onFilterClear}
        body={body}
        header={header}
        align={align}
        showFilterMenu={false}
    />
}
export function getDataviewColumn(columnConfig, filter, setFilterValue, getDoubleClickCommand, dataviewConfig, configurationsList) {
    const columnAlias = columnConfig.filterAlias
    const translationKey = `${dataviewConfig.key}.${columnAlias}`
    const screenreaderColumn = dataviewConfig.screenreaderColumn
    const filterElementFunction = getFilterElementFunction(dataviewConfig.key, filter)
    const bodyFunction = getBodyFunction(columnConfig, screenreaderColumn, filter, getDoubleClickCommand, configurationsList)
    return <DataviewColumn
        style={columnConfig.style}
        key={columnAlias}
        className={`${columnConfig.filterAlias} ${columnConfig.className || ' '}`}
        frozen={columnConfig.frozen}
        alignFrozen={columnConfig.alignFrozen}
        header={renderHeader(translationKey)}
        sortable={filter?.sortable || columnConfig.sortable}
        sortField={columnConfig.sortField ? columnConfig.sortField : null}
        body={bodyFunction}
        onFilterClear={() => setFilterValue(columnConfig.filter, null)}
        field={filter?.field || columnConfig.field}
        filter={filter?.filter || columnConfig.filter}
        filterField={columnAlias}
        filterElement={filterElementFunction}
        filterPlaceholder={filter?.filterPlaceholder}
        align={columnConfig.align}
        columnConfig={columnConfig}
        dataviewConfig={dataviewConfig}
        filters={filter}
        screenreaderColumn={screenreaderColumn}
    />
}