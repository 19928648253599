import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils"
import { useEffect, useRef, useState } from "react";
import { logDefault } from "../../Utils/logger";
import { useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { getTranslation } from "../../snippets";

const CLASSNAME = 'DropdownChangesChecked';
export const DropdownChangesChecked = ({ id, disabled, formik, className, optionLabel, optionValue, multiple, itemTemplate, valueTemplate, options, placeholder, filter, filterBy, onChange, handleChange, showClear, tabIndex, idError, classnameDiv, NAMESPACE, translationKey }) => {
	const fbibMode = useFBiBMode();
	const inputRef = useRef(null);
	const [labels, setLabels] = useState(getTranslation(NAMESPACE, `${translationKey || id}`, { returnObjects: true }));
	const [mounted, setMounted] = useState(false);
	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id].label}</small>;
	};

	useEffect(() => {
		if (mounted && inputRef.current) {
			const focusInput = inputRef.current.getFocusInput()
			focusInput.removeAttribute('readonly');
		}
	}, [mounted]);

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'id:' + id, formik.values[id])
		logDefault(CLASSNAME, 'info', 'id:' + id + ' labels:', labels)
		setMounted(true)
	}, [])

	const handleOnChange = (e) => {
		if (onChange) {
			onChange(e.value)
		}
		if (handleChange) {
			handleChange(e.value)
		}
		formik.handleBlur(e)
		formik.handleChange(e);
	}

	const defaultOptionTemplate = (option) => {
		return (
			<div role="option" aria-label={option.ariaLabel || option.label}>
				{optionLabel ? option[optionLabel] : option.label}
			</div>
		);
	};
	const defaultValueTemplate = (option) => {
		return !option ? '' :
			<div role="option" aria-label={option.ariaLabel || option.label}>
				{optionLabel ? option[optionLabel] : option.label}
			</div>
			;
	};
	return (
		<div className={'field col ' + (classnameDiv || 'mr-3')}>
			<label htmlFor={id} className='block' aria-label={labels ? labels.ariaLabel : ''} >{labels?.label}</label>
			<Dropdown key={'dropdown-' + id}
				ref={inputRef}
				id={id}
				aria-label={labels ? labels.ariaLabel : ''}
				value={formik.values[id]}
				tabIndex={tabIndex}
				disabled={disabled}
				type='text'
				filter={filter}
				filterBy={filterBy}
				optionLabel={optionLabel}
				optionValue={optionValue}
				valueTemplate={valueTemplate}
				multiple={multiple}
				itemTemplate={itemTemplate}
				emptyMessage={labels?.emptyMessage?.label || 'Keine Auswahl vorhanden'}
				emptyFilterMessage={labels?.emptyFilterMessage?.label}
				options={options}
				onChange={handleOnChange}
				placeholder={labels?.placeholder?.label}
				aria-placeholder={!formik.values[id] && labels?.placeholder ? labels.placeholder.ariaLabel : null}
				panelClassName={fbibMode ? 'fbib p-2' : 'p-2'}
				className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' w-full ' + className}
				showClear={showClear}
			/>
			{getFormErrorMessage(idError ? idError : id)}
		</div>
	)

}

