import { ColorPicker } from 'primereact/colorpicker';
import { classNames } from "primereact/utils";

export const Colorpicker = ({ id, label, formik, className, tabIndex, col }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id].label}</small>;
	};
	return (
		<div className={"field " + (col || 'col')}>
			<label htmlFor={id} className='block'>{label}</label>
			<ColorPicker id={id} name={id} tabIndex={tabIndex} value={formik.values[id]} onChange={(e) => { formik.handleBlur(e); formik.handleChange(e); }} className={classNames({ 'p-invalid': isFormFieldValid(id) }) + (className ? className : ' w-full')} />
			{getFormErrorMessage(id)}
		</div>
	)

}

