import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import client from '../feathers';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useEffect, useRef, useState } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { getSortedList } from '../snippets';
import { ENUM_AWESOMEICON, Icon } from '../Components/Icons';

const navitemClassName = 'p-ripple flex flex-row  gap-2 align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors'

export const Menuinfo = ({ showSidebarLabel }) => {
	const configurationsList = useConfigurationsList();
	const [toastParams, setToastParams] = useState(null);
	const [isMenuVisible, setIsMenuVisible] = useState(false);
	const [toggleEvent, setToggleEvent] = useState();
	const [items, setItems] = useState([]);
	const menu = useRef()

	useEffect(() => {
		const items = []
		for (let item of configurationsList.eaaModules.MENUINFO.items) {
			if (item.visible === 1) {
				if (item.filepath) {
					//item.icon = 'fa fa-file-circle-exclamation'
					item.command = () => openFile(item.filepath)
				} else if (item.targeturl) {
					item.command = () => window.open(item.targeturl, '_blank');
				}
				items.push(item)
			}
		}
		setItems(getSortedList(items, 'pos'))
	}, [])

	useEffect(() => {
		if (isMenuVisible && menu.current && toggleEvent) {
			menu.current.hide(toggleEvent);
		}
	}, [showSidebarLabel])

	const openFile = async (filepath) => {
		await client.service(ENUM_SERVICES.UTILS).create({ method: 'menuinfofile', filepath }).then(async (result) => {
			const base64 = await fetch(result);
			var blob = await base64.blob();
			// Erstellen einer Blob-URL
			const fileURL = URL.createObjectURL(blob);
			// Öffnen der Datei in einem neuen Tab
			window.open(fileURL);
		}).catch((error) => {
			setToastParams({ title: 'Beim Holen des Datenschutzblattes ist ein Fehler aufgesteten !', message: error.message })
		});
	}

	const toggleMenu = (event) => {
		setToggleEvent(event)
		menu.current.toggle(event);  // Toggle TieredMenu visibility
		setIsMenuVisible((prevState) => !prevState);  // Toggle visibility state
	};
	const renderEntry = () => {
		//
		const label = configurationsList.eaaModules.MENUINFO.label
		const ariaLabel = configurationsList.eaaModules.MENUINFO.ariaLabel
		const icon = configurationsList.eaaModules.MENUINFO.icon
		const className = " flex mr-2 lg:mr-0 lg:text-2xl w-2rem justify-content-center p-overlay-badge navigation-entry " + 'text-base';
		return <div aria-label={ariaLabel} tabIndex={0} role='button'>
			<a className={navitemClassName} onClick={toggleMenu}>
				<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.IMPRESSUM} className={className} />
				<div className={(showSidebarLabel ? 'flex inline' : 'hidden') + " font-semibold text-base"}>{label}</div>
			</a>
		</div>
	}

	return <>
		<TieredMenu
			model={items}
			popup ref={menu}
			breakpoint="767px"
			onHide={() => setIsMenuVisible(false)}
		/>
		{renderEntry()}
	</>

}

