import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { getTranslation } from "../snippets";

export const InputNumberChecked = ({ id, min, max, label, formik, className, setShowDialog, disabled, digits, showButtons, step, col, rowStyle, colLabels, inputStyle, NAMESPACE, translationKey }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block" dangerouslySetInnerHTML={{ __html: formik.errors[id].label }} />;
	};

	const onChange = (e) => {
		if (setShowDialog) {
			setShowDialog(true);
		}
		formik.setFieldValue(id, e.value)
	}

	return (
		<div className={rowStyle ? 'flex w-full m-2' : 'field col mr-3'}>
			<label htmlFor={id} className={rowStyle ? (colLabels || 'col-3') : 'block'} >{label || getTranslation(NAMESPACE, `${translationKey || id}.label`)}</label>
			<div className={rowStyle ? '' : 'col p-0'}>
				<InputNumber
					style={inputStyle}
					key={'input-' + id}
					inputId={'input-' + id}
					max={max ? max : undefined}
					min={min ? min : 0}
					step={step ? step : 1}
					showButtons={showButtons}
					maxFractionDigits={digits ? digits : 0}
					minFractionDigits={digits ? digits : 0}
					value={formik.values[id]}
					onValueChange={onChange}
					disabled={disabled}
					mode="decimal"
					locale="de-DE"
					className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' ' + className}
					inputClassName={' ' + className}
					autoComplete="off"
					onFocus={(event) => { event.target.setAttribute('autocomplete', 'new-password') }} />
				{getFormErrorMessage(id)}
			</div>
		</div>
	)

}

