
import { startOfDay } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { useEffect, useState } from "react";
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { DropdownChangesChecked } from '../Components/FormElements/DropdownChangesChecked';

import { InputChangesChecked } from '../Components/InputChangesChecked';
import { InputNumberChecked } from '../Components/InputNumberChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';
import { getTranslation } from '../snippets';

export const defaultProviderActivity = { id: null, version: 1, active: 1, name: '', date: startOfDay(new Date()), endDate: null, typeActivity: 'INFORMATIONMATERIALS_PRINT', description: '', recurring: false, participants: 0, userId: null }
export const hasParticipants = (value) => ['OWNEVENTS', 'PARTICIPATION_THIRDPARTYEVENTS'].includes(value)

const NAMESPACE = ENUM_NAMESPACES.PROVIDERACTIVITIES
const CLASSNAME = 'ProviderActivitiesForm'
export const ProviderActivitiesForm = ({ formik }) => {
	const isReader = useCurrentUserIsReaderEAA();
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const [readonly, setReadonly] = useState(false);
	const [mounted, setMounted] = useState(false)
	const [labels, setLabels] = useState(getTranslation(NAMESPACE, 'fields', { returnObjects: true }))

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)
		setReadonly(isReader);
		setMounted(true)
	}, [isReader, formik.values])

	const onTypeActivityChange = (value) => {
		switch (value) {
			case 'COOPERATION_MEETINGS': formik.setFieldValue('participants', 0); onRecurringChange(false); break;
			case 'INTERNETPRESENCE':
			case 'INFORMATIONMATERIALS_DIGITAL':
			case 'INFORMATIONMATERIALS_PRINT':
				formik.setFieldValue('participants', 0);
				break;
			default: onRecurringChange(false);
		}
	}

	const onRecurringChange = (value) => {
		logDefault(CLASSNAME, 'info', 'onRecurringChange:', value)
		if (!value) {
			formik.setFieldValue('endDate', null);
		}
		formik.setFieldValue('recurring', value);
	}

	const getSpecialComponent = (typeActivity) => {
		logDefault(CLASSNAME, 'info', 'getSpecialComponent:', typeActivity)
		switch (typeActivity) {
			case 'COOPERATION_MEETINGS': return '';
			case 'INTERNETPRESENCE':
			case 'INFORMATIONMATERIALS_DIGITAL':
			case 'INFORMATIONMATERIALS_PRINT':
				return <div className='flex formgrid grid col-6'>
					<div className='field mr-6 col-2'>
						<label className='block' htmlFor="recurring">{labels.recurring.label}</label>
						<Checkbox inputId="recurring" checked={formik.values.recurring} onChange={e => onRecurringChange(e.checked)} />
					</div>
					<CalendarChangesChecked
						id='endDate'
						translationKey='fields.endDate'
						NAMESPACE={NAMESPACE}
						formik={formik}
						key='endDate'
						disabled={!formik.values.recurring}
						className={'field col-fixed w-11rem'}
						classNameCalendar=' w-full'
						autoFocus={true}
					/>
				</div>
			default: return <InputNumberChecked className='col-4' id='participants' label='Teilnehmer*innenzahl' type="number" formik={formik} disabled={readonly} min={0} showButtons={true} />
		}
	}

	return (
		<div className='provider-activities-form col' >
			{!formik ? '' : <div className="formgrid grid">
				<div className="field col  mb-0">
					<div className="formgrid grid">
						<InputChangesChecked
							NAMESPACE={NAMESPACE}
							translationKey='fields.name'
							id='name'
							type="text"
							formik={formik}
						/>
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked
							className='h-3rem line-height-4 col-6'
							id='typeActivity'
							NAMESPACE={NAMESPACE}
							translationKey='fields.typeActivity'
							formik={formik}
							optionLabel='display'
							optionValue='alias'
							onChange={onTypeActivityChange}
							options={configurationsList.ProvideractivitiesTypeList.filter(e => !e.isHeadline)}
						/>
						{getSpecialComponent(formik.values.typeActivity)}
					</div>
					<div className="formgrid grid">
						<CalendarChangesChecked
							id='date'
							translationKey='fields.date'
							NAMESPACE={NAMESPACE}
							formik={formik}
							key='date'
							className='field col-6 mr-3'
							classNameCalendar='w-10rem w-full'
							autoFocus={true}
						/>
					</div>
					<div className="formgrid grid">
						{!mounted ? '' : <EditorChangesChecked
							id='description'
							value={formik.values.description}
							setValue={(value) => formik.setFieldValue('description', value)}
							translationKey='fields.description'
							NAMESPACE={NAMESPACE}
							required={false}
						/>}
					</div>
				</div>
			</div>}
		</div>

	)
}


export const validateProviderActivity = (data) => {
	let errors = {};
	if (!data.name) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error', { returnObjects: true });
	}
	if (!data.date) {
		errors.date = getTranslation(NAMESPACE, 'fields.date.error', { returnObjects: true });
	}
	if (data.recurring && !data.endDate) {
		errors.endDate = getTranslation(NAMESPACE, 'fields.endDate.error', { returnObjects: true });
	}
	logDefault(CLASSNAME, 'info', 'validateProviderActivity:', data)
	return errors;
}