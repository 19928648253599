import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button";
import { v4 as uuidv4 } from 'uuid';
import { getTranslation } from "../snippets";
import { useEffect, useState } from "react";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

export const ConfirmDialog = ({ NAMESPACE, labelKey, labelObject, displayConfirmDialog, setDisplayConfirmDialog, handleOnClick, handleOnReject, className, style }) => {
	const [labels, setLabels] = useState(getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `confirmdialog`, { returnObjects: true }));

	useEffect(() => {
		let dialogLabels = NAMESPACE && labelKey ? getTranslation(NAMESPACE, `${labelKey}`, (labelObject ? { ...labelObject, returnObjects: true } : { returnObjects: true })) : labels
		if (!dialogLabels['button-ok']) {
			dialogLabels['button-ok'] = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `confirmdialog.button-ok`, { returnObjects: true })
		}
		if (!dialogLabels['button-cancel']) {
			dialogLabels['button-cancel'] = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `confirmdialog.button-cancel`, { returnObjects: true })
		}
		if (!dialogLabels.message) {
			dialogLabels.message = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `confirmdialog.message`, { returnObjects: true })
		}
		if (!dialogLabels.headline) {
			dialogLabels.headline = getTranslation(ENUM_NAMESPACES.FORMELEMENTS, `confirmdialog.headline`, { returnObjects: true })
		}
		setLabels(dialogLabels)
	}, [labelObject])

	return (<>
		<Dialog key={uuidv4()}
			style={style}
			header={labels.headline.label}
			closable={false}
			visible={displayConfirmDialog}
			onHide={() => setDisplayConfirmDialog(false)}
			id="dialog-confirm"
			className={'eaa-inputdialog ' + (className || 'w-28rem')} >
			<div className='flex font-light my-3 p-0 ' style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: labels?.message.label }}>{ }</div>
			<div className="flex flex-row card-container flex-row-reverse mx-4 my-2">
				<Button
					key={uuidv4()}
					type="button"
					className="flex-order-1 button-cancel"
					label={labels['button-cancel'].label}
					onClick={() => { handleOnReject ? handleOnReject() : setDisplayConfirmDialog(false) }}
				/>
				<Button
					key={uuidv4()}
					onClick={() => { handleOnClick(); setDisplayConfirmDialog(false) }}
					className="flex-order-0 ml-3 "
					label={labels['button-ok'].label}
				/>
			</div>
		</Dialog>
	</>)
}
