import { useFormik } from "formik";
import { useState } from 'react'
import { CompanyForm, emptyCompany, validateCompany } from "../../Companies/CompanyForm";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { useCurrentUser, useCurrentUserIsReaderFBiB } from "../../Persistence/CurrentUserContext";
import { Button } from "primereact/button";
import { getCompany } from "../../Companies/CompanyManager";
import { useBreadcrumbsUpdate } from "../../Persistence/Breadcrumbs";
import { copyObject } from "../../snippets";
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../../Components/Icons";
import { FBiBJobOpeningForm, emptyFBiBJobOpening, validateFBiBJobOpening } from "./FBiBJobOpeningForm";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { patchFBiBJobOpening } from "./FBiBJobOpeningManager";
import { ENUM_ROUTES, ROUTES } from "../../Navigation/Routes";
import { patchData } from "../../feathers";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.FBIBJOBOPENINGS
const CLASSNMAE = 'FBiBJobOpeningCreateWizard'
export const FBiBJobOpeningCreateWizard = ({ ENUM_AWESOMEICON: icon, disabled, parentCompanyId }) => {
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderFBiB()
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [step, setStep] = useState(0)

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	/** formik formikJobOpening */
	const formikJobOpening = useFormik({
		initialValues: copyObject(emptyFBiBJobOpening),
		validate: validateFBiBJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const startWizard = () => {
		formikCompany.setValues(copyObject(emptyCompany));
		formikJobOpening.setValues(copyObject(emptyFBiBJobOpening))
		setStep(1)
	}

	const createFBiBJobOpening = async () => {
		let companyId = parentCompanyId;
		if (!companyId) {
			/** Die Anlage / Aktualisierung des Unternemens  wurde gewählt */
			const company = { ...formikCompany.values, origin: 'FBIB' }
			companyId = await patchData(ENUM_SERVICES.COMPANIES, company).then((result) => {
				return result.id
			})
		}
		const fbibJobOpening = { ...formikJobOpening.values, companyId }
		await patchFBiBJobOpening(fbibJobOpening).then(async (fbibJobOpening) => {
			/** öffne neu erstellten Fall */
			updateBreadcrumbs({ home: { icon: ENUM_ICONS.JOBPROFILE }, items: [{ label: ROUTES.FBIB_JOBPROFILES.label, route: `${ENUM_ROUTES.FBIB_JOBPROFILES}/` + fbibJobOpening.id }] })
		})
	}

	const handleSelectExistingCompany = async (companyId) => {
		formikCompany.setTouched({})
		if (companyId) {
			await getCompany(companyId).then(company => {
				formikCompany.setValues(company)
			})
		} else {
			formikCompany.setValues(copyObject(emptyCompany))
		}
	}

	return <>
		{step !== 1 ? '' : <EditDialog
			NAMESPACE={NAMESPACE}
			labelKey={'editdialog-newcompany'}
			form={
				<CompanyForm
					readOnly={false}
					formik={formikCompany}
					showTrash={true}
					handleSelectExistingCompany={handleSelectExistingCompany}
				/>
			}
			formik={formikCompany}
			displayDialog={step === 1}
			setDisplayDialog={setStep}
			patchForm={async () => {
				formikJobOpening.setFieldValue('companyId', formikCompany.values.id);
				formikJobOpening.setFieldValue('company', formikCompany.values);
				setStep(2)
			}}
		/>}

		{step !== 2 ? '' : <EditDialog
			NAMESPACE={NAMESPACE}
			labelKey={'editdialog-new'}
			form={
				<FBiBJobOpeningForm
					formik={formikJobOpening}
					editmode={true}
					key={'FBiBJobOpeningForm'} />
			}
			formik={formikJobOpening}
			displayDialog={step === 2}
			setDisplayDialog={setStep}
			patchForm={createFBiBJobOpening}
		/>}

		<Button disabled={isReader || disabled} className={'flex text-white ' + (disabled ? 'color-background-lessblack' : '')} onClick={startWizard} >
			{!icon ? '' : <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.FOLDER_PLUS} size={ENUM_AWESOMEICON_SIZE.X1} className='mr-2' />}
			<div>FBiB Stellenprofil anlegen</div>
		</Button>
	</>

}