import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../../Navigation/Routes';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { Button } from 'primereact/button';
import { TemplateDataViewPagination } from '../../Components/TemplateDataViewPagination';
import { useConfigurationsList, useDataviewsConfig } from '../../Persistence/ConfigurationsContext';
import { useFormik } from 'formik';
import { copyObject } from '../../snippets';
import { EditDialog } from '../../Components/Dialogs/EditDialog';
import { useBreadcrumbsAdd } from '../../Persistence/Breadcrumbs';
import { TrainingCourseForm, emptyTrainingCourse, validateTrainingCourse } from './TrainingCourseForm';
import { openTrainingCourse, patchTrainingCourse } from './TrainingCourseManager';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'TrainingCourseDataview'
const NAMESPACE = ENUM_NAMESPACES.TRAININGCOOURSES
const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.TRAININGCOURSES;
export const TrainingCourseDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const configurationsList = useConfigurationsList();
	const addBreadcrumb = useBreadcrumbsAdd();
	const isReader = useCurrentUserIsReaderFBiB();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));

	/** formik Training Center */
	const formik = useFormik({
		initialValues: copyObject(emptyTrainingCourse),
		validate: validateTrainingCourse,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "id", sortable: true, filter: true, header: "ID", style: { width: '200px' }, body: (rowData) => rowData.displayId },
		{ filterAlias: 'col_03', field: "chamber", sortable: true, filter: true, header: "Kammer", style: { width: '300px' }, body: (rowData) => configurationsList.ChamberList.find(e => e.id === rowData.chamberId).datatable },
		{ filterAlias: 'col_02', field: "name", sortable: true, filter: true, filterPlaceholder: "Name suchen", header: "Name des Ausbildungsgangs", style: { flexGrow: 1, flexBasis: '200px' }, body: null },
		{ filterAlias: 'col_06', field: "duration", header: "Dauer in Monaten", style: { width: '100px' }, },
		{ filterAlias: 'col_04', field: "type", sortable: true, filter: true, header: "Typ", style: { width: '300px' }, body: (rowData) => configurationsList.TrainingCourseTypeList.find(e => e.alias === rowData.type).datatable },
		{ filterAlias: 'col_05', field: "active", sortable: true, filter: "active", header: "Status", style: { width: '150px' } },
	]

	return <div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
		<div className='' >
			<EditDialog
				displayDialog={displayEditDialog}
				formik={formik}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				patchForm={async () => {
					await patchTrainingCourse(formik.values, currentUser).then((trainingCourse) => {
						addBreadcrumb(`${trainingCourse.name}`, `${ENUM_ROUTES.FBIB_TRAININGCOURSES}/` + trainingCourse.id);
					})
				}}
				setDisplayDialog={setDisplayEditDialog}
				form={<TrainingCourseForm
					formik={formik}
					handleSelectExistingTrainingCourse={openTrainingCourse}
					readOnly={false}
				/>}
			/>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-trainingCourse'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.FBIB_TRAININGCOURSES}
				buttons={[<Button key={'button-new-trainingcourse'} disabled={isReader} onClick={() => { formik.setValues(copyObject(emptyTrainingCourse)); setDisplayEditDialog(true) }} className='flex  text-white' label='Ausbildungsgang anlegen' />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>}
		</div>
	</div>
}