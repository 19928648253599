import { useEffect } from 'react';
import { useFormik } from "formik";
import { useCompaniesInit, useCompaniesList } from "../Persistence/CompaniesContext"
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputText } from 'primereact/inputtext'
import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useState } from "react";

export const CompanyChooseDialog = ({ handleChange, scrollHeight }) => {
	const companiesList = useCompaniesList();
	const companiesInit = useCompaniesInit();
	const [selectedRow, setSelectedRow] = useState();
	const [products, setProducts] = useState([{ code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }, { code: 1, name: 'name1', quantity: 15 }]);

	useEffect(() => {
		companiesInit();
	}, [])

	/** formik formikChooseCompany */
	const formikChooseCompany = useFormik({
		initialValues: { companyId: null, companyName: '' },
		validate: (data) => {
			let errors = {};
			if (!data.companyId) {
				errors.companyId = 'Geben Sie den Namen des Unternehmens ein.';
			} return errors
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const onChangeInputField = (e, filterOptions) => {
		formikChooseCompany.setFieldValue('companyName', e.target.value);
		filterOptions.filter(e)
	}

	return <div className='card w-full'>
		<DataTable emptyMessage='keine Einträge gefunden' scrollable scrollDirection="both" scrollHeight="flex" showGridlines size="small"
			value={companiesList} sortOrder={1}
			filterDisplay='row' sortField='name'
			style={{ height: '300px', width: '100%' }}
			headerClassName='flex p-0 m-0'
			key={'chooseCompanyDataTable'} className='my-4 company-choose-dialog'
		>
			<Column
				style={{ flexGrow: 1, flexBasis: '100px' }}
				headerClassName="hidden"
				bodyClassName="p-1 m-0"
				filterHeaderClassName="flex p-1 border-right-none w-25rem"
				filter={true}
				filterField={'name'}
				field={'name'}
				filterPlaceholder={'Unternehmen suchen'}
				showFilterMenu={false}>
			</Column>
			<Column
				classanme='w-20rem'
				filterHeaderClassName="flex border-left-none"
				headerClassName="hidden"
				body={() => <Button>auswählen</Button>}
				showFilterMenu={false}>
			</Column>
		</DataTable>
		<DropdownChangesChecked
			className='mt-4 hidden'
			options={companiesList}
			formik={formikChooseCompany}
			filter={true}
			id={'companyId'}
			itemLabel={'name'}
			label={<div>Bitte wählen Sie ein Unternehmen aus, indem Sie den Namen des Unternehmens eingeben. <br />Sollte das Unternehmen noch nicht hinterlegt sein, drücken Sie bitte auf “Neues Unternehmen anlegen.</div>}
			onChange={handleChange}
			placeholder={'kein Unternehmen ausgewält'}
			emptyFilterMessage='kein Unternehmen mit diesem Namen gefunden -> ein neues Unternehmen wird angelegt'
			onClick={() => formikChooseCompany.setFieldValue('companyId', null)}
			filterTemplate={(options) => {
				let { filterOptions } = options;
				return (
					<div className="flex gap-2">
						<InputText
							autoFocus={true}
							onFocus={() => formikChooseCompany.setFieldValue('companyId', null)}
							value={formikChooseCompany.values.companyName}
							onKeyUp={e => (e.key === 'Enter' ? console.log('formikChooseCompany:', formikChooseCompany.values) : '')}
							onChange={e => onChangeInputField(e, filterOptions)} />
					</div>
				)
			}}
			setShowDialog={() => { }} />

	</div>
}