import { MultiSelect } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { useUserResponsibleFilter } from '../../Persistence/UserContext';
import { logDefault } from '../../Utils/logger';
import { useFBiBMode } from '../../Persistence/ConfigurationsContext';
import { getTranslation } from '../../snippets';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'ResponsibleMultiSelect';
const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
export const ResponsibleMultiSelect = ({ options, translationKey }) => {

	const fbibMode = useFBiBMode();
	const userList = useUserResponsibleFilter();
	const [listOptions, setListOptions] = useState();
	const [selected, setSelected] = useState([]);
	const [filterActiveClassName, setFilterActiveClassName] = useState('');
	const [labels, setLabels] = useState(getTranslation(NAMESPACE, 'responsibleMultiSelect', { returnObjects: true }));

	useEffect(() => {
		if (userList) {
			logDefault(CLASSNAME, 'info', 'list:' + userList.length, userList)
			const list = [{ id: 0, displayName: 'nicht gesetzt', lastname: 'n.', firstname: 'n.' }]
			setListOptions(list.concat(userList));
		}

	}, [userList])

	useEffect(() => {
		if (options.value) {
			setSelected(options.value)
			setFilterActiveClassName(options.value && options.value.length > 0 ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '')
		} else {
			setSelected()
			setFilterActiveClassName('')
		}

	}, [options.value])

	const itemTemplate = (option) => {
		return option ? option.lastname + ', ' + option.firstname : ''
	}

	const selectedTemplate = (option) => {
		const user = (listOptions || userList).find(e => e.id === option) || null
		return user ? <div className="flex text-xm ">{user.lastname + ', ' + user.firstname}</div> : '';
	}

	const onSelectionChange = (e) => {
		setSelected(e.value);
		setFilterActiveClassName(e.value && e.value.length > 0 ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '')
		options.filterApplyCallback(e.value)
	}

	const panelFooterTemplate = () => {
		const selectedItems = selected;
		const length = selectedItems ? selectedItems.length : 0;
		return (
			<div className="py-2 px-3">
				<b>{length}</b> {labels?.filter?.textSelected ? labels.filter.textSelected['label'] : ' Berater*In ausgewählt'}.
			</div>
		);
	}

	return (<>
		{(!userList || userList.length === 0) ? 'keine userlist' :
			<MultiSelect
				aria-label={labels?.responsibleMultiSelect ? labels?.responsibleMultiSelect.ariaLabel : ''}
				aria-multiselectable={true}
				panelClassName={fbibMode ? 'fbib' : ''}
				value={selected}
				options={listOptions}
				optionValue='id'
				onChange={onSelectionChange}
				itemTemplate={itemTemplate}
				filter
				filterBy='lastname,firstname'
				selectedItemTemplate={selectedTemplate}
				className={filterActiveClassName + ' multiselect-custom'}
				panelFooterTemplate={panelFooterTemplate}
				placeholder={labels?.filter?.placeholder ? labels.filter.placeholder['label'] : ''}
				aria-placeholder={labels?.filter?.placeholder ? labels.filter.placeholder.ariaLabel : ''}
				selectedItemsLabel={`${selected ? selected.length : 0} ? ${labels?.filter?.textSelected ? labels.filter.textSelected['label'] : ' Berater*In ausgewählt'}`}
				maxSelectedLabels={3} />
		}
	</>)
}

export const getResponsibleMultiSelect = (dataviewConfigKey) => {
	return (options) => <ResponsibleMultiSelect options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />
}