import { useState } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { EAACaseCreateWizard } from './EAACaseCreateWizard';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.EAACASES;
export const EAACaseDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));


	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	const columns = [
		{ filterAlias: 'col_01', field: "displayId" },
		{ filterAlias: 'col_02', field: "responsible" },
		{ filterAlias: 'col_03', field: "companyName" },
		{ filterAlias: 'col_04', field: "eaaCaseName", },
		{ filterAlias: 'col_05', field: "date" },
		{ filterAlias: 'col_06', field: "status" }
	]

	return (
		<div className='mx-auto eaa-dataView ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			{!dataviewConfig ? '' : <TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-overviews-eaacases'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full'
				ROUTE={ENUM_ROUTES.EAACASES}
				buttons={[<EAACaseCreateWizard />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
				screenreaderFields={[{ label: 'Unternehmen', field: 'companyName' }, { label: 'Fall', field: 'eaaCaseName' }, { label: 'ID', field: 'displayId' }]}
			/>}
		</div>
	)

}