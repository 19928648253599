import { ConfirmDialog } from '../Components/ConfirmDialog'
import { useState } from 'react'
import { useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { ENUM_ICONS } from '../Components/Icons';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const NAMESPACE = ENUM_NAMESPACES.CONTACTPERSONS
export const ContactPersonCard = ({ contactPerson, onEditContactPerson, onDeleteContactPerson, readonly, CONTACTPERSON_SERVICECONFIG }) => {
	const isReader = useCurrentUserIsReader();
	const configurationsList = useConfigurationsList();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	const renderContent = () => {
		const { jobDescription, phone, mobile, fax, mail } = contactPerson
		return <div className="card-content flex align-content-end flex-column h-10rem py-1 px-2 overflow-x-auto">
			<div className={jobDescription ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.JOBPROFILE} /></div><div className='col overflow-hidden'>{jobDescription}</div></div>
			<div className={phone ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.PHONE} /></div><div className='col overflow-hidden'>{phone}</div></div>
			<div className={mobile ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.MOBILE} /></div><div className='col overflow-hidden'>{mobile}</div></div>
			<div className={fax ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.FAX} /></div><div className='col overflow-hidden'>{fax}</div></div>
			<div className={mail ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.ENVELOPE} /></div><div className='col overflow-hidden'><a href={`mailto:${mail}`}>{mail}</a></div></div>
		</div>
	}

	const renderTitle = () => {
		const contactType = configurationsList[CONTACTPERSON_SERVICECONFIG.contactTypeListname].find(e => e.alias === (contactPerson.contactType.alias || contactPerson.contactType))
		return <div className="flex title">
			<div className="flex flex-grow-1 flex-column ">
				<div className='font-bold'>
					{contactPerson.lastname + ', ' + contactPerson.firstname}
				</div>
				<div>
					{contactType.display}
				</div>
			</div>
			<div className={isReader || readonly ? 'hidden' : "flex justify-content-end mt-1 gap-2 h-1rem w-3rem"}>
				<i className={ENUM_ICONS.PENCIL + 'text-white'} onClick={() => { onEditContactPerson(contactPerson.id) }} />
				<i className={ENUM_ICONS.TRASH + 'title-trash'} onClick={() => { setDisplayConfirmDialog(true) }} />
			</div>
		</div>

	}

	return (<>
		<ConfirmDialog
			NAMESPACE={NAMESPACE}
			labelKey='confirmdialog-delete'
			labelObject={contactPerson}
			handleOnClick={() => onDeleteContactPerson(contactPerson)}
			displayConfirmDialog={displayConfirmDialog}
			setDisplayConfirmDialog={setDisplayConfirmDialog} />

		<div className="eaa-card contactperson-card shadow-2">
			{renderTitle()}
			{renderContent()}
		</div>
	</>)
}