import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import { useEffect, useState } from "react";
import { ENUM_ICONS } from "../../Components/Icons";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { ToastError } from "../../Utils/ToastError";
import client, { patchData } from "../../feathers";
import { TrainingCourseForm, emptyTrainingCourse, validateTrainingCourse } from "./TrainingCourseForm";
import { useFormik } from "formik";
import { copyObject, getSortedList } from "../../snippets";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { TrainingCourseCard } from "./TrainingCourseCard";
import { useMediaQuery } from "../../Utils/useMediaQuery";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";


export const TRAININGCOURSE_SERVICECONFIG = {
	TRAINIGCENTERS: { servicename: ENUM_SERVICES.TRAININGCENTERS, displayname: 'Ausbildungsstätte', deleteMessage: 'von der Ausbildungsstätte', editMessage: 'in der Ausbldungsstätte' },
	COMPANY: { servicename: ENUM_SERVICES.COMPANIES, displayname: 'Unternehmens', deleteMessage: 'vom Unternehmen', editMessage: 'des Unternehmens' },
	FBIBPERSONS: { servicename: ENUM_SERVICES.FBIBCASEPERSONS, displayname: 'Fallperson', deleteMessage: 'von der Fallperson', editMessage: 'der Fallperson' },
	JOBOPENING: { servicename: ENUM_SERVICES.JOBPROFILES, displayname: 'Stellenprofil', deleteMessage: 'vom Stellenprofil', editMessage: 'vom Stellenprofil' },
	FBIBJOBOPENING: { servicename: ENUM_SERVICES.FBIBJOBPROFILES, displayname: 'Stellenprofil', deleteMessage: 'vom Stellenprofil', editMessage: 'vom Stellenprofil' },
}

const NAMESPACE = ENUM_NAMESPACES.TRAININGCOOURSES
export const TrainingCourseSheet = ({ serviceConfig, trainingCoursesList, readonly, parentId, updateParent, trainingCourseSelected, maxHeight }) => {

	const screenSize = useMediaQuery()
	const [trainingCourses, setTrainingCourses] = useState([])
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [displayTrainingCourseDialog, setDisplayTrainingCourseDialog] = useState();
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		if (trainingCoursesList) {
			const list = getSortedList(trainingCoursesList, 'name').map((trainingCourse) => {
				trainingCourse.sortCard = (trainingCourse.id === (trainingCourseSelected ? trainingCourseSelected.id : null))
				return trainingCourse
			})
			setTrainingCourses(getSortedList(list, 'sortCard', true))
		}

	}, [trainingCoursesList, trainingCourseSelected])

	const onTrainingCourseDelete = async () => {
		const trainingCourseIds = trainingCoursesList ? trainingCoursesList.filter(e => e.id !== formik.values.id).map(e => e.id) : [];
		const data = { method: 'updateTrainingCourseAssociation', service: serviceConfig.servicename, objectId: parentId, trainingCourseIds };
		await client.service(ENUM_SERVICES.UTILS).create(data).then(async () => {
			await updateParent()
		}).catch((error) => {
			setToastParams({ title: 'Asbildungsgang konnte nicht gelöscht werden', message: error.message })
		});

	}

	const onPatchTrainingCourse = async () => {
		await patchData(ENUM_SERVICES.TRAININGCOURSE, formik.values).then(async (trainingcourse) => {
			const trainingCourseIds = trainingCoursesList ? trainingCoursesList.map(e => e.id) : [];
			const hasTrainingCourseId = trainingCoursesList.find(e => e.id === trainingcourse.id)
			if (!hasTrainingCourseId) {
				trainingCourseIds.push(trainingcourse.id);
				const data = { method: 'updateTrainingCourseAssociation', service: serviceConfig.servicename, objectId: parentId, trainingCourseIds };
				await client.service(ENUM_SERVICES.UTILS).create(data).then(async () => {
					await updateParent()
				})
			} else {
				await updateParent()
			}
		})
	}

	const onTrainingCourseEdit = (trainingCourse) => {
		formik.setValues(trainingCourse || copyObject(emptyTrainingCourse))
		setDisplayTrainingCourseDialog(true)
	}

	const confirmTrainingCourseDelete = async (trainingCourse) => {
		formik.setValues(trainingCourse || copyObject(emptyTrainingCourse))
		setDisplayConfirmDialogDelete(true)
	}


	const formik = useFormik({
		initialValues: copyObject(emptyTrainingCourse),
		validate: validateTrainingCourse,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderTrainingCourses = () => {
		const height = screenSize[0][1] - 670
		return (!trainingCourses || trainingCourses.length === 0) ? 'keine' : <div className='flex flex-column gap-1 w-full overflow-y-auto' style={{ maxHeight: height + 'px' }}> {trainingCourses.map((trainingCourse) => {
			return <TrainingCourseCard
				key={'relatedusercard_' + trainingCourse.id}
				trainingCourse={trainingCourse}
				onDeleteHandler={confirmTrainingCourseDelete}
				onEditHandler={onTrainingCourseEdit}
				highlightCard={trainingCourseSelected && (trainingCourseSelected.id === trainingCourse.id)}
				readonly={readonly} />
		})}</div>
	}

	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className='flex font-bold mb-2'>Ausbildungsgänge</div>
			<div className='flex justify-content-between border-bottom-1'>
				<div className='flex font-medium  mb-2 '>Ausbildungsgänge </div>
				<div className='flex gap-3'>
					<div className={readonly ? 'hidden' : 'flex w-1rem'} onClick={() => onTrainingCourseEdit(null)}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-remove'
				labelObject={{ name: formik?.values.name, deleteMessage: serviceConfig.deleteMessage }}
				handleOnClick={onTrainingCourseDelete}
				displayConfirmDialog={displayConfirmDialogDelete}
				setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<EditDialog
				form={<TrainingCourseForm formik={formik} key='trainingcourse-form' />}
				formik={formik}
				patchForm={onPatchTrainingCourse}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog-choose'}
				setDisplayDialog={setDisplayTrainingCourseDialog}
				displayDialog={displayTrainingCourseDialog}
			/>
		</>
	}

	return <>
		{renderDialogs()}
		<Card className="trainingcoursesheet card-sheet  border-none mb-3" header={renderHeader}>
			{renderTrainingCourses()}
		</Card>
	</>

}