import { format } from "date-fns";
import { Column } from 'primereact/column';
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Badge } from 'primereact/badge';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { useDataViewFilter, useDataViewFilterUpdate, useDataViewMounted, useDataViewPagination, useDataViewPaginationUpdate, useDataViewSort, useDataViewSortUpdate } from "../Persistence/DataViewFilterContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { useTaskareasList } from "../Persistence/TaskareasContext";
import { logDefault } from "../Utils/logger";
import { getMultiTagFilterElement, MultiTagFilterElement } from "./FilterTemplates/MultiTagFilterElement";
import { getMultiSelectFilterElement, MultiSelectFilterElement } from "./FilterTemplates/MultiSelectFilterElement";
import { ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "./Icons";
import { OpenEye } from "./OpenEye";
import { getResponsibleMultiSelect, ResponsibleMultiSelect } from "./FilterTemplates/ResponsibleMultiSelect";
import { Button } from "primereact/button";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog";
import { patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { InputText } from "primereact/inputtext";
import { useStrgPressed } from "../Persistence/StrgPressed";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getSingleTagFilterElement } from "./FilterTemplates/SingleTagFilterElement";
import { getDateFilterElement } from "./FilterTemplates/DateRangeFilterElement";
import { getListFilterElement } from "./FilterTemplates/ListFilterElement";
import { getTextFilterElement } from "./FilterTemplates/TextFilterElement";

const CLASSNAME = 'TemplateDataView'
const columnLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ'];
export const TemplateDataView = ({ dataTableList, columns, showOpenLinkColumn, DATAVIEW_CONFIG, ROUTE, routeIdField, classNameDataTable, scrollHeight, loadingMessage, dataTableWidth, exportFunction, exportColumns, buttons, onDoubleClick }) => {
	const formatDate = useFormatDate();
	const ctlPressed = useStrgPressed();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const dataViewMounted = useDataViewMounted();
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const regionsList = useRegionsList();
	const taskareasList = useTaskareasList();
	const currentUser = useCurrentUser();
	const [screenReaderMessage, setScreenReaderMessage] = useState('')
	const [paginationFirst, setPaginationFirst] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].paginationFirst);
	const [paginationRows, setPaginationRows] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].paginationRows);
	const [currentPage, setCurrentPage] = useState(dataViewPagination[DATAVIEW_CONFIG.alias].currentPage);
	const [selectedRow, setSelectedRow] = useState();
	const [sortFunctions, setSortFunctions] = useState();
	const [multiSortMeta, setMultiSortMeta] = useState(dataViewSort[DATAVIEW_CONFIG.alias]);
	const [dataviewFilters, setDataviewFilters] = useState(dataViewFilter[DATAVIEW_CONFIG.alias]);
	const [filters, setFilters] = useState(dataViewFilter[DATAVIEW_CONFIG.alias]);
	const [filterValues, setFilterValues] = useState();
	const [mounted, setMounted] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [initPagination, setInitPagination] = useState(false);
	const [renderFunctions, setRenderFunctions] = useState();
	const [listFilterElements, setListFilterElements] = useState({ 'textFilterElement': getTextFilterElement, 'listFilterElement': getListFilterElement, 'dateFilterElement': getDateFilterElement, 'responsibleFilterElement': getResponsibleMultiSelect, 'multiSelectFilterElement': getMultiSelectFilterElement, 'singleTagFilterElement': getSingleTagFilterElement, 'multiTagFilterElement': getMultiTagFilterElement });
	const [filteredData, setFilteredData] = useState();
	const [showExportButton, setShowExportButton] = useState(false);
	const [filtersActive, setFiltersActive] = useState(false);
	const [displayExportConfirmDialog, setDisplayExportConfirmDialog] = useState(false);
	const cm = useRef(null);
	const dt = useRef(null);

	useEffect(() => {
		if (exportFunction && dt.current && dataLoaded) {
			const keys = Object.getOwnPropertyNames(filters)
			const filter = filters[keys[0]]
			dt.current.filter(filter.value, filter, filter.matchMode)
		}
	}, [dataLoaded])

	useEffect(() => {
		setFilters(dataViewFilter[DATAVIEW_CONFIG.alias])
	}, [dataViewMounted])

	useEffect(() => {
		if (dataLoaded) {
			setFilteredData(dataTableList)
		}
	}, [dataLoaded, dataTableList])

	useEffect(() => {
		if (dataTableList && dt.current) {
			if (exportFunction && configurationsList) {
				const EXPORTROLES_CONFIG = configurationsList.eaaModules.OVERVIEWS_TEST[DATAVIEW_CONFIG.alias] || configurationsList.eaaModules.OVERVIEWS[DATAVIEW_CONFIG.alias] || { EXPORTROLES: ['NOBODY'] }
				setShowExportButton(EXPORTROLES_CONFIG.EXPORTROLES.includes(currentUser.permissions))
			}
			setDataLoaded(true);
		}
	}, [dataTableList, dt.current, configurationsList])

	useEffect(() => {
		//setListFilterElements({ 'listFilter': listFilterElement, 'dateFilter': dateFilterElement, 'singleTagFilter': singleTagFilterElement, 'multiTagFilterElement': multiTagFilterElement, 'responsibleFilterElement': responsibleFilterElement, 'multiSelectFilterElement': multiSelectFilterElement })
		setRenderFunctions({ 'renderDate': renderDate, 'renderList': renderList, 'renderListIcon': renderListIcon, 'renderTag': renderTag })
		setSortFunctions({ 'sortResponsible': sortResponsible })
	}, [])

	useEffect(() => {
		if (!mounted && dataViewMounted && configurationsList && configurationsList.RegionsList && configurationsList.TaskareasList) {
			setPaginationFirst(dataViewPagination[DATAVIEW_CONFIG.alias].paginationFirst);
			setPaginationRows(dataViewPagination[DATAVIEW_CONFIG.alias].paginationRows);
			setCurrentPage(dataViewPagination[DATAVIEW_CONFIG.alias].currentPage);
			setMultiSortMeta(dataViewSort[DATAVIEW_CONFIG.alias]);
			setMounted(true)
		}
	}, [configurationsList, taskareasList, regionsList, dataViewMounted])

	useEffect(() => {
		let filterActive = false
		for (let key of Object.getOwnPropertyNames(filters || {})) {
			if (filters[key].value !== null && ('' + filters[key].value !== '')) {
				filterActive = true
			}
		}
		setFiltersActive(filterActive)
	}, [filters])

	const renderOpenLink = (rowData) => {
		return <OpenEye ROUTE={ROUTE} id={routeIdField ? rowData[routeIdField] : rowData.id} />
	}

	const onChangeFilter = (e) => {
		logDefault(CLASSNAME, 'info', 'onChangeFilter ', e.filters)
		const filters = e.filters;
		setDataViewFilter(DATAVIEW_CONFIG, filters);
		setFilters(filters);
	}
	const onChangeSort = (e) => {
		try {
			setMultiSortMeta(e.multiSortMeta)
			setDataViewSort(DATAVIEW_CONFIG, e.multiSortMeta)
		} catch (error) {
			console.error('Filtererror:', error)
		}
	}
	const onChangePaginator = (event) => {
		if (!initPagination) {
			event.first = DATAVIEW_CONFIG.pagination.paginationFirst
		}
		setInitPagination(true);
		setPaginationFirst(event.first);
		setPaginationRows(event.rows);
		setCurrentPage(event.page);
		logDefault(CLASSNAME, 'info', DATAVIEW_CONFIG.alias + ' onChangePaginator: paginationFirst:' + paginationFirst, event)

		setDataViewPagination(DATAVIEW_CONFIG, { paginationFirst: event.first, paginationRows: event.rows, currentPage: event.page })
	}

	const sortResponsible = (event) => {
		let data = [...dataTableList];
		return data;
	}

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_EAA-DOQ_' + fileName + EXCEL_EXTENSION);
			}
		});
	}
	const generateExport = useCallback(() => {
		patchData(ENUM_SERVICES.DATAEXPORTS, { name: DATAVIEW_CONFIG.alias, permissions: currentUser.permissions, filter: filters, count: filteredData.length })
		const data = filteredData.map(exportFunction)
		import('xlsx').then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(data);
			worksheet['!autofilter'] = { ref: `A1:${columnLetters[exportColumns ? exportColumns - 1 : 6]}1` };
			const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
			const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
			saveAsExcelFile(excelBuffer, DATAVIEW_CONFIG.exportFilename);
		});
	}, [filteredData])

	const removeAllFilter = () => {
		const emptyFilter = {}
		for (let key of Object.getOwnPropertyNames(filters)) {
			const filter = filters[key]
			filter.value = null
			emptyFilter[key] = filter
		}
		setDataViewFilter(DATAVIEW_CONFIG.filtername, emptyFilter);
		setFilters(emptyFilter)
	}

	const menuModel = [
		{ label: 'Öffnen', icon: ENUM_ICONS.EYE, command: () => addBreadcrumb(``, `${ROUTE}/` + selectedRow.id) },
		{ label: 'In neuem Tab öffnen', icon: ENUM_ICONS.EYE, command: () => window.open(window.location.href + '/' + selectedRow.id, '_blank') },
	];

	const setFilterValue = (field, value) => {
		const _filterValues = { ...filterValues };
		_filterValues[field] = value;
		setFilterValues(_filterValues);
	}

	const getFilterElement = (options, columnAlias) => {
		const filterELement = filters[columnAlias] && filters[columnAlias].filterElement ? filters[columnAlias].filterElement : null;
		const filterElementFunction = listFilterElements[filterELement] || listFilterElements['textFilterElement']
		return filterElementFunction(options, { alias: '' })
	}

	const renderTag = (rowData, column) => {
		const filterField = column.column.props.filterField
		const values = Array.isArray(rowData[column.field]) ? rowData[column.field] : [rowData[column.field]]
		const list = configurationsList[dataviewFilters[filterField].filterListName] ? configurationsList[dataviewFilters[filterField].filterListName] : [];
		return values.map(value => {
			const alias = value.constructor === Object ? value.alias : value;
			const option = list.find(entry => entry.alias === alias);
			const style = option && option.backcolor ? { color: '#' + option.color, backgroundColor: '#' + option.backcolor, borderColor: '#' + option.color, borderStyle: 'solid', borderWidth: option.backcolor.toLowerCase() === 'ffffff' ? '1px' : '0px' } : {};
			const className = 'px-3 mx-1' + (option && option.backcolor ? '' : (option ? 'text-white color-tag-' + option.color : ''));
			return <Tag key={'tag_' + rowData.id + '_' + alias} style={style} className={className} rounded value={option ? (option.datatable || option.alias) : '---'} />
		})
	}

	const renderDate = (rowData, column) => { return (rowData[column.field] ? formatDate(rowData[column.field]) : '---') }
	const renderListIcon = (rowData, column) => {
		const filterField = column.column.props.filterField
		const columnConfig = dataviewFilters[filterField]
		const list = configurationsList[columnConfig.filterListName] ? configurationsList[columnConfig.filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias);
		const badge = (entry && entry.functionBadge && entry.functionBadge(rowData)) ? <Badge severity='danger' className='fa-badge' /> : '';
		return entry ? (entry.icon ? <i className={'flex w-full justify-content-center ' + entry.icon} /> : <Icon ENUM_AWESOMEICON={entry.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className={'flex w-full justify-content-center '} badge={badge} />) : rowData[column.field];
	}
	const renderList = (rowData, column) => {
		const filterField = column.column.props.filterField
		const list = configurationsList[dataviewFilters[filterField].filterListName] ? configurationsList[dataviewFilters[filterField].filterListName] : [];
		const entry = list.find(e => rowData[column.field] === e.alias)
		return entry ? entry.datatable : rowData[column.field];
	}

	const getDoubleClickCommand = (e) => {
		if (onDoubleClick) {
			onDoubleClick(e.data)
		} else if (!showOpenLinkColumn) {
			console.log('KEINE AKTION')
		} else {
			const id = routeIdField ? e.data[routeIdField] : e.data.id;
			if (id) {
				if (ctlPressed) {
					const url = window.location.href.replace(ENUM_ROUTES.OVERVIEWS, ROUTE)
					window.open(url + '/' + id, '_blank', { rel: "noopener noreferrer" });
				} else {
					addBreadcrumb(``, `${ROUTE}/` + (routeIdField ? e.data[routeIdField] : e.data.id))
				}
			}
		}
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 },
				{ label: 100, value: 100 },
				{ label: 200, value: 200 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				<span >
					{options.first} - {options.last} von {options.totalRecords}
				</span>
				<span style={{ display: 'none', marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
					Seite {currentPage + 1}
				</span>
			</div>
			)
		}
	};

	const onValueChanged = (data) => {
		setFilteredData(data)
	}

	return (
		<div className={'flex flex-column overflow-x-auto justify-content-center select-none ' + (classNameDataTable || 'eaa-dataView')} >
			<div aria-live="polite" className="screen-reader-only">{screenReaderMessage}</div>
			<div className={"flex justify-content-end flex-wrap card-container gap-2 my-2"}>
				<div className={"flex flex-grow-1 mb-2"}>
					<div className={dataTableList === null ? 'hidden' : "flex align-self-center justify-content-left"}>
						<Button disabled={!filtersActive} icon={'pi pi-filter-slash'} label="Alle Filter zurücksetzen" onClick={removeAllFilter} />
					</div>
				</div>
				<div className={!showExportButton || dataTableList === null ? 'hidden' : "flex align-self-center justify-content-left mb-2"}>
					<Button icon={ENUM_ICONS.EXPORT} label="Als Excel-File exportieren" onClick={() => setDisplayExportConfirmDialog(true)} />
				</div>
				{(buttons || []).map((b) => <div className="flex align-self-center justify-content-left mb-2" key={'div-' + b.key}>{b}</div>)}
			</div>
			<div className={loadingMessage && !dataTableList ? "flex justify-content-end flex-wrap card-container" : 'hidden'}>
				{loadingMessage}
			</div>
			<div className={!dataTableList ? 'hidden' : "flex card flex md:inline-flex overflow-x-auto "} style={{ width: dataTableWidth || '1480px' }} >
				<ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
				<ConfirmDialog
					NAMESPACE={ENUM_NAMESPACES.FORMELEMENTS}
					labelKey='confirmdialog-dataview-export'
					displayConfirmDialog={displayExportConfirmDialog}
					setDisplayConfirmDialog={setDisplayExportConfirmDialog}
					key='display-export-confirm-dialog'
					style={{ width: '800px' }}
					className={' '}
					handleOnClick={generateExport} />

				{!mounted ? '' :
					<DataTable emptyMessage={!dataTableList && !loadingMessage ? 'Daten werden geladen.' : (loadingMessage ? '' : 'keine Einträge gefunden')}
						scrollHeight={scrollHeight || 'calc(100vh - 220px)'}
						scrollable scrollDirection="both"
						className='w-full template-dataview'
						rowHover
						showGridlines
						size="small"
						responsiveLayout="scroll"
						dataKey="id"
						stripedRows
						value={dataTableList}
						selection={selectedRow}
						removableSort
						onSort={onChangeSort}
						sortMode="multiple"
						multiSortMeta={multiSortMeta}
						filters={filters}
						filterDisplay='row'
						onFilter={onChangeFilter}
						paginator
						paginatorTemplate={paginatorTemplate}
						first={paginationFirst}
						rows={paginationRows}
						onPage={onChangePaginator}
						contextMenuSelection={selectedRow}
						ref={dt}
						onValueChange={onValueChanged}
						onContextMenuSelectionChange={e => setSelectedRow(e.value)}
						onRowDoubleClick={getDoubleClickCommand}
						onContextMenu={e => { if (showOpenLinkColumn) { cm.current.show(e.originalEvent) } }}>

						{/** Tabellenspalten */}
						{columns.map((column) => {
							const filterField = column.filterAlias
							return (<Column
								style={column.style}
								key={DATAVIEW_CONFIG.alias + '-' + (column.key || column.field)}
								className={column.className}
								sortable={column.sortable}
								sortField={column.sortField ? column.sortField : null}
								filter={column.filter}
								filterField={filterField}
								field={column.field}
								filterPlaceholder={dataviewFilters[filterField] ? dataviewFilters[filterField].filterPlaceholder : null}
								//filterElement={dataviewFilters[filterField] && dataviewFilters[filterField].filterElement ? listFilterElements[dataviewFilters[filterField].filterElement] : textFilterElement}
								filterElement={(options) => getFilterElement(options, filterField)}
								onFilterClear={() => setFilterValue(column.filter, null)}
								body={renderFunctions[column.body] ? renderFunctions[column.body] : column.body}
								header={column.header}
								align={column.align}
								showFilterMenu={false}>
							</Column>)
						})}
						{showOpenLinkColumn ? <Column style={{ width: '40px' }} className='open-icon justify-content-center p-0' body={renderOpenLink}></Column> : null}
					</DataTable>}
			</div>
		</div>
	)
}
