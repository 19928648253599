import { Dropdown } from "primereact/dropdown"
import { getTranslation } from "../../snippets";
import { ENUM_AWESOMEICON_SIZE, Icon } from "../Icons";
import { logDefault } from "../../Utils/logger";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { useConfigurationsList, useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { useEffect, useState } from "react";

const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
const CLASSNAME = 'listItemTemplate'
const listItemTemplate = (option) => {
    return <div className='flex w-full  align-items-center'>{option.icon ? <i className={option.icon + ' pr-1 w-2rem'} /> : (option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : '')}{option.datatable}</div>
}
const listValueTemplate = (option, placeholder) => {
    return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' w-2rem '} /> : (option && option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : ' ')}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
}

const ListFilterElement = ({ options, translationKey }) => {
    const fbibMode = useFBiBMode();
    const configurationsList = useConfigurationsList();
    const [listOptions, setListOptions] = useState([]);
    const [className, setClassName] = useState(options.filterModel && options.filterModel.filterElementClassName ? options.filterModel.filterElementClassName : '');
    const [labels, setLabels] = useState(getTranslation(NAMESPACE, translationKey, { returnObjects: true }));

    useEffect(() => {
        setListOptions(options.filterModel ? configurationsList[options.filterModel.filterListName] : [])
    }, [configurationsList, options?.filterModel])

    return !listOptions ? null : <Dropdown
        value={options.value}
        className={className + (options.value ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '')}
        panelClassName={fbibMode ? 'fbib p-2' : 'p-2'}
        optionLabel="datatable"
        optionValue="alias"
        options={listOptions}
        onChange={(e) => { options.filterApplyCallback(e.value) }}
        itemTemplate={listItemTemplate}
        valueTemplate={(option) => listValueTemplate(option, labels?.placeholder?.label)}
        aria-placeholder={labels?.placeholder ? labels.placeholder.ariaLabel : ''}
        placeholder={labels?.placeholder?.label}
    />
}

export const getListFilterElement = (dataviewConfigKey) => {
    return (options) => <ListFilterElement options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />
}