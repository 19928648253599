import { Button } from 'primereact/button';
import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useCurrentUser, useCurrentUserIsReader } from '../Persistence/CurrentUserContext';
import { CompanyForm, emptyCompany, validateCompany } from './CompanyForm';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useFormik } from 'formik';
import { openCompany, patchCompany } from './CompanyManager';
import { copyObject, getTranslation } from '../snippets';
import { useConfigurationsList, useDataviewsConfig, useFBiBMode } from '../Persistence/ConfigurationsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useLocation } from 'react-router-dom';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.COMPANIES;
const NAMESPACE = ENUM_NAMESPACES.COMPANIES
export const CompaniesDataview = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const addBreadcrumb = useBreadcrumbsAdd();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReader();
	const fbib = useFBiBMode();
	const configurationsList = useConfigurationsList();
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const location = useLocation()

	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}

	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const columns = [
		{ filterAlias: 'col_01', field: "displayId" },
		{ filterAlias: 'col_02', field: "name" },
		{ filterAlias: 'col_03', field: "responsible" },
		{ filterAlias: 'col_04', field: "employees", align: 'right' },
		{ filterAlias: 'col_05', field: "employeesSbM", align: 'right' },
		{ filterAlias: 'col_06', field: "quote", align: 'right', body: (rowData) => { return (rowData.quote / 100).toLocaleString("de-DE", { style: "percent", minimumFractionDigits: 1 }) }, },
		{ filterAlias: 'col_07', field: "createdAt", },
		{ filterAlias: 'col_08', field: "region.name", body: (rowData) => rowData['region'].name },
		{ filterAlias: 'col_09', field: "active" }
	]

	return (
		<div className='mx-auto eaa-dataView select-none ' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<EditDialog
				displayDialog={displayCompanyDialog}
				formik={formikCompany}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				patchForm={async () => {
					const company = { ...formikCompany.values, origin: (fbib ? 'FBIB' : 'EAA') }
					await patchCompany(company, currentUser).then((company) => {
						addBreadcrumb(`${company.name}`, `${fbib ? ENUM_ROUTES.FBIB_COMPANIES : ENUM_ROUTES.COMPANIES}/` + company.id);
					})
				}}
				setDisplayDialog={setDisplayCompanyDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={(id) => { formikCompany.setTouched({}); openCompany(id, addBreadcrumb, fbib) }}
				/>}
			/>
			<TemplateDataViewPagination
				showOpenLinkColumn={true}
				key='datatable-companies'
				scrollHeight={'calc(100vh - 260px)'}
				classNameDataTable='flex w-full companies-dataview'
				ROUTE={fbib ? ENUM_ROUTES.FBIB_COMPANIES : ENUM_ROUTES.COMPANIES}
				buttons={[<Button
					key={'button-new-company'}
					disabled={isReader}
					onClick={() => { formikCompany.setValues(copyObject(emptyCompany)); setDisplayCompanyDialog(true) }}
					className='flex text-white'
					aria-label={getTranslation(ENUM_NAMESPACES.DATAVIEWS, `button-new-company.ariaLabel`)}
					label={getTranslation(ENUM_NAMESPACES.DATAVIEWS, `button-new-company.label`)} />]}
				columns={columns}
				dataTableWidth='100%'
				dataviewConfig={dataviewConfig}
			/>
		</div>
	)

}