import { SelectButton } from 'primereact/selectbutton';
import { classNames } from "primereact/utils"

export const SelectButtonChecked = ({ id, label, formik, className, readonly, handleBlur }) => {

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[id].label}</small>;
	};

	const onChange = (e) => {
		formik.handleBlur(e);
		formik.handleChange(e)
	}

	const onBlur = (e) => {
		formik.handleBlur(e);
		if (handleBlur) { handleBlur(e) }
	}

	return (
		<div className="field col mr-3">
			<div className='flex w-full'>
				<label htmlFor={id} className='flex flex-grow-1'>{label}</label>
				<SelectButton
					key={'selectbutton-' + id}
					inputId={'selectbutton-' + id}
					id={id}
					value={formik.values[id]}
					readOnly={readonly}
					unselectable={false}
					onBlur={onBlur}
					onChange={onChange}
					options={[{ display: 'ja', value: 1 }, { display: 'nein', value: 0 }]}
					optionLabel='display'
					optionValue='value'
					className={classNames({ 'p-invalid': isFormFieldValid(id) }) + ' flex fbib-selectbutton ' + (className || '')} />


			</div>
			{getFormErrorMessage(id)}
		</div>
	)

}

