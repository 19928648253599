import { parseISO, startOfDay } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useEffect, useRef, useState } from "react";
import { CalendarChangesChecked } from '../Components/CalendarChangesChecked';
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { EditorChangesChecked } from '../Components/EditorChangesChecked';
import { InputTextareaChangesChecked } from '../Components/InputTextareaChangesChecked';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useOrganizationsSelectionList } from '../Persistence/OrganizationsContext';
import { useUserList } from '../Persistence/UserContext';
import { InputChangesChecked } from '../Components/InputChangesChecked';
import { useAssociatedContactsSelectionList } from '../Persistence/AssociatedContactsContext';
import { getAssociatedContact } from '../AssociatedContacts/AssociatedContactsManager';
import { RadioButton } from 'primereact/radiobutton';
import { logDefault } from '../Utils/logger';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const NAMESPACE = ENUM_NAMESPACES.CASEEVENTS
export const CaseEventsForm = ({ formik, caseEventsParent, handleCaseEventUpdate }) => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const associatedContactsList = useAssociatedContactsSelectionList();
	const organizationsSelectionList = useOrganizationsSelectionList();
	const userList = useUserList();
	const [organization, setOrganization] = useState(null);
	const [associatedContactId, setAssociatedContactId] = useState(null);
	const [transferKind, setTransferKind] = useState(0)
	const [organizationUserList, setOrganizationUserList] = useState([])
	const [caseEventClassifierStructure, setCaseEventClassifierStructure] = useState([]);
	const [selectedClassifiers, setSelectedClassifiers] = useState([]);
	const [editmode, setEditmode] = useState(false);
	const [mounted, setMounted] = useState(false);
	const [eventOptions, setEventOptions] = useState([]);

	useEffect(() => {
		logDefault('associatedContactsList', 'info', associatedContactsList.length)
	}, [organizationsSelectionList, associatedContactsList])

	useEffect(() => {
		const tempList = organization && userList ? userList.filter(entry => entry.active === 1 && entry.organizationId === organization.id) : userList;
		setOrganizationUserList(tempList);
		formik.setFieldValue('reminderUser', null)
	}, [organization, userList])

	useEffect(() => {
		if (transferKind === 0 && formik.values.associatedContactId) {
			formik.setFieldValue('transferOrganization', '');
			formik.setFieldValue('transferUser', '');
			formik.setFieldValue('associatedContactId', null);
		}
	}, [transferKind])

	useEffect(() => {
		if (formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE') {
			setEventOptions(configurationsList.EventTypeList.filter((e) => e.alias === 'CASETRANSFER' || e.alias === 'CASERECEIVE'))
		} else if (formik.values.eventType.alias === 'INTERN') {
			setEventOptions(configurationsList.EventTypeList.filter((e) => e.alias === 'INTERN'))
		} else {
			const hasContact = !caseEventsParent ? undefined : caseEventsParent.find(e => e.eventType.alias === "CONTACT")
			setEventOptions(configurationsList.EventTypeList.filter((e) => (hasContact ? ['CONTACT', 'CONTACTRESULT', 'SUSTAINABILITY'] : ['CONTACT']).includes(e.alias)))
		}
	}, [])

	useEffect(() => {
		if (formik.values.associatedContactId) {
			getAssociatedContact(formik.values.associatedContactId).then((associatedContact) => {
				formik.setFieldValue('transferOrganization', associatedContact.organization.name)
				formik.setFieldValue('transferUser', associatedContact.name)
			})
		}
	}, [formik.values.associatedContactId])

	useEffect(() => {
		setCaseEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)))
		setSelectedClassifiers([]);
		if (formik.values) {
			setSelectedClassifiers(formik.values.caseEventClassifiers || []);
			let eventDate = (formik.values.eventDate == null || ('' + formik.values.eventDate) === 'Invalid Date') ? new Date() : formik.values.eventDate;
			eventDate = ('' + parseISO(eventDate)) === 'Invalid Date' ? eventDate : parseISO(eventDate);
			formik.setFieldValue('eventDate', eventDate);
			formik.setFieldValue('eventTime', eventDate)
			setEditmode(!formik.values.id)
		} else if (!caseEventsParent || caseEventsParent.length === 0) {
			const newEvent = { ...emptyCaseEvent, eventType: configurationsList.EventTypeList.find(entry => entry.alias === 'CONTACT'), userId: currentUser.id }
			formik.setValues(newEvent);
			setEditmode(true)
		} else {
			formik.setValues({ ...emptyCaseEvent, userId: currentUser.id });
			handleCaseEventUpdate(formik.values)
		}
		setMounted(true);
	}, [])

	const onClassifierNumberChanged = (e, item) => {
		const count = e.value;
		const values = selectedClassifiers.slice()
		const selectedClassfier = values.find(entry => entry.caseEventClassifier === item.caseEventClassifier)
		if (selectedClassfier) {
			selectedClassfier.active = count > 0 ? 1 : 0
			selectedClassfier.count = count;
		} else if (count > 0) {
			values.push({ ...item, active: 1, count, version: 1 })
		}
		formik.setFieldValue('caseEventClassifiers', values)
		setSelectedClassifiers(values)

	}

	const onClassifierRadioButtonChanged = (e) => {
		const values = selectedClassifiers.slice()

		for (let classifier of values) {
			const groupWith = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === classifier.caseEventClassifier).groupWith
			if (groupWith === e.value.groupWith) {
				classifier.active = 0
				classifier.count = 0
			}
		}
		const selectedClassfier = values.find(entry => entry.caseEventClassifier === e.value.caseEventClassifier)
		if (selectedClassfier) {
			selectedClassfier.active = e.checked ? 1 : 0
			selectedClassfier.count = e.checked ? 1 : 0
		} else if (e.checked) {
			values.push({ ...e.value, active: 1, count: 1, version: 1 })
		}

		formik.setFieldValue('caseEventClassifiers', values)
		setSelectedClassifiers(values)
	}

	const onClassifierCheckboxChanged = (e) => {
		const values = selectedClassifiers.slice()
		const selectedClassfier = values.find(entry => entry.caseEventClassifier === e.value.caseEventClassifier)
		if (selectedClassfier) {
			selectedClassfier.active = e.checked ? 1 : 0
			selectedClassfier.count = e.checked ? 1 : 0
		} else if (e.checked) {
			values.push({ ...e.value, active: 1, count: 1, version: 1 })
		}
		formik.setFieldValue('caseEventClassifiers', values)
		setSelectedClassifiers(values)
	}

	const associatedContactOptionTemplate = (option) => {
		return (
			<div className="flex m-0 p-0">
				<div className='col-7 m-0 p-0 white-space-nowrap overflow-hidden text-overflow-ellipsis pr-2'>{option.organization}</div>
				<div className='col-5 m-0 p-0 white-space-nowrap overflow-hidden text-overflow-ellipsis '>{option.name}</div>
			</div>
		);
	}

	const renderEventClassifiers = () => {
		const eventClassifierStructure = !formik.values.eventType ? [] : caseEventClassifierStructure.filter(entry => entry.eventType.includes(formik.values.eventType.alias))

		return eventClassifierStructure.map((structure) => {
			return <div key={'classifiers_' + structure.order}>
				<div className={structure.headline === '' ? 'hidden' : 'card'}>
					<h4>{structure.headline}</h4>
				</div>

				<div className={structure.align === 'H' ? 'card flex flex-row flex-wrap gap-4 row-gap-1' : 'card'}>
					{structure.items.map((item) => {
						const selectedClassifier = selectedClassifiers.find((entry) => item.caseEventClassifier === entry.caseEventClassifier)
						if (selectedClassifier) {
							item.count = selectedClassifier.count
							item.id = selectedClassifier.id
						}

						const checked = selectedClassifier && selectedClassifier.count === 1 && selectedClassifier.active === 1 ? true : false;
						const disabled = item.readonly === 1;
						const hide = item.hide === 1;

						return <div className={hide || disabled ? 'hidden' : 'field field-checkbox' + (item.fieldType !== 'RADIOBUTTON' ? '' : ' mb-1 mr-3')} key={'classifiers_' + item.id + '_' + item.caseEventClassifier}>
							{item.fieldType !== 'NUMBER' ? '' : <InputNumber size={3} disabled={disabled} min={0} inputId={"classifier_" + item.caseEventClassifier} value={item.count} onValueChange={(e) => { onClassifierNumberChanged(e, item) }} />}
							{item.fieldType !== 'RADIOBUTTON' ? '' : <RadioButton autoFocus={true} disabled={disabled} tabIndex={'4'} id={item.caseEventClassifier} key={item.caseEventClassifier} inputId={item.caseEventClassifier} name="eventClassifier" value={item} onChange={onClassifierRadioButtonChanged} checked={checked} />}
							{item.fieldType !== 'CHECKBOX' ? '' : <Checkbox autoFocus={true} disabled={disabled} tabIndex={'4'} id={item.caseEventClassifier} key={item.caseEventClassifier} inputId={item.caseEventClassifier} name="eventClassifier" value={item} onChange={onClassifierCheckboxChanged} checked={checked} />}
							<label htmlFor={item.caseEventClassifier} className={item.fieldType === 'HEADLINE' ? 'm-0 mt-2' : "p-checkbox-label mt-2"}>{item.display} </label>
						</div>

					})}
				</div>
			</div>
		})
	}

	return (

		<div className='col' >
			{!mounted ? '' : <div className="formgrid grid">
				<div className="field col">
					<div className='formgrid grid mr-2'>
						<DropdownChangesChecked id='eventType' tabIndex={1}
							NAMESPACE={NAMESPACE}
							disabled={formik.values.id !== null}
							formik={formik}
							itemLabel={'display'}
							options={eventOptions}
							classNameDropdown='h-3rem p-1' />
						<CalendarChangesChecked id='eventDate' tabIndex={2} label='Datum' formik={formik} key='eventDate' className='field col-fixed w-10rem' classNameCalendar='h-3rem w-full' maxDate={new Date()} onKeyDown={(e) => { if (e.key === 'Tab') { console.log(formik.values.timeInputRef); formik.values.timeInputRef.current?.focus() } }} />
						<CalendarChangesChecked id='eventTime' tabIndex={3} label='Uhrzeit' timeOnly={true} formik={formik} key='eventTime' className='field col-fixed w-8rem' classNameCalendar='h-3rem w-full' />
					</div>
					<div className={(formik.values.eventType.alias !== 'INTERN' || formik.values.id ? 'hidden' : 'formgrid grid')}>
						<div className="field-checkbox ml-2">
							<Checkbox inputId="reminder" checked={formik.values.reminder} onChange={e => formik.setFieldValue('reminder', e.checked)} />
							<label htmlFor="reminder">Wiedervorlage erstellen</label>
						</div>
					</div>
					<div className={(formik.values.eventType.alias !== 'INTERN' || formik.values.id || !formik.values.reminder ? 'hidden' : 'formgrid grid')}>
						<div className='flex w-6'>
							<InputTextareaChangesChecked id='reminderMessage' formik={formik} label='Notiz der Wiedervorlage' autoFocus rows={5} className='w-full' setShowDialog={() => { }} />
						</div>
						<div className='flex w-6 flex-column '>
							<div className="field col mr-3">
								<label htmlFor='organization' className='block'>Auswahl Träger</label>
								<Dropdown
									aria-label='Auswahl Träger'
									id="organization"
									className='bg-white w-full'
									showClear
									filter
									name="organization"
									optionLabel="name"
									value={organization}
									options={organizationsSelectionList}
									onChange={(e) => setOrganization(e.value)} autoFocus
								/>
							</div>
							<DropdownChangesChecked
								filter={true}
								label={'Wiedervorlage für Bearbeiter*in'}
								id={'reminderUser'}
								itemLabel={'displayname'}
								formik={formik}
								options={organizationUserList}
							/>
						</div>
					</div>
					<div className={(!formik.values.id && configurationsList.eaaModules.ASSOCIATEDCONTACTS === '1' && (formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE') ? 'flex flex-column' : ' hidden')}>
						<div className="field col mr-3 p-0">
							<label htmlFor='transferKind' className='block'>Ansprechpartner*in der Übergabe/Übernahme</label>
							<Dropdown id="transferKind" className='bg-white w-full' name="transferKind" optionLabel="name" optionValue='value' value={transferKind} options={[{ name: 'Manuelle Eingabe einer Organisation und Ansprechpartner*in', value: 0 }, { name: 'Auswahl eines bestehenden Netzwerkkontaktes', value: 1 }]} onChange={(e) => setTransferKind(e.value)} />
						</div>
					</div>
					<div className={((transferKind === 1 && configurationsList.eaaModules.ASSOCIATEDCONTACTS === '1' && !formik.values.id && (formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE')) ? 'formgrid grid' : 'hidden')}>
						<DropdownChangesChecked
							className='mr-3'
							label={'Netzwerkkontakt'}
							id={'associatedContactId'}
							itemLabel={'organization'}
							panelClassName='p-2  dropdown-associated-contact-select'
							placeholder='Netzwerkkontakt wählen'
							itemTemplate={associatedContactOptionTemplate}
							valueTemplate={(option, props) => !option ? <span>{props.placeholder}</span> : ((option.organization ? option.organization + ' - ' : null) + option.name)}
							optionValue={'id'}
							formik={formik}
							filter={true}
							filterBy='organization,name'
							options={associatedContactsList} />
					</div>

					<div className={(transferKind === 0 && (formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE') ? 'formgrid grid ' : 'hidden')}>
						<InputChangesChecked formik={formik} id='transferOrganization' label={formik.values.eventType.alias !== 'CASERECEIVE' ? 'Name der empfangenden Organisation' : 'Name der abgebenden Organisation'} />
					</div>
					<div className={(transferKind === 0 && (formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE') ? 'formgrid grid ' : 'hidden')}>
						<InputChangesChecked formik={formik} id='transferUser' label={formik.values.eventType.alias !== 'CASERECEIVE' ? 'Name des Empfängers' : 'Name des Versendenden'} />
					</div>
					{renderEventClassifiers()}
					<div className="formgrid grid">
						<EditorChangesChecked id='description' formik={formik} value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label={formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE' ? 'Vermerk (Optional)' : 'Beschreibung'} required={formik.values.eventType.alias === 'CASETRANSFER' || formik.values.eventType.alias === 'CASERECEIVE' ? false : true} />
					</div>
				</div>
			</div>}
		</div>


	)
}

export const emptyCaseEvent = { id: null, version: 1, active: 1, reminderMessage: '', reminder: false, eventDate: null, eventTime: null, eventType: '', description: '', caseEventClassifiers: [], reminderUser: null, transferOrganization: '', transferUser: '', eaaCaseId: null, associatedContactId: null }

export const validateCaseEvent = (data) => {
	let errors = {};
	if (!data.eventType) {
		errors.eventType = 'Kontakt Klassifikation muss gesetzt sein';
	}
	if (!data.eventDate) {
		errors.eventDate = 'Datum muss gesetzt sein';
	}
	if (data.eventType.alias !== 'CASERECEIVE' && data.eventType.alias !== 'CASETRANSFER' && !data.description) {
		errors.description = 'Beschreibung muss ausgefüllt sein';
	}
	if (data.reminder && !data.reminderMessage) {
		errors.reminderMessage = 'Bei aktivierter Wiedervorlage muss eine Notiz eingegeben werden!';
	}
	if (data.reminder && !data.reminderUser) {
		errors.reminderUser = 'Bei aktivierter Wiedervorlage muss ein/e Bearbeiter*in eingegeben werden!';
	}
	if (data.eventType.alias === 'CASETRANSFER' && !data.transferOrganization && !data.associatedContactId) {
		errors.associatedContactId = 'Netzwerkkontakt muss eingegeben werden!';
		errors.transferOrganization = 'Bei Übergabe muss die Organisation hinterlegt sein, an die der Fall übergeben wird!';
	}
	if (data.eventType.alias === 'CASERECEIVE' && !data.transferOrganization && !data.associatedContactId) {
		errors.associatedContactId = 'Netzwerkkontakt muss eingegeben werden!';
		errors.transferOrganization = 'Bei einem eingehenden Fall muss die Organisation hinterlegt sein von der der Fall übergeben wurde!';
	}
	return errors;
}