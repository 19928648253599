import { Icon } from "../Components/Icons";
import { useBreadcrumbs } from "../Persistence/Breadcrumbs";
import { useEffect, useState } from "react";
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const navitemClassName = 'p-ripple flex flex-row  gap-2 align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors'
const NAMESPACE = ENUM_NAMESPACES.SIDEBAR
export const NavigationEntry = ({ icon, route, label, translation, onSidebarClick, showLabel, awesomeIcon, badge }) => {
	const breadcrumbs = useBreadcrumbs();


	const renderEntry = () => {
		const translationLabel = translation ? getTranslation(NAMESPACE, `${translation}.label`) : label
		const translationAriaLabel = translation ? getTranslation(NAMESPACE, `${translation}.ariaLabel`) : label
		const className = " flex mr-2 lg:mr-0 lg:text-2xl w-2rem justify-content-center p-overlay-badge navigation-entry " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? ' navigation-entry-active' : 'text-base');
		return <>
			<div role='heading'
				aria-level={1}
				aria-label={translationAriaLabel + ' zum Aktivieren drücken Sie die Eingabetaste'}
				tabIndex={0}>
				<a onClick={() => (onSidebarClick(route, icon, translation ? translationLabel : label))} className={navitemClassName} >
					{awesomeIcon ? <Icon ENUM_AWESOMEICON={awesomeIcon} className={className} /> : <i aria-hidden={true} className={icon + className} >{badge}</i>}
					<div aria-hidden={true}
						className={(showLabel ? 'flex inline' : 'hidden') + " font-semibold " + (breadcrumbs && breadcrumbs.home && breadcrumbs.home.icon === icon ? 'navigation-entry-active' : 'text-base')}>{translation ? translationLabel : label}
					</div>
				</a>
				<div className="screen-reader-only">
					{`${translationAriaLabel} zum Aktivieren drücken Sie die Eingabetaste`}
				</div>
			</div>

			<div className="screen-reader-only"></div>
		</>

	}

	return renderEntry()
}