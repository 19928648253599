import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { FieldInfo } from "../Components/FieldInfo";
import { ENUM_ICONS } from '../Components/Icons';
import { BasicSheetTitleLayout, getScreenreaderMessage } from '../Components/Layouts/BasicSheetTitleLayout';
import { OverlayPanelMenu } from '../Components/OverlayPanelMenu';
import { deleteData, patchData } from '../feathers';
import { useBreadcrumbsMinus } from '../Persistence/Breadcrumbs';
import { useConfigurationsList, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderEAA } from '../Persistence/CurrentUserContext';
import { logDefault } from '../Utils/logger';
import { ToastError } from '../Utils/ToastError';
import { hasParticipants, ProviderActivitiesForm, defaultProviderActivity, validateProviderActivity } from './ProviderActivitiesForm';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { RelatedUsersSheet } from '../RelatedUsers/RelatedUsersSheet';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { CommentsModule } from '../Components/CommentsModule';
import { ERROR_RESOURCENOTFOUND, useErrorUpdate } from '../Persistence/ErrorContext';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { getProviderActivity, getTypeAvtivityObject } from './ProviderActivitiesManager';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { ASSOCIATEDCONTACTCONFIG, AssociatedContactSheet } from '../AssociatedContacts/AssociatedContactSheet';
import { RELATEDUSERSERVICECONFIG } from '../RelatedUsers/RelatedUserManager';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';
import { getTranslation } from '../snippets';

const SERVICENAME = ENUM_SERVICES.PROVIDERACTIVITIES;
const NAMESPACE = ENUM_NAMESPACES.PROVIDERACTIVITIES;
const CLASSNAME = 'ProviderActivityData'
export const ProviderActivityData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate();
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const configurationsList = useConfigurationsList();
	const { provideractivityId } = useParams();
	const minusBreadcrumbs = useBreadcrumbsMinus();
	const [providerActivity, setProviderActivity] = useState();
	const [displayProviderActivityDialog, setDisplayProviderActivityDialog] = useState(false);
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [readonly, setReadonly] = useState(true);
	const [toastParams, setToastParams] = useState(null);

	const navigate = useNavigate();

	const providerActivityUpdate = async () => {
		if (provideractivityId) {
			return await getProviderActivity(configurationsList, provideractivityId).then((providerActivity) => {
				setReadonly(isReader)
				setProviderActivity(providerActivity);
				logDefault(CLASSNAME, 'info', 'providerActivity ', providerActivity);
				return providerActivity !== null
			}).catch((error) => {
				setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
			});
		}
	}

	useEffect(() => {
		setTimeout(async () => {
			if (!await providerActivityUpdate()) {
				errorUpdate(ERROR_RESOURCENOTFOUND.title, ERROR_RESOURCENOTFOUND.message)
				navigate('/' + ENUM_ROUTES.ERROR)
			}
		}, 100);
	}, [errorUpdate, navigate])

	const patchProviderActivity = async () => {
		await patchData(SERVICENAME, formik.values).then(async (patchedProviderActivity) => {
			await providerActivityUpdate();
		})
	}

	const onEditActivity = () => {
		formik.setValues({ ...providerActivity, endDate: providerActivity.endDate ? parseISO(providerActivity.endDate) : null, recurring: providerActivity.recurring === 1 })
		setDisplayProviderActivityDialog(true)
	}

	const deleteActivity = async () => {
		setDisplayDeleteConfirmDialog(false);
		await deleteData(SERVICENAME, providerActivity.id).then(async () => {
			minusBreadcrumbs();
		}).catch((error) => {
			setToastParams({ title: getTranslation(NAMESPACE, 'confirmdialog-delete.error.label'), message: error.message })
		})
	}
	const renderItems = () => {
		const items = [{ label: getTranslation(NAMESPACE, 'button-edit-provideractivity.label'), icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly }]
		if (currentUser.permissions === 'ADMIN' && providerActivity) {
			items.push({ label: getTranslation(NAMESPACE, 'confirmdialog-delete.button-label.label'), className: 'eaa-menuitem-delete', icon: ENUM_ICONS.CASE_DELETE, command: () => setDisplayDeleteConfirmDialog(true) })
		}
		return items;
	}
	const formik = useFormik({
		initialValues: { ...defaultProviderActivity, userId: currentUser.id },
		validate: validateProviderActivity,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const listButtons = [
		{ key: 'edit', label: getTranslation(NAMESPACE, 'button-edit-provideractivity.label'), icon: ENUM_ICONS.PENCIL, command: onEditActivity, disabled: readonly },
		{ key: 'reminder', label: getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.label`), icon: ENUM_ICONS.CLOCK, command: handleUseForReminder, disabled: readonly },
	]

	const renderContent = () => {
		return (<>
			<div className='mx-auto eaa-maincontent'>
				<div>
					<BasicSheetTitleLayout
						screenReaderMessage={getScreenreaderMessage('Trägeraktivität', providerActivity)}
						listButtons={listButtons}
						title={providerActivity.name}
						subtitle={getTranslation(NAMESPACE, 'subtitle.label', { displayId: providerActivity?.displayId, dateFormatted: formatDate(parseISO(providerActivity.createdAt)), creatorname: providerActivity.creator?.displayname })}
					/>

					<div className='flex card-container blue-container overflow-hidden grid'>
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.typeActivity' content={getTypeAvtivityObject(configurationsList, providerActivity.typeActivity).display} className='col-5' />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.date' content={providerActivity.date ? formatDate(providerActivity.date) : '---'} className='col-1' />
						<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.creator' content={!providerActivity.creator ? '' : providerActivity.creator.displayname} className='hidden col-5' />
						{hasParticipants(providerActivity.typeActivity)
							? <FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.participants' content={providerActivity.participants} className='col-1' />
							: providerActivity.typeActivity === 'COOPERATION_MEETINGS' ? '' : <FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.endDate' content={providerActivity.endDate ? formatDate(parseISO(providerActivity.endDate)) : '---'} className='col-2' />}
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.description.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: providerActivity.description }} />
					</div>
					<div className="flex flex-column mt-4 ml-2 mr-3">
						<CommentsModule
							onUpdateComment={providerActivityUpdate}
							key='provideractivityCommentsModule'
							servicename={ENUM_SERVICES.PROVIDERACTIVITYCOMMENTS}
							readonly={readonly}
							parentId={providerActivity.id}
							idFieldname='provideractivityId'
							reminderParentService={ENUM_SERVICES.PROVIDERACTIVITIES}
							comments={providerActivity.comments} />
					</div>
				</div>
			</div></>
		)
	}
	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey={'confirmdialog-delete'}
				labelObject={providerActivity}
				handleOnClick={deleteActivity}
				displayConfirmDialog={displayDeleteConfirmDialog}
				setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Trägeraktivität'
				name={providerActivity.name}
				serviceId={providerActivity.id}
				serviceName={ENUM_SERVICES.PROVIDERACTIVITIES}
				userId={currentUser.id}
			/>
			<EditDialog
				form={<ProviderActivitiesForm formik={formik} key='providerActivity-form' />}
				formik={formik}
				patchForm={patchProviderActivity}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayProviderActivityDialog}
				displayDialog={displayProviderActivityDialog}
			/>
		</>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{
				index: 0, visible: true, icon: ENUM_ICONS.CASE_USER + "fa-lg",
				component: <div className="flex flex-column row-gap-4">
					<RelatedUsersSheet readonly={readonly} key='relatedUserSheet' parentId={providerActivity.id} relatedUserServiceConfig={RELATEDUSERSERVICECONFIG.PROVIDERACTIVITY} hideDivider={true} />
					<AssociatedContactSheet readonly={readonly} associatedContactServiceConfig={ASSOCIATEDCONTACTCONFIG.PROVIDERACTIVITY} parent={providerActivity} />
				</div>
			},
		]
		return sideSheets;
	}

	return (!providerActivity ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!providerActivity ? '' : renderContent()}{!providerActivity ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={renderItems()} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={providerActivity.id}
		/>
	)
}