import { format, differenceInSeconds, differenceInMilliseconds } from "date-fns";
import logger, { logContext } from "./Utils/logger";
import { FindDataError } from "./errorClasses";
import client from "./feathers";
import i18n from './Utils/i18n';
import { ENUM_NAMESPACES } from "./Enums/ENUM_NAMESPACES";

const dateFormat = 'yyy-MM-dd';
//export const formatDate = (date) => { return format(date || new Date(), dateFormat) }
export const formatDateTime = (date: any) => { return format(date || new Date(), dateFormat + ' HH:mm') }
export const formatDate = (date: any) => { return format(date || new Date(), dateFormat) }

export const copyObject = (object: any) => {
	return JSON.parse(JSON.stringify(object))
}

export const printStopwatchTime = (title: string, start: any, millis: boolean) => {
	const difference = (millis ? differenceInMilliseconds(new Date(), start) + ' milliseconds' : differenceInSeconds(new Date(), start) + ' seconds')
	logger.info(`stopwatch: '${title}' takes ${difference}`)
}

export const getSortedList = (list: any[], fieldname: string, descending: boolean) => {

	const listSorted = list.sort((a, b) => {
		let x = a[fieldname];
		let y = b[fieldname];
		if (typeof x === 'string' || x instanceof String) { x = x.toLowerCase() }
		if (typeof y === 'string' || y instanceof String) { y = y.toLowerCase() }
		if (x < y) { return !descending ? -1 : 1 }
		if (x > y) { return !descending ? 1 : -1 }
		return 0;
	})
	return listSorted
}

export const fetchDataWithPagination = async (servicename: string, setList: Function, query: Object) => {
	logContext('fetchDataWithPagination', 'info', '########### ' + servicename + ' getData', null);
	const service = client.service(servicename);
	let $skip = 0;
	let _list: any[] = [];
	let count = 0;
	do {
		count = await service.find({ query: query ? { ...query, $skip, $limit: 50 } : { $skip, $limit: 50 } }).then((result: any) => {
			_list = _list.concat(result);
			$skip = _list.length
			return result.length;
		}).catch((error: any) => {
			console.error("Error fetchDataWithPagination service:" + servicename, error)
			throw new FindDataError("Error fetchDataWithPagination service:" + servicename, error, servicename)
		});
	} while (count > 0);
	logContext('fetchDataWithPagination', 'info', `########### ${servicename} fetchDataWithPagination found:${_list.length}`, null);
	setList(_list);
	return
}

const prefixTranslator = process.env.NODE_ENV !== "production" ? '#' : ''

const addPrefixTranslator = (o: any) => {
	for (let key of Object.getOwnPropertyNames(o)) {
		o[key] = typeof o[key] === 'object' && o[key] !== null && !Array.isArray(o[key]) ? addPrefixTranslator(o[key]) : o[key] === null ? null : prefixTranslator + o[key]
	}
	return o
}

export const getTranslation = (namespace: ENUM_NAMESPACES, key: string, substitutions?: any) => {
	let translation = i18n.t(`${namespace}:${key}`, substitutions || {});
	if (translation !== key && prefixTranslator !== '') {
		if (substitutions && substitutions.returnObjects) {
			translation = addPrefixTranslator(translation)
		} else {
			translation = prefixTranslator + translation
		}
	}
	return translation
}
