import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { useState } from 'react';
import { AssociatedContactCard } from "../AssociatedContacts/AssociatedContactCard";
import { ENUM_ICONS } from "../Components/Icons";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Card } from "primereact/card";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { patchData } from "../feathers";
import { useFormik } from "formik";
import { AssociatedContactForm, getEmptyAssociatedContact, validateAssociatedContact } from "../AssociatedContacts/AssociatedContactForm";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";


const SERVICENAME = ENUM_SERVICES.ASSOCIATEDCONTACTS;
const CLASSNAME = 'AssociatedOrganizationContactSheet'
const NAMESPACE = ENUM_NAMESPACES.ASSOCIATEDCONTACTS;
export const AssociatedOrganizationContactSheet = ({ associatedContacts, parentSetAssociatedContact, onAssociatedContactPatched, readonly, parentId }) => {

	const configurationsList = useConfigurationsList();
	const [associatedContact, setAssociatedContact] = useState();
	const [displayEditDialog, setDisplayEditDialog] = useState(false);
	const [displayConfirmDialogDelete, setDisplayConfirmDialogDelete] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	const onAssociatedContactDelete = async () => {
		await patchData(ENUM_SERVICES.ASSOCIATEDCONTACTS, { ...associatedContact, active: 0 }).then(() => {
			setAssociatedContact(null);
			parentSetAssociatedContact({})
			onAssociatedContactPatched();
		})
	}

	const onSelectAssociatedContact = (associatedContact) => {
		for (let entry of associatedContacts) {
			entry.isSelectedContact = entry.id === associatedContact.id
		}
		parentSetAssociatedContact(associatedContact)
	}

	const onEditHandler = async (associatedContact) => {
		formik.setValues(associatedContact)
		setDisplayEditDialog(true)
	}

	const patchAssociatedContact = async () => {
		const associatedContact = { ...formik.values, associatedOrganizationId: parentId, regionId: formik.values.regionId || null }
		await patchData(ENUM_SERVICES.ASSOCIATEDCONTACTS, associatedContact).then(() => {
			setDisplayEditDialog(false)
			onAssociatedContactPatched()
		})
	}

	const confirmAssociatedContactDelete = async (associatedContact) => {
		setAssociatedContact(associatedContact);
		setDisplayConfirmDialogDelete(true)
	}

	const confirmAssociatedContactAdd = async () => {
		formik.setValues(getEmptyAssociatedContact())
		setDisplayEditDialog(true)
	}

	const formik = useFormik({
		initialValues: getEmptyAssociatedContact(),
		validate: validateAssociatedContact,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});


	const renderHeader = () => {
		return <div className='flex flex-column'>
			<div className={'flex justify-content-between border-bottom-1'}>
				<div className='flex font-medium  mb-2 '>Netzwerkkontakte</div>
				<div className='flex '>
					<div className={readonly ? 'hidden' : 'flex w-1rem open-icon'} onClick={confirmAssociatedContactAdd}><i className={ENUM_ICONS.PLUS_CIRCLE} /></div>
				</div>
			</div>
		</div>
	}

	const renderAssociatedContacts = () => {
		return !associatedContacts ? 'keine' : <div className={`flex flex-column gap-1 w-full overflow-y-auto`} style={{ maxHeight: 'calc(100vh - 190px)' }}> {associatedContacts.map((associatedContact) => {
			return (associatedContact.isOrganizationEntry ? '' : <AssociatedContactCard
				key={'associatedcontactcard_' + associatedContact.id}
				associatedContact={associatedContact}
				onEditHandler={onEditHandler}
				readonly={readonly} />)
		})}</div>
	}

	return (
		<>
			<ToastError toastParams={toastParams} />
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-delete'
				labelObject={associatedContact}
				handleOnClick={onAssociatedContactDelete}
				displayConfirmDialog={displayConfirmDialogDelete}
				setDisplayConfirmDialog={setDisplayConfirmDialogDelete} />
			<EditDialog
				form={<AssociatedContactForm formik={formik} key='associatedContact-form' />}
				formik={formik}
				patchForm={patchAssociatedContact}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				setDisplayDialog={setDisplayEditDialog}
				displayDialog={displayEditDialog}
			/>
			{configurationsList.eaaModules.ASSOCIATEDCONTACTS !== '1' ? '' : <Card className={"associatedcontactsheet card-sheet"} header={renderHeader}>
				{renderAssociatedContacts()}
			</Card>}
		</>
	)

}