import { useState } from 'react';
import { ROUTES } from '../Navigation/Routes';
import { ENUM_ICONS } from '../Components/Icons';
import { useBreadcrumbsUpdate } from '../Persistence/Breadcrumbs';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig, useFormatDate } from '../Persistence/ConfigurationsContext';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';

const CLASSNAME = 'OverviewAssociatedContactsDataview'
export const OverviewAssociatedContactsDataview = () => {
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const formatDate = useFormatDate();
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_ASSOCIATEDCONTACTS));

	const handleDoubleClick = (rowData) => {
		const serviceName = rowData.serviceName
		const id = rowData.serviceId
		handleOpenAssociatedContactObject(serviceName, id)
	}
	const handleOpenAssociatedContactObject = async (serviceName, id) => {
		const route = serviceName === 'companies' ? ROUTES['COMPANIES'] : (serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : ROUTES['PROVIDERACTIVITIES'])
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}
	const renderContextMenu = (rowData) => {
		return <div className={'open-icon flex w-full h-1rem ' + (rowData.hideEdit ? 'justify-content-end' : 'justify-content-between ')}>
			{rowData.hideEdit ? '' : <i className={ENUM_ICONS.EYE} onClick={() => handleOpenAssociatedContactObject(rowData.serviceName, rowData.serviceId)} />}
		</div>
	}
	const renderDate = (rowData) => { return (rowData.millisecondsDate ? formatDate(rowData.millisecondsDate) : '---') }
	const renderResponsible = (rowData) => {
		return (rowData.responsible ? rowData.responsible.lastname + ', ' + rowData.responsible.firstname : 'n.n.')
	}
	const columns = [
		{ filterAlias: 'col_01', field: "displayName", sortable: true, filter: "name", header: "Netzwerkkontakt", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_02', field: "regionName", sortable: true, filter: "name", header: "Region", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_03', field: "serviceName", sortable: true, filter: "serviceName", header: "Art", style: { width: '110px' } },
		{ filterAlias: 'col_04', field: "displayId", sortable: true, filter: true, header: "ID", style: { width: '150px' }, },
		{ filterAlias: 'col_05', field: "name", sortable: true, filter: "name", header: "Name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_06', field: "date", sortable: true, filter: true, header: "Datum", style: { width: '180px' }, body: renderDate },
		{ filterAlias: 'col_07', field: "responsible.id", sortable: true, sortField: 'responsible.lastname', filter: true, filterField: 'responsible.id', header: "Berater*in", style: { flexGrow: 1, flexBasis: '200px' }, body: renderResponsible },
		{ filterAlias: 'col_08', style: { width: '30px' }, body: renderContextMenu },
	]

	return <div className='flex flex-column gap-2'>
		<TemplateDataViewPagination
			key='datatable-overviews-associatedcontacts'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			routeIdField='eaaCaseId'
			columns={columns}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
			onDoubleClick={handleDoubleClick} />
	</div>
}