import { useCallback, useEffect, useState } from "react";
import { getTranslation } from "../../snippets";
import { Tag } from "primereact/tag";
import { useConfigurationsList, useFBiBMode } from "../../Persistence/ConfigurationsContext";
import { ENUM_NAMESPACES } from "../../Enums/ENUM_NAMESPACES";
import { logDefault } from "../../Utils/logger";
import { Dropdown } from "primereact/dropdown";

const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
const CLASSNAME = 'SingleTagFilterElement'
const SingleTagFilterElement = ({ options, translationKey }) => {
    const configurationsList = useConfigurationsList();
    const fbibMode = useFBiBMode();
    const [listOptions, setListOptions] = useState([]);
    const [className, setClassName] = useState();
    const [labels, setLabels] = useState(getTranslation(NAMESPACE, translationKey, { returnObjects: true }));

    useEffect(() => {
        if (options.filterModel) {
            setListOptions(configurationsList[options.filterModel.filterListName]);
            setClassName(options.filterModel.filterElementClassName)
            logDefault(CLASSNAME, 'info', `${translationKey} ${className}`, listOptions)
        }
    }, [options, configurationsList])


    const renderTagFilter = useCallback((option) => {
        return <Tag
            key={'tag-' + option.id}
            className={"mr-2 text-white px-3 color-tag-" + option.color}
            rounded
            value={option.datatable}
        />
    }, [])

    return <Dropdown
        value={options.value}
        className={className}
        panelClassName={fbibMode ? 'fbib' : ''}
        optionLabel="datatable"
        optionValue="alias"
        options={listOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={renderTagFilter}
        placeholder={labels?.filter?.placeholder ? labels.filter.placeholder['label'] : ''}
        aria-placeholder={labels?.filter?.placeholder ? labels.filter.placeholder.ariaLabel : ''}
    />
}
export function getSingleTagFilterElement(dataviewConfigKey) {
    return (options) => <SingleTagFilterElement options={options} translationKey={`${dataviewConfigKey}.${options.field}`} />
}