import { useFormik } from 'formik';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from "react";
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS } from '../Components/Icons';
import { BasicSheetLayout } from '../Components/Layouts/BasicSheetLayout';
import { TemplateDataView } from "../Components/TemplateDataView";
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { getData, patchData } from '../feathers';
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useRegionsList } from '../Persistence/RegionsContext';
import { useTaskareasList } from '../Persistence/TaskareasContext';
import { useUserList } from '../Persistence/UserContext';
import { emptyUserRegionAndTaskarea, RequestDistributionUserRegionAndTaskareaForm, validateUserRegionAndTaskareaForm } from './RequestDistributionUserRegionAndTaskareaForm';
import { Tag } from 'primereact/tag';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const NAMESPACE = ENUM_NAMESPACES.REQUESTDISTRIBUTIONS
export const RequestDistributionsUserRelations = () => {
	const getDataviewConfig = useDataviewsConfig();
	const DATAVIEW_CONFIG = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.REQUESTDISTRIBUTIONS_USERMATRIX);
	const dataTableList = useUserList();
	const regionsList = useRegionsList();
	const taskareasList = useTaskareasList();
	const [displayRegionAndTaskareaDialog, setDisplayRegionAndTaskareaDialog] = useState(false);
	const [user, setUser] = useState({});
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		if (regionsList && regionsList.length > 0 && taskareasList && taskareasList.length > 0) {
			setMounted(true)
		}
	}, [dataTableList, taskareasList, regionsList])

	const handleSelectUser = async (id) => {
		await getData(ENUM_SERVICES.USERS, id).then((user) => {
			setUser(user);
			user.regions = user.regions.map(e => regionsList.find(region => e.id === region.id));
			user.taskareas = user.taskareas.map(e => taskareasList.find(taskarea => e.id === taskarea.id))
			delete user.password
			formikUserRegionAndTaskarea.setValues(user)
			setDisplayRegionAndTaskareaDialog(true);
		})
	}

	const renderEditUser = (row) => {
		return <div className="flex justify-content-center w-full" onClick={() => { handleSelectUser(row.id) }}><i className={ENUM_ICONS.PENCIL} /></div>
	}

	const columns = [
		{ field: "displayname", sortable: true, filter: "displayname", header: "Berater*innen", style: { width: '250px' }, body: null },
		{ field: "regions", sortable: true, filter: 'regions', header: "Regionen", style: { flexGrow: 1, flexBasis: '300px' }, body: (row) => <div className='flex flex-wrap'>{row.regions.map(e => <Tag className={"mb-2 mr-2 px-3"} key={'tag-region-' + e.id} rounded value={e.name} />)}</div> },
		{ field: "taskareas", sortable: true, filter: "taskareas", header: "Bereiche", style: { flexGrow: 1, flexBasis: '100px' }, body: (row) => <div className='flex flex-wrap'>{row.taskareas.map(e => <Tag className={"mb-2 mr-2 px-3"} key={'tag-taskarea-' + e.id} style={{ color: '#' + e.color, backgroundColor: '#' + e.backcolor }} rounded value={e.name} />)}</div> },

		{ style: { width: '60px' }, body: renderEditUser }
	]

	const formikUserRegionAndTaskarea = useFormik({
		initialValues: { ...emptyUserRegionAndTaskarea },
		validate: validateUserRegionAndTaskareaForm,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const patchUser = async () => {

		await patchData('users', formikUserRegionAndTaskarea.values).then(() => {
			setDisplayRegionAndTaskareaDialog(false);
		})
	}

	const renderContent = () => {
		return <div className='flex mx-auto flex-column mt-0'>

			<TemplateDataView
				columns={columns}
				classNameDataTable='flex w-full eaa-maincontent'
				dataTableList={dataTableList}
				dataTableWidth='1080px'
				DATAVIEW_CONFIG={DATAVIEW_CONFIG}
				ROUTE={ENUM_ROUTES.REQUESTDISTRIBUTION} />
			<EditDialog
				form={<RequestDistributionUserRegionAndTaskareaForm formik={formikUserRegionAndTaskarea} key='user-region-and-taskarea-form' />}
				formik={formikUserRegionAndTaskarea}
				patchForm={patchUser}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog-user'}
				setDisplayDialog={setDisplayRegionAndTaskareaDialog}
				displayDialog={displayRegionAndTaskareaDialog}
			/>
		</div>
	}

	const renderHeaderUserManual = () => {
		return <div className='flex flex-column my-0'>
			<div className='flex font-bold mt-0 mb-2'>Zuordnung testen</div>
			<div className='flex flex-column '>
				<div className='flex font-medium border-bottom-1 my-2 '>Anleitung </div>
			</div>
		</div>
	}

	const renderUserManual = () => {
		return <div className='flex flex-column'>
			{renderHeaderUserManual()}
			<div> Diese Filter dienen nur zur Probe. Um herauszufinden wer welche Anfragen automatisch zu gestellt bekommt, kann man hier mit den Filtern Region und Bereich testen. Um Änderungen vorzunehmen muss der Administrator jedoch entweder in der Nutzerverwaltung die Eigenschaften oder in den Einstellungen der Region die Zuordnungen anpassen.</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, awesomeIcon: ENUM_AWESOMEICON.QUESTIONSMARK, awnsomeSize: ENUM_AWESOMEICON_SIZE.XL, component: <Panel id='panelRequestDistributionsUserRelationsManual'>{renderUserManual()}</Panel> },
		]
		return sideSheets;
	}


	return (!mounted ? <></> :
		<BasicSheetLayout
			mainContent={renderContent()}
			sideSheets={getSideSheets()}
		/>
	)
}