import { useCallback, useEffect, useState } from "react"
import { useTabViewsIndex, useTabViewsIndexUpdate } from '../Persistence/CurrentUserContext';
import { StatisticCaseRequests } from './StatisticCaseRequests';
import { StatisticCasePersons } from './StatisticCasePersons';
import { Menubar } from 'primereact/menubar';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../Components/Icons';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { ENUM_TABVIEWS } from '../Enums/ENUM_TABVIEWS';
import { ENUM_STATISTICS } from '../Enums/ENUM_STATISTICS';
import { StatisticBIH } from './StatisticBIH';
import { StatisticCompanyContacts } from "./StatisticCompanyContacts";
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

const NAMESPACE = ENUM_NAMESPACES.STATISTIC
export const Statistic = () => {

	const menubarItemSelected = useTabViewsIndex();
	const setMenubarItemSelected = useTabViewsIndexUpdate();
	const [items, setItems] = useState(null)
	const [dataView, setDataView] = useState();

	useEffect(() => {
		const value = menubarItemSelected ? menubarItemSelected[ENUM_TABVIEWS.STATISTICS] || ENUM_STATISTICS.BIH : ENUM_STATISTICS.BIH
		setMenuItems(value)
		switch (value) {
			case ENUM_STATISTICS.BIH: setDataView(<StatisticBIH />); break;
			case ENUM_STATISTICS.CASEREQUESTS: setDataView(<StatisticCaseRequests />); break;
			case ENUM_STATISTICS.CASEPERSONS: setDataView(<StatisticCasePersons />); break;
			case ENUM_STATISTICS.COMPANYCONTACTS: setDataView(<StatisticCompanyContacts />); break;
			default: setDataView(< StatisticBIH />)
		}
	}, [menubarItemSelected])

	const setMenuItems = useCallback((enum_statistic) => {
		const items = [
			{
				label: getTranslation(NAMESPACE, 'BIH.label.tab'),
				ariaLabel: getTranslation(NAMESPACE, 'BIH.aria-label.tab'),
				icon: ENUM_ICONS.STATISTICS,
				className: ([ENUM_STATISTICS.BIH].includes(enum_statistic)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.BIH, <StatisticBIH />)
			},
			{
				label: getTranslation(NAMESPACE, 'request.label.tab'),
				ariaLabel: getTranslation(NAMESPACE, 'request.aria-label.tab'),
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.CASEREQUESTS}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_DATAVIEWS_CONFIG.OVERVIEWS_PROVIDERACTIVITIES].includes(enum_statistic)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: ([ENUM_STATISTICS.CASEREQUESTS].includes(enum_statistic)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.CASEREQUESTS, <StatisticCaseRequests />)
			},
			{
				label: getTranslation(NAMESPACE, 'caseperson.label.tab'),
				ariaLabel: getTranslation(NAMESPACE, 'caseperson.aria-label.tab'),
				icon: ENUM_ICONS.USER,
				className: ([ENUM_STATISTICS.CASEPERSONS].includes(enum_statistic)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.CASEPERSONS, <StatisticCasePersons />)
			},
			{
				label: getTranslation(NAMESPACE, 'company.label.tab'),
				ariaLabel: getTranslation(NAMESPACE, 'company.aria-label.tab'),
				icon: ENUM_ICONS.COMPANY,
				className: ([ENUM_STATISTICS.COMPANYCONTACTS].includes(enum_statistic)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.COMPANYCONTACTS, <StatisticCompanyContacts />)
			}
		]
		setItems(items);
	}, [])

	const changeDataView = (enum_dataview_config, component) => {
		setDataView(component);
		setMenubarItemSelected({ ...menubarItemSelected, [ENUM_TABVIEWS.STATISTICS]: enum_dataview_config })
	}

	return (
		<div className='mx-auto eaa-dataView ' style={{ maxWidth: 'calc(100vw - 100px)', maxheight: 'calc(100vh - 60px)' }} >
			<div className='flex flex-column w-full border-1 border-gray-200 bg-white' >
				<Menubar model={items} className='border-none border-bottom-1' />
				<div className='z-0 mx-3 my-1'>{dataView}</div>
			</div>

		</div>
	)

}