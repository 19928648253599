import { format } from "date-fns";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useRef, useState } from 'react';
import { useBreadcrumbsAdd } from "../Persistence/Breadcrumbs";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useDataViewFilter, useDataViewFilterUpdate, useDataViewMounted, useDataViewPagination, useDataViewPaginationUpdate, useDataViewSort, useDataViewSortUpdate } from "../Persistence/DataViewFilterContext";
import { useRegionsList } from "../Persistence/RegionsContext";
import { logDefault } from "../Utils/logger";
import { ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "./Icons";
import { OpenEye } from "./OpenEye";
import { Button } from "primereact/button";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ConfirmDialog } from "./ConfirmDialog";
import { generateReport, getDataPagination } from "../feathers";
import { useStrgPressed } from "../Persistence/StrgPressed";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { UnloadComponent } from "./UnloadComponent";
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getDataviewColumn } from "./Dataview/DataviewColumn";
import { Column } from "primereact/column";
import n2words from 'n2words'
import '../css/dataviews.scss'

//const n2words = require('n2words')
const CLASSNAME = 'TemplateDataViewPagination'
const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS

export const TemplateDataViewPagination = ({ dataviewConfig, columns, showOpenLinkColumn, bodyOpenLink, ROUTE, routeIdField, classNameDataTable, scrollHeight, dataTableWidth, buttons, onDoubleClick, refreshTime, menuModelRoute, screenreaderFields }) => {
	const ctlPressed = useStrgPressed();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const dataViewFilter = useDataViewFilter();
	const setDataViewFilter = useDataViewFilterUpdate();
	const dataViewSort = useDataViewSort();
	const setDataViewSort = useDataViewSortUpdate();
	const dataViewPagination = useDataViewPagination();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const regionsList = useRegionsList();
	const currentUser = useCurrentUser();
	const [screenReaderMessage, setScreenReaderMessage] = useState('')
	const [dataviewList, setDataviewList] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0)
	const dataViewMounted = useDataViewMounted();
	const [paginationFirst, setPaginationFirst] = useState(dataViewPagination[dataviewConfig.key].paginationFirst);
	const [paginationRows, setPaginationRows] = useState(dataViewPagination[dataviewConfig.key].paginationRows || 15);
	const [currentPage, setCurrentPage] = useState(dataViewPagination[dataviewConfig.key].currentPage);
	const [selectedRow, setSelectedRow] = useState();
	const [multiSortMeta, setMultiSortMeta] = useState(dataViewSort[dataviewConfig.key]);
	const [filters, setFilters] = useState(dataViewFilter[dataviewConfig.key]);
	const [filterValues, setFilterValues] = useState();
	const [mounted, setMounted] = useState(false);
	const [initPagination, setInitPagination] = useState(false);
	const [showExportButton, setShowExportButton] = useState(false);
	const [filtersActive, setFiltersActive] = useState(false);
	const [displayExportConfirmDialog, setDisplayExportConfirmDialog] = useState(false);
	const cm = useRef(null);
	const dt = useRef(null);
	const refMain = useRef();

	useEffect(() => {
		if (mounted) {
			refMain.current.focus()
			setScreenReaderMessage(`Übersicht ${dataviewConfig.headline}`)
		}
	}, [mounted])

	useEffect(() => {
		if (mounted) {
			refreshData();
		}

	}, [mounted, refreshTime])

	useEffect(() => {
		if (!mounted && filters) {
			setShowExportButton(dataviewConfig.exportRoles && dataviewConfig.exportRoles.includes(currentUser.permissions))
			setMounted(true);
		}
	}, [configurationsList, regionsList, filters])

	useEffect(() => {
		if (dataViewMounted) {
			setFilters(dataViewFilter[dataviewConfig.key])
		}
	}, [dataViewMounted])

	useEffect(() => {
		if (dataViewMounted) {
			let filterActive = false
			for (let key of Object.getOwnPropertyNames(filters || {})) {
				if (filters[key].value !== null && ('' + filters[key].value !== '')) {
					filterActive = true
				}
			}
			setFiltersActive(filterActive)
			setDataViewFilter(dataviewConfig.key, filters).then(() => {
				refreshData();
			});
			setPaginationFirst(0);
			setCurrentPage(1);
			setDataViewPagination(dataviewConfig.key, { paginationFirst: 0, paginationRows, currentPage: 0 })
		}

	}, [filters])

	const saveAsExcelFile = (buffer, fileName) => {
		import('file-saver').then(module => {
			if (module && module.default) {
				let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				let EXCEL_EXTENSION = '.xlsx';
				const data = new Blob([buffer], {
					type: EXCEL_TYPE
				});

				module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_EAA-DOQ_' + fileName + EXCEL_EXTENSION);
			}
		});
	}

	const generateExport = async () => {
		const excelBuffer = await generateReport(dataviewConfig)
		saveAsExcelFile(excelBuffer, dataviewConfig.exportFilename);
	}

	const refreshData = async () => {
		if (mounted) {
			await getDataPagination(dataviewConfig).then((result) => {
				setTotalRecords(result.total);
				setDataviewList(result.data);
				const filtertext = []
				for (let key of Object.getOwnPropertyNames(filters || {})) {
					if (filters[key].value !== null && ('' + filters[key].value !== '')) {
						filtertext.push(`${filters[key].header} mit Filter ${filters[key].value}`)
					}
				}
				const totalText = n2words(result.total, { lang: 'de' })
				setScreenReaderMessage(`Übersicht ${dataviewConfig.headline} ${filtertext.length === 0 ? 'keine Filter gesetzt' : `${filtertext.length} Filter gesetzt ${filtertext.join(' ')}`} ${result.total === 0 ? 'kein Eintrag' : (result.total === 1 ? 'ein Eintrag' : totalText + ' Einträge')} `)
			})
		}
	}

	const renderOpenLink = (rowData) => {
		return <OpenEye ROUTE={ROUTE} id={routeIdField ? rowData[routeIdField] : rowData.id} />
	}

	const onChangeSort = async (e) => {
		try {
			setMultiSortMeta(e.multiSortMeta)
			await setDataViewSort(dataviewConfig.key, e.multiSortMeta)
			refreshData();
		} catch (error) {
			console.error('Filtererror:', error)
		}
	}
	const onChangePaginator = async (event) => {
		if (!initPagination) {
			//event.first = 0
		}
		setInitPagination(true);
		setPaginationFirst(event.first);
		setPaginationRows(event.rows);
		setCurrentPage(event.page);
		logDefault(CLASSNAME, 'info', dataviewConfig.key + ' onChangePaginator: paginationFirst:' + paginationFirst, event)
		await setDataViewPagination(dataviewConfig.key, { paginationFirst: event.first, paginationRows: event.rows, currentPage: event.page })
		refreshData();
	}

	const removeAllFilter = async () => {
		const emptyFilter = {}
		for (let key of Object.getOwnPropertyNames(filters)) {
			const filter = filters[key]
			filter.value = null
			emptyFilter[key] = filter
		}
		await setDataViewFilter(dataviewConfig.key, emptyFilter);
		setFilters(emptyFilter);
		refreshData();
	}

	const menuModel = [
		{
			label: 'Öffnen', icon: ENUM_ICONS.EYE, command: () => {
				let route = ROUTE + '/' + selectedRow.id
				if (menuModelRoute) {
					route = menuModelRoute(selectedRow)
				}
				addBreadcrumb(``, route)
			}
		},
		{
			label: 'In neuem Tab öffnen', icon: ENUM_ICONS.EYE, command: () => {
				let route = ROUTE + '/' + selectedRow.id
				if (menuModelRoute) {
					route = menuModelRoute(selectedRow)
				}
				window.open(window.location.origin + '/' + route, '_blank')
			}
		},
	];

	const setFilterValue = (field, value) => {
		const _filterValues = { ...filterValues };
		_filterValues[field] = value;
		setFilterValues(_filterValues);
	}

	const getDoubleClickCommand = (e) => {
		if (onDoubleClick) {
			onDoubleClick(e.data)
		} else if (!showOpenLinkColumn) {
			console.log('KEINE AKTION')
		} else {
			const id = routeIdField ? e.data[routeIdField] : e.data.id;
			if (id) {
				if (ctlPressed) {
					const url = window.location.href.replace(ENUM_ROUTES.OVERVIEWS, ROUTE)
					window.open(url + '/' + id, '_blank', { rel: "noopener noreferrer" });
				} else {
					addBreadcrumb(``, `${ROUTE}/` + id)
				}
			}
		}
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'NextPageLink': (options) => {
			return (
				<button aria-label='Nächste Seite' type="button" onClick={options.onClick} disabled={options.disabled} className="p-paginator-next p-link">
					<i className='pi pi-angle-right' />
				</button>
			);
		},
		'PrevPageLink': (options) => {
			return (
				<button aria-label='Vorherige Seite' type="button" onClick={options.onClick} disabled={options.disabled} className="p-paginator-prev p-link">
					<i className='pi pi-angle-left' />
				</button>
			);
		},
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 50, value: 50 },
				{ label: 100, value: 100 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown
						aria-label='Einträge pro Seite'
						value={options.value}
						options={dropdownOptions}
						onChange={options.onChange}
					/>
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (<div style={{ marginLeft: '20px', color: 'var(--text-color)', userSelect: 'none', width: '200px', textAlign: 'center' }}>
				<span >
					{options.first} - {options.last} von {totalRecords}
				</span>
				<span style={{ display: 'none', marginLeft: '20px', marginRight: '20px', color: 'var(--text-color)', userSelect: 'none', width: '30px', textAlign: 'center' }}>
					Seite {currentPage + 1}
				</span>
			</div>
			)
		}
	};

	const unmounteFunction = () => {
		logDefault(CLASSNAME, 'info', 'Unload TemplateDataView ' + dataviewConfig.alias + ' mounted:' + mounted);
		setMounted(false)
	}

	return (
		<div className={'flex flex-column overflow-x-auto  justify-content-center select-none ' + (classNameDataTable || 'eaa-dataView')} >
			<UnloadComponent unmountFunction={unmounteFunction} />
			<div tabIndex={0} ref={refMain} aria-live="polite" className="screen-reader-only">{screenReaderMessage}</div>
			<div className={"flex justify-content-end flex-wrap card-container gap-2 my-2"}>
				<div className={"flex flex-grow-1 mb-2"}>
					<div className={"flex align-self-center justify-content-left"}>
						<Button
							className={!filtersActive ? `p-disabled` : ''}

							icon={'pi pi-filter-slash'}
							label={getTranslation(NAMESPACE, 'clear-all-filter.label')}
							onClick={removeAllFilter} />
					</div>
				</div>
				<div className={!showExportButton ? 'hidden' : "flex align-self-center justify-content-left mb-2"}>
					<Button
						icon={ENUM_ICONS.EXPORT}
						aria-label={getTranslation(NAMESPACE, 'excel-export.ariaLabel')}
						label={getTranslation(NAMESPACE, 'excel-export.label')}
						onClick={() => setDisplayExportConfirmDialog(true)} />
				</div>
				{(buttons || []).map((b) => <div className="flex align-self-center justify-content-left mb-2" key={'div-' + b.key}>{b}</div>)}
			</div>
			<div className={"flex card flex md:inline-flex"} >
				<ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRow(null)} />
				<ConfirmDialog
					NAMESPACE={ENUM_NAMESPACES.FORMELEMENTS}
					labelKey='confirmdialog-dataview-export'
					displayConfirmDialog={displayExportConfirmDialog}
					setDisplayConfirmDialog={setDisplayExportConfirmDialog}
					key='display-export-confirm-dialog'
					style={{ width: '800px' }}
					className={' '}
					handleOnClick={generateExport}
				/>

				{<DataTable emptyMessage={'keine Einträge gefunden'}
					scrollHeight={scrollHeight || 'calc(100vh - 220px)'}
					scrollable scrollDirection="both"
					className='w-full template-dataview'
					rowHover
					showGridlines
					size="small"
					lazy={true}
					responsiveLayout="scroll"
					dataKey="id"
					stripedRows
					value={dataviewList}
					selection={selectedRow}
					removableSort
					onSort={onChangeSort}
					sortMode="multiple"
					multiSortMeta={multiSortMeta}
					filters={filters}
					filterDisplay='row'
					onFilter={(e) => setFilters(e.filters)}
					paginator
					paginatorTemplate={paginatorTemplate}
					first={paginationFirst}
					rows={paginationRows}
					totalRecords={totalRecords}
					onPage={(e) => onChangePaginator(e)}
					contextMenuSelection={selectedRow}
					ref={dt}
					onContextMenuSelectionChange={e => setSelectedRow(e.value)}
					onRowDoubleClick={getDoubleClickCommand}
					onContextMenu={e => { if (showOpenLinkColumn) { cm.current.show(e.originalEvent) } }}>
					{/** Tabellenspalten */}
					{columns.map((column) => {
						return getDataviewColumn(column, filters[column.filterAlias], setFilterValue, getDoubleClickCommand, dataviewConfig, configurationsList)
					})}
					{!showOpenLinkColumn ? null : <Column
						style={{ width: '40px' }}
						frozen={true}
						alignFrozen='right'
						className='open-icon justify-content-center p-0'
						body={bodyOpenLink || renderOpenLink} />
					}
				</DataTable>}
			</div>
		</div >
	)
}

const listValueTemplate = (option, placeholder) => {
	return <div style={{ minHeight: '21px' }}>{option && option.icon ? <i className={option.icon + ' w-2rem '} /> : (option && option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : ' ')}{option && option.onlyIcon ? ' ' : (option ? option.datatable : placeholder)}</div>
}

const listItemTemplate = (option) => {
	return <div className='flex w-full  align-items-center'>{option.icon ? <i className={option.icon + ' pr-1 w-2rem'} /> : (option.awesomeIcon ? <Icon ENUM_AWESOMEICON={option.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className=' pr-3 w-1rem' /> : '')}{option.datatable}</div>
}