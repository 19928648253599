import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { getData, patchData } from "../feathers";
import { useCurrentUser, useCurrentUserIsReaderEAA } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { JobOpeningForm, emptyJobOpening, validateJobOpening } from "./JobOpeningForm";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { Tag } from "primereact/tag";
import { JobOpeningSheet } from "./JobOpeningSheet";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList, useFormatDate } from "../Persistence/ConfigurationsContext";
import { ENUM_AWESOMEICON, ENUM_ICONS, Icon } from "../Components/Icons";
import { CompanySheet } from "../Companies/CompanySheet";
import exportToPDF from "../Utils/exportToPDF";
import { ToastError } from "../Utils/ToastError";
import { CommentsModule } from "../Components/CommentsModule";
import { BasicSheetLayout } from "../Components/Layouts/BasicSheetLayout";
import { JobOpeningCaseSheet } from "./JobOpeningCaseSheet";
import { logDefault } from "../Utils/logger";
import { useErrorUpdate } from "../Persistence/ErrorContext";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { copyObject, formatDateTime, getTranslation } from "../snippets";
import { TRAININGCOURSE_SERVICECONFIG, TrainingCourseSheet } from "../FBiB/TrainingCourses/TrainingCourseSheet";
import { FBiBJobOpeningCaseSheet } from "../FBiB/FBiBJobOpenings/FBiBJobOpeningCaseSheet";
import { useFormik } from "formik";
import { EditDialog } from "../Components/Dialogs/EditDialog";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";

export const emptyJobOpeningComment = { id: null, version: 1, active: 1, comment: '' }

const NAMESPACE = ENUM_NAMESPACES.JOBOPENINGS
const CLASSNAME = 'JobOpeningData';
const SERVICENAME = ENUM_SERVICES.JOBPROFILES;
export const JobOpeningData = () => {
	const formatDate = useFormatDate();
	const errorUpdate = useErrorUpdate()
	const currentUser = useCurrentUser();
	const isReader = useCurrentUserIsReaderEAA();
	const configurationsList = useConfigurationsList();
	const location = useLocation();
	const { jobOpeningId } = useParams();
	const [jobOpening, setJobOpening] = useState();
	const [readonly, setReadonly] = useState(true);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);
	const navigate = useNavigate();
	const [fbibMode, setFbibMode] = useState(false);

	useEffect(() => {
		setFbibMode(location.pathname.toUpperCase().startsWith('/FBIB'));
	}, [])

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			errorUpdate('Modul Stellenprofile', 'Das Modul Stellenprofile ist in dieser Instanz nicht verfügbar.<br/>Wenden Sie sich bitte an Ihre/n Administrator*in')
			navigate('/' + ENUM_ROUTES.ERROR)
		}
	}, [configurationsList, errorUpdate, navigate])

	const getJobOpeningData = async () => {
		return await getData(ENUM_SERVICES.JOBPROFILES, jobOpeningId).then((jobOpening) => {
			filterAndSortComments(jobOpening);
			logDefault(CLASSNAME, 'info', 'getJobOpeningData:', jobOpening)
			return jobOpening !== null
		})
	}

	useEffect(() => {
		if (jobOpeningId) {
			getJobOpeningData()
		}
	}, [jobOpeningId])

	const filterAndSortComments = (jobOpening) => {
		if (jobOpening.jobOpeningComments) {
			jobOpening.jobOpeningComments = jobOpening.jobOpeningComments.filter(entry => entry.active);
			jobOpening.jobOpeningComments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a === b ? 0 : a > b ? 1 : -1; })
		}
		setJobOpening(jobOpening);
		setReadonly(jobOpening.status.alias === 'INACTIVE' || isReader)
	}

	const patchJobOpening = async () => {
		await patchData(ENUM_SERVICES.JOBPROFILES, formik.values).then(async (jobOpening) => {
			await getJobOpeningData()
			setDisplayDialog(false);
		})
	}

	const handleExportJobOpening = () => {
		exportToPDF('JOBOPENING', jobOpening, null, configurationsList.eaaModules);
	}
	const handleEditJobOpening = () => {
		formik.setValues({ ...copyObject(jobOpening), openingDate: parseISO(jobOpening.openingDate) })
		setDisplayDialog(true);
	}

	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const handleStatusJobOpening = async () => {
		const status = { alias: (jobOpening.status.alias === 'INACTIVE' ? 'OPEN' : 'INACTIVE') }
		await patchData('job-openings', { id: jobOpening.id, active: 1, version: jobOpening.version, status }).then(async () => {
			await getJobOpeningData();
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gespeichert werden', message: error.message })
		})
	}

	const items = [{ label: getTranslation(NAMESPACE, 'button-edit-jobopening.label'), icon: ENUM_ICONS.PENCIL, command: handleEditJobOpening, disabled: readonly },
	{ label: 'Exportieren', icon: ENUM_ICONS.EXPORT, command: handleExportJobOpening },
	{ separator: true },
	{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.DEACTIVATE), command: () => { setDisplayConfirmDialog(true) }, disabled: isReader }]

	const formik = useFormik({
		initialValues: copyObject(emptyJobOpening),
		validate: validateJobOpening,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderDialogs = () => {
		return <>
			<ToastError toastParams={toastParams} />
			<ReminderDialog
				displayDialog={displayReminderDialog}
				setDisplayDialog={setDisplayReminderDialog}
				header='Stellenprofil'
				name={jobOpening.company.name}
				serviceId={jobOpening.id}
				serviceName={ENUM_SERVICES.JOBPROFILES}
				userId={currentUser.id}
			/>
			<EditDialog
				displayDialog={displayDialog}
				formik={formik}
				NAMESPACE={NAMESPACE}
				labelKey={'editdialog'}
				form={<JobOpeningForm
					formik={formik}
				/>}
				patchForm={patchJobOpening}
				setDisplayDialog={setDisplayDialog}
			/>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-deactivate'
				labelObject={{ action: readonly ? 'reaktivieren' : 'inaktivieren' }}
				handleOnClick={handleStatusJobOpening}
				displayConfirmDialog={displayConfirmDialog}
				setDisplayConfirmDialog={setDisplayConfirmDialog} />

		</>
	}

	const renderContent = () => {
		const status = configurationsList.JobOpeningStatusList.find(e => e.alias === jobOpening.status)
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				{!jobOpening ? '' : <div className='mx-auto eaa-maincontent flex flex-column'>
					<div className="flex flex-column">
						<div className='flex justify-content-end mb-4 gap-2'>
							<Button onClick={handleEditJobOpening}
								disabled={isReader || readonly}
								icon={ENUM_ICONS.PENCIL}
								className='flex text-white'
								label={getTranslation(NAMESPACE, 'button-edit-jobopening.label')}
								aria-label={getTranslation(NAMESPACE, 'button-edit-jobopening.ariaLabel')}
							/>
							<Button onClick={handleUseForReminder}
								icon={ENUM_ICONS.CLOCK}
								disabled={isReader}
								className='flex text-white'
								label={getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.label`)}
								aria-label={getTranslation(ENUM_NAMESPACES.REMINDERS, `button-edit-reminder.ariaLabel`)}
							/>
						</div>
						<div className='flex grid'>
							<div className='col flex flex-column text-4xl '>
								<div className='flex text-center justify-content-center font-semibold'>
									{jobOpening.description}
								</div>
								<div className='flex text-lg justify-content-center my-2 gap-2'>
									<div>{getTranslation(ENUM_NAMESPACES.FORMELEMENTS, 'subtitle.label', { displayId: jobOpening?.displayId, dateFormatted: formatDate(parseISO(jobOpening.createdAt)), creatorname: jobOpening.creator?.displayname })}</div>
									<Tag key='tagStatus' className={'mr-2 text-white color-tag-' + status.color} rounded value={status.display} />
								</div>
							</div>
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.companyname' content={jobOpening.company.name} className='col-4' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.COMPANY} />} />
							<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.casename' content={jobOpening.eaaCase ? jobOpening.eaaCase.name : 'keinem Fall zugeordnet'} className='col-4' icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.EAACASE} />} />
							{!jobOpening.trainingCourse ? '' : <FieldInfo label='Ausbildungsgang' content={jobOpening.trainingCourse.name} icon={<Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCOURSES} />} className='col-4' />}
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.address' content={jobOpening.location} className='col-6' />
							<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.openingDate' content={jobOpening.openingDate ? formatDate(parseISO(jobOpening.openingDate)) : ''} className='col-3' />
							<FieldInfo NAMESPACE={NAMESPACE} translationKey='fields.jobType' content={configurationsList.JobOpeningTypeList.find(e => e.alias === jobOpening.jobType).display} className='col-3' />
						</div>
					</div>

					<div className='flex flex-column surface-ground p-2 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							{getTranslation(NAMESPACE, 'fields.description.label')}
						</div>
						<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: jobOpening.explanation }} />
					</div>

					<div className="flex flex-column mt-4 mr-3">
						<CommentsModule
							onUpdateComment={getJobOpeningData}
							key='companyCommentsModule'
							servicename={ENUM_SERVICES.JOBPROFILECOMMENTS}
							readonly={readonly}
							parentId={jobOpening.id}
							idFieldname='jobOpeningId'
							reminderParentService={ENUM_SERVICES.JOBPROFILES}
							comments={jobOpening.jobOpeningComments} />
					</div>

				</div>}
			</div>
		</div>
	}

	const getSideSheets = () => {
		const sideSheets = [
			{ index: 0, visible: true, icon: ENUM_ICONS.JOBPROFILE + "fa-lg", component: <JobOpeningSheet jobOpening={jobOpening} /> },
			{
				index: 1, visible: true, icon: ENUM_ICONS.COMPANY + "fa-lg",
				component: <CompanySheet
					readonly={readonly}
					company={jobOpening.company}
					onUpdateCompany={getJobOpeningData} />
			},
			{
				index: 2, visible: false && configurationsList.eaaModules && configurationsList.eaaModules.FBIB === '1' && fbibMode, awesomeIcon: ENUM_AWESOMEICON.TRAININGCOURSES,
				component: <div className="flex flex-column row-gap-4">
					<TrainingCourseSheet
						serviceConfig={TRAININGCOURSE_SERVICECONFIG.JOBOPENING}
						readonly={readonly}
						parentId={jobOpening.id}
						trainingCoursesList={jobOpening.courses}
						updateParent={getJobOpeningData}
					/>
				</div>
			},
			{ index: 3, visible: true, icon: ENUM_ICONS.EAACASE + "fa-lg", component: (<JobOpeningCaseSheet jobOpening={jobOpening} />) },
		]
		return sideSheets;
	}

	return (!jobOpening ? '' :
		<BasicSheetLayout
			mainContent={<div className='flex mx-auto eaa-maincontent mt-0'>{!jobOpening ? '' : renderContent()}{!jobOpening ? '' : renderDialogs()}</div>}
			overlayMenu={<OverlayPanelMenu items={items} />}
			sideSheets={getSideSheets()}
			reminderService={SERVICENAME}
			serviceId={jobOpening.id}
		/>
	)

}