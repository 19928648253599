import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useCurrentUserIsAdmin, useTabViewsIndex, useTabViewsIndexUpdate, useUserSwitchMode } from '../Persistence/CurrentUserContext';
import { patchData } from '../feathers';
import { RegionDataview } from './RegionDataview';
import { TaskareaDataview } from './TaskareaDataview';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { OrganizationDataview } from './OrganizationDataview';
import { CaseRequestTypesDataview } from './CaseRequestTypesDataview';
import { Menubar } from 'primereact/menubar';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../Components/Icons';
import { ENUM_SETTINGS } from '../Enums/ENUM_SETTINGS';
import { ENUM_TABVIEWS } from '../Enums/ENUM_TABVIEWS';
import { getTranslation } from "../snippets";
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const NAMESPACE = ENUM_NAMESPACES.SETTINGS
export const Settings = () => {
	const isAdmin = useCurrentUserIsAdmin();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const menubarItemSelected = useTabViewsIndex();
	const setMenubarItemSelected = useTabViewsIndexUpdate();
	const [items, setItems] = useState(null)
	const [dataView, setDataView] = useState();
	const [toggleEntry, setToggleEntry] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	})


	useEffect(() => {
		const value = menubarItemSelected ? menubarItemSelected[ENUM_TABVIEWS.SETTINGS] || ENUM_SETTINGS.REGIONS : ENUM_SETTINGS.REGIONS
		setMenuItems(value)
		switch (value) {
			case ENUM_SETTINGS.REGIONS: setDataView(<RegionDataview handleStatusChange={handleStatusChange} />); break;
			case ENUM_SETTINGS.ORGANIZATIONS: setDataView(<OrganizationDataview handleStatusChange={handleStatusChange} />); break;
			case ENUM_SETTINGS.TASKAREAS: setDataView(<TaskareaDataview handleStatusChange={handleStatusChange} />); break;
			case ENUM_SETTINGS.CASEREQUESTTYPES: setDataView(<CaseRequestTypesDataview handleStatusChange={handleStatusChange} />); break;
			default: setDataView(<RegionDataview handleStatusChange={handleStatusChange} />)
		}
	}, [menubarItemSelected])

	const setMenuItems = useCallback((enum_settings) => {
		const items = [
			{
				label: getTranslation(NAMESPACE, 'regions-tab.label'),
				ariaLabel: getTranslation(NAMESPACE, 'regions-tab.aria-label'),
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.REGIONS}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_SETTINGS.REGIONS].includes(enum_settings)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: ([ENUM_SETTINGS.REGIONS].includes(enum_settings)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_SETTINGS.REGIONS, <RegionDataview handleStatusChange={handleStatusChange} />)
			},
			{
				label: getTranslation(NAMESPACE, 'organizations-tab.label'),
				ariaLabel: getTranslation(NAMESPACE, 'organizations-tab.aria-label'),
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.ORGANIZATIONS}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_SETTINGS.ORGANIZATIONS].includes(enum_settings)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: ([ENUM_SETTINGS.ORGANIZATIONS].includes(enum_settings)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_SETTINGS.ORGANIZATIONS, <OrganizationDataview handleStatusChange={handleStatusChange} />)
			},
			{
				label: getTranslation(NAMESPACE, 'taskareas-tab.label'),
				ariaLabel: getTranslation(NAMESPACE, 'taskareas-tab.aria-label'),
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.REQUESTDISTRIBUTION}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_SETTINGS.TASKAREAS].includes(enum_settings)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: configurationsList.eaaModules && !configurationsList.eaaModules.REQUESTDISTRIBUTION ? 'hidden' : (([ENUM_SETTINGS.TASKAREAS].includes(enum_settings)) ? 'statistics-menubar-selected' : ''),
				command: () => changeDataView(ENUM_SETTINGS.TASKAREAS, <TaskareaDataview handleStatusChange={handleStatusChange} />)
			},
			{
				label: getTranslation(NAMESPACE, 'requests-tab.label'),
				ariaLabel: getTranslation(NAMESPACE, 'requests-tab.aria-label'),
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.CASEREQUESTS}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_SETTINGS.CASEREQUESTTYPES].includes(enum_settings)) ? 'mr-2 color-text-blue' : 'mr-2'}
				/>,
				className: ([ENUM_SETTINGS.CASEREQUESTTYPES].includes(enum_settings)) ? 'statistics-menubar-selected' : '',
				command: () => changeDataView(ENUM_SETTINGS.CASEREQUESTTYPES, <CaseRequestTypesDataview handleStatusChange={handleStatusChange} />)
			}
		]
		setItems(items);
	}, [])

	const changeDataView = (enum_setting, component) => {
		setDataView(component);
		setMenubarItemSelected({ ...menubarItemSelected, [ENUM_TABVIEWS.SETTINGS]: enum_setting })
	}

	const toggleStatus = async () => {
		toggleEntry.active = (toggleEntry.active ? 0 : 1);
		await patchData(toggleEntry.servicename, toggleEntry);
		setDisplayConfirmDialog(false);
	}

	const handleStatusChange = async (entry, servicename, objectName) => {
		const command = (entry.active ? 'deaktivieren' : 'reaktivieren');
		const confirmTitle = objectName + ' ' + command
		const confirmMessage = `<span>Sind Sie sicher,<br/><b>${objectName + ': ' + entry.name}</b><br>zu ${command}?`
		setToggleEntry({ ...entry, servicename, confirmTitle, confirmMessage });
		setDisplayConfirmDialog(true);
	}

	return (
		<div className={!isAdmin && !userSwitchMode ? 'hidden' : ''}>
			<div className='mx-auto eaa-dataView flex gap-4'>
				<div className='flex flex-column w-full border-1 border-gray-200 bg-white' >
					<Menubar model={items} className='border-none border-bottom-1' />
					<div className='z-0 mx-3 my-1'>{dataView}</div>
				</div>
			</div>
			<ConfirmDialog
				NAMESPACE={NAMESPACE}
				labelKey='confirmdialog-deactivate'
				labelObject={toggleEntry}
				handleOnClick={toggleStatus}
				displayConfirmDialog={displayConfirmDialog}
				setDisplayConfirmDialog={setDisplayConfirmDialog} />
		</div>
	)

}