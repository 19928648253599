import { useEffect, useState } from 'react';
import { Login } from './Login/login';
import { ChangePassword } from './Login/ChangePassword';
import { Message } from 'primereact/message';
import { useCurrentUser } from './Persistence/CurrentUserContext';
import { useSocketError } from './Persistence/SocketErrorContext';
import { ConfigurationsProvider, useConfigurationsComplete, useConfigurationsList } from './Persistence/ConfigurationsContext';
import { locale, addLocale } from 'primereact/api';
import logger, { LoggerContext, LoggerLogin, logDefault, loglevel, loglevelcontext, loglevellogin } from './Utils/logger';
import { ApplicationInit } from './ApplicationInit';
import { TaskareasProvider } from './Persistence/TaskareasContext';
import { RegionsProvider } from './Persistence/RegionsContext';
import client from './feathers';


const CLASSNAME = 'Application';
export const Application = () => {
	const currentUser = useCurrentUser();
	const socketError = useSocketError();
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setTimeout(() => setMounted(true), 500)
	}, [])

	useEffect(() => {

		logger.level = loglevel;
		LoggerContext.level = loglevelcontext;
		LoggerLogin.level = loglevellogin;
		window.onunhandledrejection = event => {
			logger.warn(`UNHANDLED PROMISE REJECTION: ${event.reason}`);
		};
		addLocale('de', {
			firstDayOfWeek: 1,
			dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
			dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			dayNamesMin: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
			monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
			monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			today: 'Tag',
			clear: 'Löschen'
		});
		locale('de')
		client.service('metadata').find().then((appConfig) => {
			console.log('metadata:', appConfig)
			document.title = `EAA Management${appConfig ? ' - ' + appConfig.title : ''}`;
		})

	}, [])

	const renderPage = () => {
		logDefault(CLASSNAME, 'info', '------------ APPLICATION ------------- ' + (currentUser ? currentUser.username : ''))
		if (socketError !== null) {
			return <div className="flex p-jc-center p-ai-center" style={{ height: '100%', backgroundColor: 'rgba(255,255,255,0.3)' }}>
				<div className='p-shadow-24 p-as-center ' style={{ width: '400px' }}>
					<Message className='p-6' severity="warn" text={socketError} />
				</div>
			</div>
		} else if (currentUser && currentUser.forceChangePassword === 1) {
			return (
				<ChangePassword user={currentUser} currentUser={currentUser} hrMode={false} hideLogo={false} />
			)
		} else if (currentUser) {
			return <RegionsProvider>
				<TaskareasProvider>
					<ConfigurationsProvider>
						<ApplicationInit />
					</ConfigurationsProvider>
				</TaskareasProvider>
			</RegionsProvider>
		}
		return <Login />;
	}

	return !mounted ? '' : renderPage()
}
