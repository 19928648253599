import { ConfirmDialog } from '../../Components/ConfirmDialog'
import { useState } from 'react'
import { parseISO } from 'date-fns';
import { useConfigurationsList, useFBiBMode, useFormatDate } from '../../Persistence/ConfigurationsContext';
import { useBreadcrumbsUpdate } from '../../Persistence/Breadcrumbs';
import { ROUTES } from '../../Navigation/Routes';
import { useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../../Components/Icons';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';

const numberFormat = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);

const NAMESPACE = ENUM_NAMESPACES.FBIBJOBOPENINGS
export const FBiBJobOpeningCard = ({ item, onEditStatus, onEditHandler, onDeleteHandler, readonly, fbibCaseMode, fbibCaseId, fbibCaseJobOpeningId }) => {
	const formatDate = useFormatDate();
	const isReader = useCurrentUserIsReaderFBiB();
	const fbibMode = useFBiBMode();
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const configurationsList = useConfigurationsList();
	const updateBreadcrumbs = useBreadcrumbsUpdate();

	const onOpenHandler = async (id) => {
		const route = fbibMode ? ROUTES.FBIB_JOBPROFILES : ROUTES.JOBPROFILES
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + id }] })
	}

	const renderContent = () => {
		const { openingDate, jobType, salary, location, trainingCourse, status } = item
		const _jobType = configurationsList.FBiBJobOpeningTypeList.find(entry => entry.alias === jobType);
		const _status = configurationsList.FBiBJobOpeningStatusList.find(entry => entry.alias === status);
		const statustext = 'Status: ' + (_status ? _status.display : '') + (status === 'RESERVED' && item.fbibCaseId && item.fbibCaseId !== fbibCaseId ? ' für FBiB-FA-' + item.fbibCaseId.toString().padStart(5, "0") : '')
		return <div className="flex align-content-end flex-column h-16rem py-1 px-2 overflow-x-auto">
			<div className='grid'><div className='col'>{statustext}</div></div>
			<div className={trainingCourse ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.TRAININGCOURSES} size={ENUM_AWESOMEICON_SIZE.XS} /></div><div className='col '>{item.trainingCourse ? item.trainingCourse.name : ''}</div></div>
			<div className={openingDate ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.CALENDAR} /></div><div className='col '>ab {formatDate(parseISO(openingDate))}</div></div>
			<div className={jobType ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.JOBTYPE} /></div><div className='col '>{_jobType ? _jobType.display : ''}</div></div>
			<div className={salary ? 'grid' : 'hidden'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.EURO} /></div><div className='col '>{numberFormat(salary)}</div></div>
			<div className={'grid'}><div className='col-fixed w-2rem'><i className={ENUM_ICONS.LOCATION} /></div><div className='col ' style={{ whiteSpace: 'pre-wrap' }}>{[item.line1, item.line2, item.line3, (item.postcode + ' ' + item.city).trim(), item.country].filter(e => e !== '').join('\n')}</div></div>

		</div>
	}

	const renderTitle = () => {
		const { status } = item
		const showEditStatus = fbibCaseMode && ((status === 'OPEN' && !fbibCaseJobOpeningId) || fbibCaseJobOpeningId === item.id)

		return <div className={`flex title ${fbibCaseId === item.fbibCaseId ? 'color-background-fbib' : ''} `}>
			<div className="flex flex-grow-1 flex-column">
				<div className="flex font-bold">
					{item.description}
				</div>
			</div>
			<div className="flex title-icons justify-content-end font-light pt-1">
				<i className={ENUM_ICONS.EYE + 'fa-lg text-white-500'} onClick={() => { onOpenHandler(item.id) }} />
				<i className={!showEditStatus || isReader || readonly ? 'hidden' : (ENUM_ICONS.PENCIL + 'title-pencil')} onClick={() => { onEditStatus(item.id) }} />
				<i className={fbibCaseMode || isReader || readonly ? 'hidden' : (ENUM_ICONS.PENCIL + 'title-pencil')} onClick={() => { onEditHandler(item.id) }} />
				<i className={fbibCaseMode || isReader || readonly || true ? 'hidden' : (ENUM_ICONS.TRASH + 'title-trash')} onClick={() => { setDisplayConfirmDialog(true) }} />
			</div>
		</div>
	}

	return (<>
		<ConfirmDialog
			NAMESPACE={NAMESPACE}
			labelKey='confirmdialog-deactivate'
			labelObject={item}
			handleOnClick={() => onDeleteHandler(item)}
			displayConfirmDialog={displayConfirmDialog}
			setDisplayConfirmDialog={setDisplayConfirmDialog}
		/>

		<div className={"eaa-card jobopening-card shadow-2"}>
			{renderTitle()}
			{renderContent()}
		</div>
	</>)
}