import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { getEnumArray } from '../Enums/ENUM_NAMESPACES';
const path = require('path-browserify');
//import XHR from "i18next-xhr-backend";

export let initialized = false
i18n.use(Backend)
	//	.use(XHR)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	//.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: "de",
		ns: getEnumArray(),
		debug: true,
		fallbackLng: 'de',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		backend: {
			// Specify the custom path where your translation files are stored
			loadPath: path.join(__dirname, '/locales/{{lng}}/{{ns}}.json'),
		}
	}).then((result: any) => {
		initialized = true
		console.log('#################################### ', initialized)
	});

export default i18n;