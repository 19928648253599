import { useEffect, useState } from 'react';
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from '../Components/DropdownChangesChecked';
import { ENUM_ICONS } from '../Components/Icons';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useCasePersonsInit, useCasePersonsList } from "../Persistence/CasePersonsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { InputNumberChecked } from '../Components/InputNumberChecked';

export const emptyCasePerson = { id: null, version: 1, active: 1, personId: '', GdB: '', GdBMark: '', disablityKind: '', gender: '' }

export const CasePersonForm = ({ formik }) => {

	const configurationsList = useConfigurationsList();
	const casePersonsList = useCasePersonsList();
	const initCasePersonsList = useCasePersonsInit();
	const [dataviewList, setDataviewList] = useState([])

	useEffect(() => {
		initCasePersonsList()
	}, [])

	useEffect(() => {
		setDataviewList(casePersonsList)
	}, [casePersonsList])


	const handleCasePersonChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			e.value.gender = configurationsList.GenderTypeList.find(entry => entry.alias === e.value.gender)
			formik.setValues(e.value)
		} else {
			formik.setFieldValue('personId', e.value)
		}
	}

	return (
		<div>{!formik ? <div></div> :
			<div className='flex flex-column w-full'>
				<div className="formgrid grid mr-1">
					{formik.values.id ? <>
						<div className="field col ">Person ID: {formik.values.personId}</div>
						<div className="field col-fixed w-3rem p-0"> <i className={ENUM_ICONS.TRASH} onClick={() => { formik.setValues({ ...emptyCasePerson }) }} /></div>
					</>
						:
						<AutoCompleteChangesChecked dropdown={true} label={'Bekannte Personennummer'} id={'personId'} itemLabel={'personId'}
							formik={formik} forceSelection={false} suggestionsList={dataviewList}
							helptextcolor='#F4A261'
							helptext='Eine Personen ID mit diesem Namen ist der Datenbank nicht bekannt. Beim speichern wird diese Id mit den eingegebenen Daten in der Datenbank eingetragen.'
							handleChange={handleCasePersonChange} />
					}
				</div>
				<div className="formgrid grid mr-1">
					<InputNumberChecked id='GdB' label='Grad der Behinderung' showButtons={true} min={0} max={100} step={10} formik={formik} />
					<InputChangesChecked id='GdBMark' label='Merkzeichen' type="text" formik={formik} />
				</div>
				<div className="formgrid grid mr-1">
					<InputChangesChecked id='disablityKind' label='Art der Behinderung' type="text" formik={formik} />
				</div>
				<div className="formgrid grid mr-1">
					<DropdownChangesChecked
						label={'Geschlecht'}
						id={'gender'}
						itemLabel={'display'}
						formik={formik}
						options={configurationsList.GenderTypeList}
					/>
					<div className="field col mr-3" />
				</div>
			</div>
		}</div>
	)
}


export const validateCasePerson = (data) => {
	let errors = {};
	if (!data.personId) {
		errors.personId = 'Geben Sie die Personen ID ein. Wenn diese ID bereits in der Datenbank existiert, können Sie sie auswählen. Wenn die Personen ID noch nicht in der Datenbank existiert, können Sie eine neue ID wählen und die Informationen eintragen und speichern.';
	}
	return errors;
}