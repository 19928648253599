import { ConfirmDialog } from '../Components/ConfirmDialog'
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES'
interface DialogBoxProps {
	showDialog: boolean
	cancelNavigation: any
	confirmNavigation: any
}

const UnsafedChangesDialog: React.FC<DialogBoxProps> = ({
	showDialog,
	cancelNavigation,
	confirmNavigation,
}) => {
	return (
		<ConfirmDialog
			NAMESPACE={ENUM_NAMESPACES.FORMELEMENTS}
			labelKey='confirmdialog-unsave'
			labelObject={{}}
			handleOnReject={cancelNavigation}
			handleOnClick={confirmNavigation}
			setDisplayConfirmDialog={() => { }}
			displayConfirmDialog={showDialog}
			className={undefined}
			style={{}} />
	)
}
export default UnsafedChangesDialog