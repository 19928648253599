import { endOfDay, format, formatDistance, getTime, parse } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { InputMask } from 'primereact/inputmask';
import { useRef, useEffect, useState } from 'react';
import { logDefault } from '../../Utils/logger';
import { useFBiBMode } from '../../Persistence/ConfigurationsContext';
import { useDataViewFilter } from '../../Persistence/DataViewFilterContext';
import './DateRangeFilterElement.scss'
import { getTranslation } from '../../snippets';
import { ENUM_NAMESPACES } from '../../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'DateRangeFilterElement';
const NAMESPACE = ENUM_NAMESPACES.DATAVIEWS
export const DateRangeFilterElement = ({ setFilterValue, alias, dataviewConfigKey, translationKey }) => {
    const calendarRef = useRef();
    const fbibMode = useFBiBMode();
    const dataViewFilter = useDataViewFilter();
    const [labels, setLabels] = useState(getTranslation(NAMESPACE, `${translationKey}`, { returnObjects: true }));
    const [filter, setFilter] = useState();
    const [minDate, setMinDate] = useState();
    const [maxDate, setMaxDate] = useState();
    const [start, setStart] = useState();
    const [startPicker, setStartPicker] = useState();
    const [end, setEnd] = useState();
    const [endPicker, setEndPicker] = useState();
    const [validStartDate, setValidStartDate] = useState(true);
    const [validEndDate, setValidEndDate] = useState(true);
    const [mounted, setMounted] = useState()

    useEffect(() => {
        const testLabels = labels
        if (mounted && filter) {
            const currentFilter = filter.value || [undefined, undefined]
            if (start === '' && end === '') {
                setFilterValue(null)
            } else {
                const dateStart = parse(start, 'dd.MM.yyyy', new Date())
                const dateEnd = parse(end, 'dd.MM.yyyy', new Date())

                const startFilter = (('' + dateStart) !== 'Invalid Date') ? dateStart.getTime() : 0;
                const endFilter = (('' + dateEnd) !== 'Invalid Date') ? dateEnd.getTime() : 99999999999999;

                setValidStartDate(('' + dateStart) !== 'Invalid Date')
                setValidEndDate(('' + dateEnd) !== 'Invalid Date')

                setStartPicker(startFilter !== 0 ? new Date(startFilter) : undefined)
                setEndPicker(endFilter !== 99999999999999 ? new Date(endFilter) : undefined)

                if (currentFilter[0] !== startFilter || currentFilter[1] !== endFilter) {
                    if (startFilter !== 0 || endFilter !== 99999999999999) {
                        console.log('filter:' + format(startFilter, 'dd.MM.yyyy') + ' - ' + format(endFilter, 'dd.MM.yyyy'))
                        setMinDate(startFilter !== 0 ? startFilter : filter.minDate)
                        setMaxDate(endFilter !== 99999999999999 ? endFilter : filter.maxDate)
                        setFilterValue([startFilter, endFilter])
                    }

                }
            }

        }
    }, [start, end, mounted])

    useEffect(() => {
        const filter = dataViewFilter[dataviewConfigKey][alias]
        setFilter(filter)
        setMinDate(filter.minDate)
        setMaxDate(filter.maxDate)
        logDefault(CLASSNAME, 'info', 'filter', filter)
        const start = filter.value && filter.value[0] && filter.value[0] > 0 ? format(new Date(filter.value[0]), 'dd.MM.yyyy') : ''
        const end = filter.value && filter.value[1] && filter.value[1] < 99999999999999 ? format(new Date(filter.value[1]), 'dd.MM.yyyy') : ''
        setStart(start)
        setEnd(end)
        setMounted(true)
    }, [dataViewFilter[dataviewConfigKey]])



    return (
        <div className={' flex filterCalendar '}>
            <div className={'flex flex-column gap-1 flex-grow-0 ' + (filter && filter.value && filter.value.length > 0 ? `eaa-filter-active${fbibMode ? '-fbib' : ''}` : '')}>
                <div className='flex'>
                    <InputMask
                        aria-label={labels?.startdate ? labels?.startdate.ariaLabel : ''}
                        mask="99.99.9999"
                        slotChar="TT.MM.JJJJ"
                        className={(validStartDate ? '' : 'text-red-500') + ' inputmask '}
                        value={start}
                        onChange={(e) => setStart(e.value)}
                    />
                    <div aria-hidden='true'>
                        <Calendar id='filterCalendarStart'
                            tabIndex={-1}
                            style={{ height: '25px', width: '25px' }}
                            value={startPicker}
                            showIcon
                            inputClassName='p-0'
                            inputStyle={{ height: '0px' }}
                            ref={calendarRef}
                            dateFormat="dd.mm.yy"
                            panelClassName={fbibMode ? 'fbib' : ''}
                            maxDate={maxDate ? new Date(maxDate) : undefined}
                            onChange={(e) => {
                                setStart(format(e.value, 'dd.MM.yyyy'))
                            }}
                        />
                    </div>
                </div>

                <div className='flex'>
                    <InputMask
                        aria-label={labels?.enddate ? labels?.enddate.ariaLabel : ''}
                        mask="99.99.9999"
                        slotChar="TT.MM.JJJJ"
                        className={(validEndDate ? '' : 'text-red-500') + ' inputmask '}
                        value={end}
                        onChange={(e) => setEnd(e.value)}
                    />
                    <div aria-hidden='true'>
                        <Calendar id='filterCalendarEnd'
                            tabIndex={-1}
                            style={{ height: '25px', width: '25px' }}
                            value={endPicker}
                            showIcon
                            inputClassName='p-0'
                            inputStyle={{ height: '0px' }}
                            ref={calendarRef}
                            dateFormat="dd.mm.yy"
                            panelClassName={fbibMode ? 'fbib' : ''}
                            minDate={minDate ? new Date(minDate) : undefined}
                            onChange={(e) => {
                                setEnd(format(e.value, 'dd.MM.yyyy'))
                            }}
                        />
                    </div>
                </div>

            </div>
            <div className='flex flex-grow-1' />
        </div>
    )
}

export const getDateFilterElement = (dataviewConfigKey) => {
    return (options) => <DateRangeFilterElement
        alias={options.field}
        setFilterValue={options.filterApplyCallback}
        dataviewConfigKey={dataviewConfigKey}
        key={options.field}
        translationKey={`${dataviewConfigKey}.${options.field}.filter`}
    />
}