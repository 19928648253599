import { useState } from 'react';
import { Button } from "primereact/button";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { ENUM_ICONS } from "../Components/Icons";
import { getData, patchData } from "../feathers";
import { logDefault } from "../Utils/logger";
import { useFormik } from "formik";
import { RegionForm, emptyRegion, validateRegion } from "./RegionForm";
import { useRegionTagsList } from "../Persistence/RegionTagsContext";
import { Tag } from 'primereact/tag';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { usePostcodesList } from '../Persistence/PostcodesContext';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';
import { getSortedList, getTranslation } from '../snippets';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { ENUM_NAMESPACES } from '../Enums/ENUM_NAMESPACES';

const CLASSNAME = 'RegionDataview'
const NAMESPACE = ENUM_NAMESPACES.REGIONS
const DATAVIEW_CONFIG = ENUM_DATAVIEWS_CONFIG.REGIONS;
export const RegionDataview = ({ handleStatusChange }) => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(DATAVIEW_CONFIG));
	const configurationsList = useConfigurationsList();
	const isAdmin = useCurrentUserIsAdmin();
	const regionsTagList = useRegionTagsList();
	const postcodesList = usePostcodesList();
	const [displayRegionDialog, setDisplayRegionDialog] = useState(false);
	const [refreshTime, setRefreshTime] = useState(null)


	const patchRegion = async () => {
		const region = { ...formikRegion.values };
		region.postcodes = region.postcodes.map(e => { return postcodesList.find(p => p.postcode === e) || { postcode: e } })
		region.tags = region.tags.map(e => { return regionsTagList.find(p => p.alias.toLowerCase() === e.toLowerCase()) || { alias: e.toLowerCase() } })
		logDefault(CLASSNAME, 'info', 'patchRegion postcodes', region.postcodes)
		logDefault(CLASSNAME, 'info', 'patchRegion tags', region.tags)
		await patchData(ENUM_SERVICES.REGIONS, region).then(async () => {
			setDisplayRegionDialog(false)
			setRefreshTime(Date.now())
		})
	}

	/** Regions */
	const formikRegion = useFormik({
		initialValues: { ...emptyRegion },
		validate: validateRegion,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const onEditRegion = async (entry) => {
		await getData('regions', entry.id).then((region) => {
			region.postcodes = region.postcodes.map(e => e.postcode).sort()
			region.tags = region.tags.map(e => e.alias).sort()
			logDefault(CLASSNAME, 'info', 'onEditRegion region:', region)
			formikRegion.setValues(region);
			setDisplayRegionDialog(true)
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Region bearbeiten', icon: ENUM_ICONS.PENCIL, command: () => { onEditRegion(rowData.id) } },
		{ label: rowData.active ? 'Inaktiv setzen' : 'Aktiv setzen', icon: (rowData.active ? ENUM_ICONS.DEACTIVATE : ENUM_ICONS.REACTIVATE), command: () => { handleStatusChange(rowData, 'regions', 'Region') } }]

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const renderRegionTags = (row) => {
		let counter = 0;
		const tags = (row.tagValues || '').split(',').sort()
		return <div className='flex flex-wrap'>{tags.map(e => <Tag key={'regiontag_' + counter++} className="mr-2 mb-1 px-3" rounded value={e} />)}</div>
	}
	const renderRegionPostcodes = (row) => {
		let counter = 0;
		const postcodes = (row.postcodeValues || '').split(',').sort()
		return <div className='flex flex-wrap'>{postcodes.map(e => <Tag key={'postcodetag_' + counter++} className="mr-2 mb-1 px-3" rounded value={e} />)}</div>
	}


	const getColumnsRegions = () => {
		const columns = [
			{ filterAlias: 'col_02', field: "name", sortable: true, filter: "name", header: "Name", style: { flexGrow: 1, flexBasis: '100px' } },
		]
		if (configurationsList.eaaModules.REQUESTDISTRIBUTION) {
			columns.push({ filterAlias: 'col_03', field: "tags", sortable: true, filterField: "tagValues", filter: "tagValues", header: "Tags der Region", style: { flexGrow: 1, flexBasis: '400px' }, body: renderRegionTags });
			columns.push({ filterAlias: 'col_04', field: "postcodes", sortable: true, filterField: "postcodeValues", filter: "postcodeValues", header: "PLZ der Region", style: { flexGrow: 1, flexBasis: '400px' }, body: renderRegionPostcodes });
		}
		columns.push({ filterAlias: 'col_05', field: "active", sortable: true, filter: "active", header: "Status", style: { width: '150px' } })
		columns.push({ filterAlias: 'contextmenu', style: { width: '30px' }, body: renderContextMenu })
		return columns
	}
	return <div className='flex flex-column gap-2'>
		<EditDialog
			form={<RegionForm formik={formikRegion} key='region-form' />}
			formik={formikRegion}
			patchForm={patchRegion}
			NAMESPACE={NAMESPACE}
			labelKey={'editdialog'}
			labelObject={{ regionName: formikRegion?.values.name }}
			setDisplayDialog={setDisplayRegionDialog}
			displayDialog={displayRegionDialog}
		/>
		<TemplateDataViewPagination
			key='datatable-regions'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			buttons={[<Button
				key='button-new-region'
				disabled={!isAdmin}
				aria-label={getTranslation(ENUM_NAMESPACES.DATAVIEWS, `button-new-region.ariaLabel`)}
				label={getTranslation(ENUM_NAMESPACES.DATAVIEWS, `button-new-region.label`)}
				onClick={() => { formikRegion.setValues({ ...emptyRegion }); setDisplayRegionDialog(true) }} />]}
			columns={getColumnsRegions()}
			dataTableWidth='100%'
			onDoubleClick={onEditRegion}
			refreshTime={refreshTime}
			dataviewConfig={dataviewConfig}
		/>

	</div>
}