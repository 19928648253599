import { useEffect, useRef, useState } from "react";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked"
import { InputText } from 'primereact/inputtext';
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useAssociatedOrganisationsList } from "../Persistence/AssociatedOrganisationsContext";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { ENUM_NAMESPACES } from "../Enums/ENUM_NAMESPACES";
import { getTranslation } from "../snippets";

const NAMESPACE = ENUM_NAMESPACES.ASSOCIATEDORGANIZATIONS
export const AssociatedOrganizationForm = ({ formik, validateForm, onSelectExistingOrganization, readOnly, refBlur }) => {
	const [regionsSelection, setRegionsSelection] = useState([]);
	const [editmode, setEditmode] = useState(true);
	const configurationsList = useConfigurationsList();
	const organizationList = useAssociatedOrganisationsList();
	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();
	const [postcodeList, setPostcodeList] = useState([]);
	const [labelsAddress, setLabelsAddress] = useState(getTranslation(ENUM_NAMESPACES.ADDRESSES, 'fields', { returnObjects: true }));

	const createRegionSelection = () => {
		const regionSelection = regionsSelectionList ? [...regionsSelectionList] : [];
		if (formik.values.regionId) {
			const region = regionsList.find(entry => entry.id === formik.values.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		setRegionsSelection(regionSelection)
	}
	useEffect(() => {
		createRegionSelection();
	}, [regionsSelectionList])

	useEffect(() => {
		if (configurationsList) {
			setPostcodeList(configurationsList.postcodes)
		}
	}, [configurationsList])

	const handleCityChange = async (value) => {
		const postcode = postcodeList.find(e => e.postcodeAndCity === value)
		formik.setFieldValue('city', postcode ? postcode.city : '');
		formik.setFieldValue('postcode', postcode ? postcode.postcode : '')
		if (validateForm) {
			validateForm()
		}
	}

	const handleOrganizationChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			formik.setValues(e.value)
			if (onSelectExistingOrganization) {
				onSelectExistingOrganization()
			}
			if (refBlur && refBlur.current) {
				setTimeout(() => refBlur.current.focus(), 10)
			}


		} else {
			formik.setFieldValue('name', e.value)
		}
	}

	const handleOrganizationBlur = (e) => {
		const entry = e.target.value.toUpperCase().trim()
		const existingOrganization = organizationList.find(org => (org.name.toUpperCase().trim() === e.target.value.toUpperCase().trim() && org.id !== formik.values.id))
		formik.setValues({
			...formik.values,
			existingOrganization: (existingOrganization && formik.values.id !== existingOrganization.id ? (existingOrganization.active ? 1 : -1) : 0),
			organizationCheck: false
		})
	}

	return (!configurationsList.CountryList ? '' : <>
		<div className="formgrid grid ">
			<AutoCompleteChangesChecked
				dropdown={false}
				label={getTranslation(NAMESPACE, 'fields.name.label')}
				id={'name'}
				itemLabel={'name'}
				formik={formik}
				forceSelection={false}
				suggestionsList={organizationList}
				helptextcolor='#11b9a0'
				helptext={formik.values.id ? undefined : 'Eine Netzwerkorganisation mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird die Organisation mit den eingegebenen Daten in der Datenbank eingetragen.'}
				onBlur={handleOrganizationBlur}
				onFocus={() => formik.setFieldValue('organizationCheck', true)}
				handleChange={handleOrganizationChange} />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={2} disabled={readOnly || false} formik={formik} id='phone' NAMESPACE={NAMESPACE} translationKey='fields.phone' type='text' />
			<InputChangesChecked tabIndex={3} disabled={readOnly || false} formik={formik} id='mobile' NAMESPACE={NAMESPACE} translationKey='fields.mobile' type='text' />
			<InputChangesChecked tabIndex={4} disabled={readOnly || false} formik={formik} id='fax' NAMESPACE={NAMESPACE} translationKey='fields.fax' type='text' />
		</div>
		<div className="formgrid grid">
			<InputChangesChecked tabIndex={2} disabled={readOnly || false} formik={formik} id='website' NAMESPACE={NAMESPACE} translationKey='fields.website' type='text' />
			<InputChangesChecked tabIndex={5} disabled={readOnly || false} formik={formik} id='email' NAMESPACE={NAMESPACE} translationKey='fields.email' type='text' />
		</div>
		<div className="formgrid grid">
			<DropdownChangesChecked
				showClear={true}
				className='mr-3'
				tabIndex={6}
				NAMESPACE={NAMESPACE}
				translationKey='fields.region'
				id='regionId'
				disabled={readOnly ? true : false}
				itemLabel={'name'}
				optionValue='id'
				formik={formik}
				options={regionsSelection} />

		</div>

		<div className="field">
			<label>{labelsAddress.headline.label}</label>
			<div className="p-3 border-1 surface-border mr-3">
				<div className="field grid">
					<label htmlFor="line1" className="col-fixed w-9rem">{labelsAddress.line1.label}</label>
					<div className="col">
						<InputText tabIndex={0} disabled={readOnly || false} className='w-full' value={formik.values.line1} id='line1' onChange={(e) => formik.setFieldValue('line1', e.target.value)} type='text' />
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="line2" className="col-fixed w-9rem">{labelsAddress.line2.label}</label>
					<div className="col">
						<InputText tabIndex={0} disabled={readOnly || false} className='w-full' value={formik.values.line2} id='line2' onChange={(e) => formik.setFieldValue('line2', e.target.value)} type='text' />
					</div>
				</div>
				<div className="field grid">
					<label htmlFor="line3" className="col-fixed w-9rem">{labelsAddress.line3.label}</label>
					<div className="col">
						<InputText tabIndex={0} disabled={readOnly || false} className='w-full' value={formik.values.line3} id='line3' onChange={(e) => formik.setFieldValue('line3', e.target.value)} type='text' />
					</div>
				</div>
				<div className={formik.values.country === 'Deutschland' ? 'hidden' : 'field grid'}>
					<label htmlFor="postcode" className="col-fixed w-9rem">{labelsAddress.postcode.label}</label>
					<div className="col-fixed w-9rem">
						<InputText tabIndex={0} disabled={readOnly || false} className='w-full' value={formik.values.postcode} id='line3' onChange={(e) => formik.setFieldValue('postcode', e.target.value)} type='text' />
					</div>
					<label htmlFor="city" className="col-fixed w-5rem">{labelsAddress.city.label}</label>
					<div className="col">
						<InputText tabIndex={0} disabled={readOnly || false} className='w-full' value={formik.values.city} id='line3' onChange={(e) => formik.setFieldValue('city', e.target.value)} type='text' />
					</div>
				</div>
				<div className={formik.values.country !== 'Deutschland' ? 'hidden' : 'field grid'}>

					<DropdownChangesChecked virtualScrollerOptions={{ itemSize: 38 }}
						filter={true}
						NAMESPACE={ENUM_NAMESPACES.ADDRESSES}
						translationKey='fields.postcodeAndCity'
						id={'postcodeAndCity'}
						formik={formik}
						disabled={readOnly ? true : false}
						optionValue='postcodeAndCity'
						itemLabel={'postcodeAndCity'}
						handleChange={handleCityChange}
						classNameLabel='hidden'
						colLabels='col-fixed w-9rem'
						className={'m-0'}
						rowStyle
						options={postcodeList} />
				</div>
				<div className="field grid mb-0">
					<DropdownChangesChecked
						NAMESPACE={ENUM_NAMESPACES.ADDRESSES}
						translationKey='fields.country'
						id={'country'}
						formik={formik}
						disabled={readOnly ? true : false}
						optionValue='country'
						itemLabel={'country'}
						handleChange={() => { }}
						classNameLabel='hidden'
						colLabels='col-fixed w-9rem'
						className={'m-0'}
						rowStyle
						options={configurationsList.CountryList} />

				</div>
			</div>
		</div>
		<div className="formgrid grid ">
			<InputChangesChecked tabIndex={14} disabled={readOnly || false} formik={formik} id='description' NAMESPACE={NAMESPACE} translationKey='fields.description' type='text' />
		</div>
	</>
	)
}
export const getEmptyAssociatedOrganization = () => {
	return {
		id: null, active: 1, version: 1, name: '', email: '', phone: '', fax: '', mobile: '', associatedContactOrganizationId: null, description: '', line1: '', line2: '', line3: '', postcode: '', city: '', country: 'Deutschland', origin: 'EAA', regionId: null, orgnaizationCheck: false, existingOrganization: 0
	}
}
export const validateAssociatedOrganization = (data) => {
	let errors = {};
	if (data.organizationCheck) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error-check.label');
	} else if (data.existingOrganization === 1) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error-exists.label');
	} else if (data.existingOrganization === -1) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error-exists-inactive.label');
	} else if (!data.name) {
		errors.name = getTranslation(NAMESPACE, 'fields.name.error.label');;
	}

	return errors;
}
